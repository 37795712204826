
export const transformMediaDataToItems = (skuData) => {
    let positionMap = {
        '360': skuData?.team_config?.['360']?.position || 1,
        'video': skuData?.team_config?.['video']?.position || 0,
        'image': skuData?.team_config?.['image']?.position || 0,
    }
    const mediaTypes = Object.entries(positionMap)
        .filter(([_, position]) => position !== undefined)
        .sort(([_, posA], [__, posB]) => posA - posB)
        .map(([type]) => type);

    const items = [];
    for (const type of mediaTypes) {
        if (skuData[type]) {
            if (type === 'image') {
                if (skuData['image']?.exterior?.length > 0) {
                    if (skuData['image']?.exterior?.length > 0) {
                        items.push(...skuData?.['image']?.exterior?.map((item) => ({
                            type: 'image',
                            data: item,
                            category: 'exterior',
                        })));
                    }
                }
                if (skuData['image']?.['360_exterior']?.length > 0) {
                    if (skuData['image']?.['360_exterior']?.length > 0) {
                        items.push(...skuData?.['image']?.['360_exterior']?.map((item) => ({
                            type: 'image',
                            data: item,
                            category: 'exterior',
                        })));
                    }
                }
                if (skuData['image']?.interior?.length > 0) {
                    if (skuData['image']?.interior?.length > 0) {
                        items.push(...skuData?.['image']?.interior?.map((item) => ({
                            type: 'image',
                            data: item,
                            category: 'interior',
                        })));
                    }
                }

                const otherImageKeys = Object.keys(skuData['image']).filter(key => 
                    !['interior', 'exterior', '360_exterior'].includes(key)
                );
                
                for (const key of otherImageKeys) {
                    if (skuData['image'][key]?.length > 0) {
                        items.push(...skuData['image'][key].map((item) => ({
                            type: 'image',
                            data: item,
                            category: 'additional',
                        })));
                    }
                }
            }

            else items.push({
                type: type,
                data: skuData[type],
            });
        }
    }


    return items;
};