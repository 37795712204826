import React, {  useEffect, useState, useCallback, useMemo } from 'react'
import useMediaDataAndTabs from '../Components/commonV3/hooks/useMediaDataAndTabs';
import { MediaProvider, NavigationProvider, UIProvider } from '../Components/commonV3/context';
import MainViewer from '../Components/commonV3/MainViewer';
import BottomSlider from '../Components/commonV3/BottomSlider';
import RightGrid from '../Components/commonV3/RightGrid';
import useCarouselNavigation from '../Components/commonV3/hooks/useCarouselNavigation';
import { IMAGE_COMPRESSOR } from '../utils/imageCompressor';
import { ASSETS, TAB_ENUM } from '../Components/common/config';
import AnimatedLoader from '../Components/common/Loader';
import FullScreenModal from '../Components/commonV3/FullScreenModal';
import ShareModal from '../Components/commonV3/shareModal/ShareModal';
import useWindowSize from '../utils/useWindowSize';
import Tabs from '../Components/common/Tabs';

const CommonIframeV3 = () => {
    const { skuData, loading, selectedTemplate, selectedTab, tabs, carouselItems, setSelectedTab, dynamicTabImage, aspectRatio, skuName, mediaId, enterpriseId, dealerVinId } = useMediaDataAndTabs();
    const { selectedIndex, handleLeft, handleRight, handleSelectThumbnail, skipAnimation } = useCarouselNavigation({ carouselItems, selectedTab, setSelectedTab });
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const windowSize = useWindowSize();
    const [isMobile, setIsMobile] = useState(false);
    const [isSingleProduct, setIsSingleProduct] = useState(false);
    const [showOverlays, setShowOverlays] = useState(true);
    const handleShareModal = useCallback(() => {
        setShareModalOpen(true);
    }, []);

    const navigationValue = {
        carouselItems,
        selectedIndex,
        handleLeft,
        handleRight,
        handleSelectThumbnail,
        skipAnimation,
        setSelectedTab
    }
    const uiValue = {
        isSingleProduct,
        showOverlays,
        setShowOverlays,
        handleShareModal
    };

    useEffect(() => {
        if (tabs?.length === 1) {
            if( (tabs[0]?.key === TAB_ENUM.VIDEO_VIEW ||  tabs[0]?.key === TAB_ENUM.THREE_SIXTY_VIEW)){
                setIsSingleProduct(true);
            }else{
                setIsSingleProduct(false);
            }
        }else{
            setIsSingleProduct(false);
        }
    }, [tabs]);

    useEffect(() => {
        let deviceWidth = windowSize.deviceWidth;
        let deviceHeight = windowSize.deviceHeight;
        if (deviceWidth < 550) {
            setIsMobile(true);
        } else setIsMobile(false);
    }, [windowSize]);

    const handleFullScreen = () => {
        setIsFullScreen(true);
    };

    useEffect(() => {
        if(isFullScreen === true){
        window.parent.postMessage(
            { type: 'FULLSCREEN_MODE_CHANGED', isFullScreen },
            '*'
        );

    }
    }, [isFullScreen]);

    const handleMessage = (event) => {
        if (event?.data && typeof event?.data?.closeFullScreen !== "undefined") {
            setIsFullScreen(event?.data?.closeFullScreen);
        }else if(event?.data && typeof event?.data?.showFullScreenModal !== "undefined"){
            setIsFullScreen(event?.data?.showFullScreenModal);
        }
      };

    useEffect(()=> {
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage)
        }
    },[])

    const handleTabSelect = useCallback((option) => {
        setSelectedTab(option?.key);
        const firstItemIndex = carouselItems.findIndex(item => item.type === option?.key);
        if (firstItemIndex !== -1) {
            handleSelectThumbnail(firstItemIndex, option?.key)
        }
    }, [carouselItems, handleSelectThumbnail, setSelectedTab]);

    return (
        !loading && (!skuData.isMediaPresent)) ?
        <img className="w-full h-full max-sm:object-contain" src={IMAGE_COMPRESSOR({ url: ASSETS.other.noSkuDone })} alt="vin is in process" />
        : (
            <>
                {
                    loading ? <AnimatedLoader /> :
                    <MediaProvider value={{skuData,
                        loading,
                        selectedTemplate,
                        selectedTab,
                        tabs,
                        dynamicTabImage,
                        aspectRatio,
                        skuName,
                        mediaId,
                        enterpriseId,
                        dealerVinId}}>
                        <NavigationProvider value={navigationValue}>
                            <UIProvider value={uiValue}>
                            {!isMobile ? (
                                <div className={`w-full h-full relative flex flex-col overflow-hidden`}>
                                    {selectedTemplate === "t1" ? (
                                        <div className={`w-full h-dvh flex flex-col overflow-hidden ${isSingleProduct && ''}`}>
                                            <div className={`w-full ${isSingleProduct ? 'max-h-full' : 'max-h-[85%] pt-2 '} `} style={{
                                                aspectRatio: aspectRatio
                                            }}>
                                                <MainViewer template={selectedTemplate} handleFullScreen={handleFullScreen} isFullScreen={false} dynamicTabImage={dynamicTabImage} />
                                            </div>
                                            {
                                                !isSingleProduct && (
                                                    <div className='w-full min-h-[15%] max-h-[40%] h-full overflow-x-scroll'>
                                                        <BottomSlider />
                                                    </div>
                                                )
                                            }
                                        </div>

                                    ) : (
                                        <div className={`w-screen h-dvh flex ${isSingleProduct && ' py-2 items-center justify-center'}`}>
                                            <div className={`h-full ${isSingleProduct ? 'max-w-full' : 'max-w-[80%] p-2'}`} style={{
                                                aspectRatio: aspectRatio
                                            }}>
                                                <MainViewer template={selectedTemplate} handleFullScreen={handleFullScreen} isFullScreen={false} dynamicTabImage={dynamicTabImage} />
                                            </div>
                                            {
                                                !isSingleProduct && (
                                                    <div className='w-full h-full max-w-[80%] overflow-y-scroll scrollbar-hide'>
                                                        <RightGrid />
                                                    </div>
                                                )
                                            }

                                        </div>
                                    )}
                                    {/* <FullScreenModal isOpen={isFullScreen} onClose={() => setIsFullScreen(false)} carouselItems={carouselItems} /> */}

                                    {/* {
                                        shareModalOpen && <ShareModal onClose={() => setShareModalOpen(false)} downloadEnabled={true} />
                                    } */}
                                </div> 
                            ) : (
                                <div className={`w-full h-full relative flex flex-col overflow-hidden`}>

                                    <MainViewer template={"mobile"} handleFullScreen={handleFullScreen} isFullScreen={false} dynamicTabImage={dynamicTabImage} />
                                    <div className={`bg-blue-lightestBlue shadow-md shadow-black-8 sm:rounded-b-md md:rounded-2xl max-md:w-full md:w-max z-[999]`}>
                                        <Tabs
                                            options={tabs}
                                            onSelect={(option) => {
                                                setSelectedTab(option?.key);
                                                const firstItemIndex = carouselItems.findIndex(item => item.type === option?.key);
                                                if (firstItemIndex !== -1) {
                                                    handleSelectThumbnail(firstItemIndex, option?.key)
                                                }
                                            }}
                                            isV3={true}
                                            selectedTab={selectedTab}
                                        />
                                    </div>

                                </div>
                            )}    
                    </UIProvider>
                    </NavigationProvider>
                </MediaProvider>
                }
            </>
        )
}

export default CommonIframeV3