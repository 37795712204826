/**@format */

import Eyeicon from "./Icons/Eyeicon"
import MinusIcon from "./Icons/MinusIcon"
import PlusIcon from "./Icons/PlusIcon"


const Icons = {
    minus: MinusIcon,
    plus: PlusIcon,
    eye: Eyeicon
}

export default function SVG({iconName, className, height, width, type, style, name, color, onClick}) {
    const Icon = Icons[iconName]
    return Icon ? <Icon className={className} height={height} width={width} type={type} style={style} name={name} color={color} onClick={onClick} /> : null
}

export const getAllSvg = () => Object.keys(Icons).map(key => ({name: key, Icon: Icons[key]}))
