import React, { useContext, useEffect, useRef } from 'react'
import { NavigationContext } from './context';
import GridItem from './GridItem';
import { getParams } from '../../utils/getParams';

const RightGrid = () => {
    const { carouselItems, selectedIndex } = useContext(NavigationContext);
    const gridContainerRef = useRef(null);
    const { params } = getParams();

    useEffect(() => {
        const scrollToThumbnail = () => {
            if (gridContainerRef.current) {
                const container = gridContainerRef.current;
                const thumbnails = Array.from(container.children);
                if (selectedIndex >= 0 && selectedIndex < thumbnails.length) {
                    const selectedThumbnail = thumbnails[selectedIndex];

                    if (selectedThumbnail) {
                        const containerHeight = container.offsetHeight;
                        const thumbnailRect = selectedThumbnail.getBoundingClientRect();
                        const containerRect = container.getBoundingClientRect();

                        // Check if thumbnail is already visible
                        if (thumbnailRect.top >= containerRect.top && thumbnailRect.bottom <= containerRect.bottom) {
                            return;
                        }

                        // Calculate scroll position to center the thumbnail
                        const scrollTop = selectedThumbnail.offsetTop - (containerHeight - thumbnailRect.height) / 2;

                        container.scrollTo({
                            top: scrollTop,
                            behavior: 'smooth'
                        });
                    }
                }
            }
        };

        // Add small delay to ensure DOM is updated
        const timeoutId = setTimeout(scrollToThumbnail, 50);
        return () => clearTimeout(timeoutId);
    }, [selectedIndex]);

    return (
        <div className='w-full h-full grid pl-1 md:pl-2 pl-2 md:pr-4 p-2 overflow-y-auto overflow-x-hidden'
            style={{
                gridTemplateColumns: params?.cols
                    ? `repeat(${params?.cols}, 1fr)`
                    : `repeat(auto-fit, minmax(min(100%, 180px), 1fr))`,
                scrollBehavior: 'smooth',
                gap: '0.75rem',
                gridAutoRows: 'min-content',
                alignItems: 'start',
            }}
            ref={gridContainerRef}
        >
            {
                carouselItems?.length > 0 &&
                carouselItems?.map((item, index) => {
                    return (
                        <div key={index} style={{ height: 'fit-content' }}>
                            <GridItem item={item} index={index} template={"t2"} rightGrid={true} />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default RightGrid