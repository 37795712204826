import React, { useEffect, useState } from 'react';
import { checkIsV2 } from '../../utils/getAppVersion';
import { IFRAME_MODULE_CONFIG } from '../common/config'

export default function View360HelperText({ showHelperText }) {
  const [showHotspot, setHotspot] = useState(false);
  const isV2 = checkIsV2();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let hotspot_val = queryParams.get('hotspot');
    setHotspot(!!hotspot_val);
  }, []);

  const containerStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white'
  };

  return (
    <div
      className={`
        !h-fit
        !absolute !z-[60] 
        !left-1/2 !-translate-x-1/2 
        !flex !items-center !justify-center 
        !rounded-full 
        !transition-all !duration-1000 
        !font-medium !whitespace-nowrap 
        !pointer-events-none 
        !text-center 
        !text-white
        ${window?.innerWidth > 560 || showHotspot
          ? '!top-6 !px-4 !py-2 !text-base !leading-6 !gap-3'
          : '!bottom-6 !px-3 !py-1 !text-sm !leading-5 !gap-2'}  
        ${!showHelperText ? '!opacity-0' : '!opacity-100'}
        ${isV2 ? '!top-[4%]' : ''}
      `}
      style={{
        maxWidth: '90%',
        padding: '8px 12px',
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Transparent background here
        lineHeight: '1.4',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', 
      }}
    >
      <img
        src="https://d20uiuzezo3er4.cloudfront.net/console/icons/raUFXQXzkM.gif"
        alt="handIcon"
        className="!w-5 !h-5 object-cover !flex-shrink-0"
      />
      <div className="!ml-2">
        {window.innerWidth > 560 ? (
          <span className="!block !text-s">Click and Drag to view 360 spin</span>
        ) : (
          <span className="!block !text-xs !p-1 !mr-1">Swipe to view 360 spin</span>
        )}
      </div>
    </div>
  );
}