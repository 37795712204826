import { useState, useEffect } from "react";
import { getParams } from "../../../utils/getParams";
import { TAB_ENUM } from "../../common/config";
import { getDynamicTabImage, getMediaDynamicTab } from "../../../utils/getDynamicTab";
import { transformMediaDataToItems } from "../../../utils/transformMediaData";
import centralAPI from "../../../CentralAPIHandler/CentralAPIHandler";

const useMediaDataAndTabs = () => {
    const [skuData, setSkuData] = useState({});
    const [skuName, setSkuName] = useState('');
    const [loading, setLoading] = useState(true);
    const [dealerVinId, setDealerVinId] = useState(null);
    const [enterpriseId, setEnterpriseId] = useState(null);
    const [aspectRatio, setAspectRatio] = useState('16/9');
    const [mediaId, setMediaId] = useState(null);

    const [selectedTemplate, setSelectedTemplate] = useState("t1");

    const [selectedTab, setSelectedTab] = useState(TAB_ENUM.THREE_SIXTY_VIEW);
    const [tabs, setTabs] = useState([]);

    //carousel items
    const [carouselItems, setCarouselItems] = useState([]);
    const [dynamicTabImage, setDynamicTabImage] = useState([]);
    const { params } = getParams();


    const fetchMediaFromStorage = async ({ mediaId, product }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_MEDIA_BUCKET_URL_V3}/m/o/${mediaId}/${product}.json`);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching media data:', error);
            throw error;
        }
    }
    const fetchMediaFromStorageV3 = async ({ enterpriseId, skuName, product }) => {
        try {
            let URL = `${process.env.REACT_APP_MEDIA_BUCKET_URL_V3}/common-iframe-sku/${skuName}_${enterpriseId}/${product}.json`
            const response = await fetch(URL);
            const data = await response.json();
            let keys;
            if (product === 'image') {
                let imageData = data?.imageData;
                if (imageData) {
                    keys = Object.keys(imageData);
                }
                if (keys?.length > 0) {
                    return data;
                }
                else throw new Error('No data found');
            }
            else return data;
        } catch (error) {
            console.error('Error fetching media data:', error);
            throw error;
        }
    }
    const getDataFromJson = async ({ params, spin, catalog, feature_video }) => {
        try {
            const mediaId = params?.mediaId;
            const skuName = params?.skuName;
            const enterpriseId = params?.enterpriseId;
            if (!mediaId && (!skuName || !enterpriseId)) return;

            const promise = []
            const productsPicked = [];
            if (spin) {
                if (mediaId) {
                    promise.push(fetchMediaFromStorage({ mediaId, product: 'spin' }))
                } else {
                    promise.push(fetchMediaFromStorageV3({ enterpriseId, skuName, product: 'threeSixty' }))
                }
                productsPicked.push('360')
            }
            if (catalog) {
                if (mediaId) {
                    promise.push(fetchMediaFromStorage({ mediaId, product: 'catalog' }))
                } else {
                    promise.push(fetchMediaFromStorageV3({ enterpriseId, skuName, product: 'image' }))
                }
                productsPicked.push('image')
            }
            if (feature_video) {
                if (mediaId) {
                    promise.push(fetchMediaFromStorage({ mediaId, product: 'feature_video' }))
                } else {
                    promise.push(fetchMediaFromStorageV3({ enterpriseId, skuName, product: 'video' }))
                }
                productsPicked.push('video')
            }
            const data = await Promise.all(promise);
            return {
                data: data,
                productsPicked: productsPicked
            };
        } catch (error) {
            console.error('Error fetching media data here:', error);
            return {
                data: null,
                productsPicked: []
            };
        }
    }
    const getDataFromFallback = async ({ params, spin, catalog, feature_video }) => {
        try {
            let mediaId = params?.mediaId;
            let skuName = params?.skuName;
            let enterpriseId = params?.enterpriseId;
            if (!mediaId && (!skuName || !enterpriseId)) return;
            const productsPicked = [];
            let URL;
            if (mediaId) {
                URL = `${process.env.REACT_APP_BASEURL_API_SPYNE}/api/pv1/media-layer/processed-products?mediaId=${mediaId}`;
            } else {
                URL = `${process.env.REACT_APP_BASEURL_API_SPYNE}/api/pv1/media-layer/processed-products?skuName=${skuName}&enterpriseId=${enterpriseId}`;
            }
            const response = await fetch(URL);
            const data = await response.json();

            let respData = [];
            if (spin && data?.spin) {
                respData.push(data.spin);
                productsPicked.push('360');
            }
            if (catalog && data?.catalog && data?.catalog?.imageData && Object.keys(data?.catalog?.imageData)?.length > 0) {
                respData.push(data.catalog);
                productsPicked.push('image');
            }
            if (feature_video && (data?.featureVideo || data?.feature_video)) {
                respData.push(data.featureVideo || data?.feature_video);
                productsPicked.push('video');
            }
            return { data: respData, productsPicked };
        } catch (error) {
            console.error('Error fetching media data:', error);
            return { data: null, productsPicked: [] };
        }
    }

    const sendAnalytics = (executionTime, apiCallFlag, params) => {
        const analyticsData = {
            commonIframeLoadingTime: executionTime,
            methodOfIframeData: apiCallFlag ? 'api' : 'json'
        };
        centralAPI.handlePostRequest(`${process.env.REACT_APP_BASEURL_API_SPYNE}/spin/create-iframe-event`, {
            "mediaId": params?.mediaId,
            "skuName": params?.skuName,
            "enterpriseId": params?.enterpriseId,
            "dealerVinId": dealerVinId,
            "actionBy": apiCallFlag ? 'api' : 'json',
            "analytics": analyticsData,
            "version": mediaId ? "v2" : null
        }).catch(analyticsError => {
            console.error('Failed to send analytics:', analyticsError);
        });
    };

    const fetchMediaData = async ({ params, spin = 0, catalog = 0, feature_video = 0 }) => {
        const startTime = performance.now();
        let apiCallFlag = false;
        try {
 
            let mediaId = params?.mediaId;
            let skuName = params?.skuName;
            let enterpriseId = params?.enterpriseId;
            if (!mediaId && (!skuName || !enterpriseId)) return;
            if (!spin && !catalog && !feature_video) return;

            let isMediaPresent = false;

            let resp = await getDataFromJson({ params, spin, catalog, feature_video });
            let data = resp?.data;
            let productsPicked = resp?.productsPicked;

            if (!data || data.length === 0 || data.every(item => item === null)) {
                resp = await getDataFromFallback({ params, spin, catalog, feature_video });
                data = resp?.data;
                productsPicked = resp?.productsPicked;
                apiCallFlag = true;
            }
            const mediaData = {
                '360': null,
                'image': null,
                'video': null,
            }
            // Function to get image dimensions
            const getImageDimensions = (url) => {
                let newUrl = `https://media.spyneai.com/unsafe/filters:format(webp)/` + url;
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        resolve({
                            width: img.width,
                            height: img.height
                        });
                    };
                    img.onerror = () => {
                        resolve({ width: 16, height: 9 }); // default on error
                    };
                    img.src = newUrl;
                });
            };

            // Function to determine closest aspect ratio (4:3 or 16:9)
            const getClosestAspectRatio = (width, height) => {
                if (!width || !height) return '16/9';

                const actualRatio = width / height;
                const ratio16_9 = 16 / 9;  // ≈ 1.778
                const ratio4_3 = 4 / 3;    // ≈ 1.333

                // Calculate which ratio is closer
                const diff16_9 = Math.abs(actualRatio - ratio16_9);
                const diff4_3 = Math.abs(actualRatio - ratio4_3);

                return diff16_9 < diff4_3 ? '16/9' : '4/3';
            };
            // Function to get aspect ratio from images
            const getAspectRatioFromImages = async (imageData) => {
                if (!imageData) return '16/9';

                // Try exterior images first
                if (imageData?.exterior?.[0]?.output_image_hres_url || imageData?.['360_exterior']?.[0]?.output_image_hres_url) {
                    const dimensions = await getImageDimensions(imageData?.exterior?.[0]?.output_image_hres_url || imageData?.['360_exterior']?.[0]?.output_image_hres_url);
                    if (dimensions) {
                        let aspect = getClosestAspectRatio(dimensions.width, dimensions.height);
                        return aspect;
                    }
                }

                return '16/9'; // Default if no images available
            };

            const getAspectRatioFromSpinImage = async (imageUrl) => {
                if (!imageUrl) return '16/9';
                const dimensions = await getImageDimensions(imageUrl);
                if (dimensions) {
                    return getClosestAspectRatio(dimensions.width, dimensions.height);
                }
                return '16/9';
            }

            const teamConfig = {
                "360": {
                    "isActive": spin ? true : false,
                    "position": spin
                },
                "image": {
                    "isActive": catalog ? true : false,
                    "position": catalog
                },
                "video": {
                    "isActive": feature_video ? true : false,
                    "position": feature_video
                }
            }

            for (let i = 0; i < data.length; i++) {
                if (data[i]) {
                    isMediaPresent = true;
                    if (productsPicked[i] === '360') {
                        // if (data[i]?.skuId) {
                        //     // const hotspotData = await getHotspotData(data[i]?.skuId);
                        //     // if (hotspotData && hotspotData?.hotspots?.length) {
                        //     //     newData = {
                        //     //         ...data[i],
                        //     //         hotspots: hotspotData?.hotspots?.[0]
                        //     //     }
                        //     // }
                        // }
                        mediaData['360'] = data[i];

                    } else if (productsPicked[i] === 'image') {
                        mediaData['image'] = data[i].imageData
                    } else if (productsPicked[i] === 'video') {
                        mediaData['video'] = data[i]
                        // @todo: remove this fixed iframe url after testing 
                        let videoId = data[i]?.video_id || data[i]?.videoData?.[0]?.video_id;
                        if (videoId) {
                            mediaData['video'].iFrameUrl = `${process.env.REACT_APP_VIDEO_IFRAME_DOMAIN}/video?video_id=${videoId}&notVerified`
                        } else {
                            mediaData['video'].iFrameUrl = null;
                        }
                    }
                    if (data[i]?.dealerVinId) {
                        setDealerVinId(data[i]?.dealerVinId);
                    }
                    if (data[i]?.enterpriseId) {
                        setEnterpriseId(data[i]?.enterpriseId);
                    }

                }
            }

            // Set aspect ratio based on available images
            let aspectRatio = '16/9';
            if (productsPicked.includes('image') && mediaData.image && (mediaData.image.exterior?.[0]?.output_image_hres_url || mediaData.image['360_exterior']?.[0]?.output_image_hres_url)) {
                aspectRatio = await getAspectRatioFromImages(mediaData.image);
            } else if (productsPicked.includes('360') && mediaData['360']) {
                aspectRatio = await getAspectRatioFromSpinImage(mediaData?.['360']?.skuDetails[0]?.output_image_hres_url);
            }
            setAspectRatio(aspectRatio);

            mediaData['team_config'] = teamConfig
            mediaData['isMediaPresent'] = isMediaPresent;

            //store all media data inside skuData
            setSkuData(mediaData)

            // Create items array for carousel
            const items = transformMediaDataToItems(mediaData);
            setCarouselItems(items);

            //set visible tabs and selected tab based on team config
            const tabsBasedOnConfig = getMediaDynamicTab(mediaData) || [];
            setTabs(tabsBasedOnConfig);

            setDynamicTabImage(getDynamicTabImage(mediaData.image));
            if (tabsBasedOnConfig.length > 0) {
                setSelectedTab(tabsBasedOnConfig[0]?.key);
            }

            //set IMAGES TABS 
            // if (mediaData.image) {
            //     setDynamicTabImage(getDynamicTabImage(mediaData.image));
            // }

        } catch (error) {
            console.error('Error fetching media data:', error);
        } finally {
            const endTime = performance.now();
            const executionTime = endTime - startTime;
            
            setLoading(false);

            // Send analytics separately
            sendAnalytics(executionTime, apiCallFlag, params);
        }
    }
    useEffect(() => {
        if (params?.mediaId || (params?.skuName && params?.enterpriseId)) {
            setSkuName(params?.skuName || '');
            setEnterpriseId(params?.enterpriseId || '');
            setMediaId(params?.mediaId || '');
            fetchMediaData({ 'params': params, 'spin': Number(params?.spin), 'catalog': Number(params?.catalog), 'feature_video': Number(params?.feature_video) })

        }
        if (params?.template === "t2") {
            setSelectedTemplate("t2");
        } else {
            setSelectedTemplate("t1");
        }
    }, []);
    return { skuData, loading, selectedTemplate, selectedTab, setSelectedTab, tabs, carouselItems, dynamicTabImage, dealerVinId, enterpriseId, aspectRatio, skuName, mediaId }
}

export default useMediaDataAndTabs;