import React, { useState, useRef, useEffect } from 'react';

const ImageCategoryDropdown = ({
    options,
    selectedTab,
    onSelect,
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef?.current && !dropdownRef?.current?.contains(event?.target)) {
                setIsDropdownOpen(false);
            }
        };

        document?.addEventListener('mousedown', handleClickOutside);
        return () => document?.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className="absolute top-2 right-3 z-[999]" ref={dropdownRef}>
            <div className="relative">
                {/* Dropdown Button */}
                <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="flex items-center gap-2 px-2 py-1 rounded-[6px] bg-white-default border border-gray-200 text-black text-xs leading-5 font-semibold"
                >
                    <span>{selectedTab}</span>
                    <svg
                        className={`w-4 h-4 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>

                {/* Dropdown Menu */}
                {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-32 rounded-lg bg-white shadow-lg bg-white-default border border-gray-200 overflow-hidden">
                        {options?.map((option) => (
                            <button
                                key={option?.label}
                                onClick={() => {
                                    onSelect?.(option);
                                    setIsDropdownOpen(false);
                                }}
                                className={`w-full text-xs font-medium text-left px-4 py-2 hover:bg-gray-50 transition-colors
                  ${selectedTab === option?.label ? 'bg-blue-activeTabBg text-blue-activeBlue' : 'text-gray-700'}
                `}
                            >
                                {option?.label}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageCategoryDropdown; 