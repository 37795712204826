import { useRef, useState } from 'react';

const useTouchNavigation = (handleLeft, handleRight, carouselItems, selectedIndex) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 30;

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    if (e.touches.length > 1) return;
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      handleRight(carouselItems?.length, selectedIndex);
    }
    if (isRightSwipe) {
      handleLeft(carouselItems?.length, selectedIndex);
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length > 1) return;
    setTouchEnd(e.touches[0].clientX);
  }

  return {
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove
  };
};

export default useTouchNavigation; 