import { useEffect, useState, useRef } from "react";

const useCarouselNavigation = ({ carouselItems, selectedTab, setSelectedTab }) => {
    //type comes from TAB_ENUM
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [skipAnimation, setSkipAnimation] = useState(false);

    useEffect(() => {
        setSelectedIndex(0);
        setTotalItems(carouselItems?.length);
    }, [carouselItems]);

    const handleSelectThumbnail = (index, type) => {
        setSelectedTab(type);
        setSelectedIndex(index);
        setSkipAnimation(true);
    }

    const handleLeftRef = useRef(() => { });
    const handleRightRef = useRef(() => { });

    useEffect(() => {
        handleLeftRef.current = (totalItems, selectedIndex) => {
            if (totalItems > 0) {
                let newIndex = (selectedIndex - 1 + totalItems) % totalItems;
                setSelectedIndex(newIndex);
                setSelectedTab(carouselItems[newIndex]?.type);
                setSkipAnimation(false);
            }
        }

        handleRightRef.current = (totalItems, selectedIndex) => {
            if (totalItems > 0) {
                let newIndex = (selectedIndex + 1) % totalItems;
                setSelectedIndex(newIndex);
                setSelectedTab(carouselItems[newIndex]?.type);
                setSkipAnimation(false);
            }
        };
    }, [carouselItems, selectedIndex, totalItems, handleLeftRef, handleRightRef]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowLeft') {
                handleLeftRef.current(totalItems, selectedIndex);
            } else if (e.key === 'ArrowRight') {
                handleRightRef.current(totalItems, selectedIndex);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [selectedIndex, totalItems, handleLeftRef, handleRightRef]);


    return {
        selectedIndex,
        handleLeft: handleLeftRef.current,
        handleRight: handleRightRef.current,
        handleSelectThumbnail,
        skipAnimation
    }
};

export default useCarouselNavigation;