import React, { useContext, useEffect, useRef, useState } from 'react'
import {  NavigationContext } from './context';
import { LeftArrow, RightArrow } from '../common/config';
import GridItem from './GridItem';


const BottomSlider = ({ insideModal = false }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const sliderContainerRef = useRef(null);
  const { carouselItems, handleLeft, handleRight, selectedIndex} = useContext(NavigationContext);
  const [itemsInCenter, setItemsInCenter] = useState(false);


  const scrollThumbnails = (direction) => {
    if (sliderContainerRef.current) {
      // const scrollAmount = mobile ? 96 + 8 : 200 + 8; // width + gap
      const scrollAmount = 200 + 8;
      const currentScroll = sliderContainerRef.current.scrollLeft;
      sliderContainerRef.current.scrollTo({
        left: currentScroll + (direction === 'left' ? -scrollAmount : scrollAmount),
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (sliderContainerRef.current) {
      const containerWidth = sliderContainerRef.current.offsetWidth;
      const thumbnails = Array.from(sliderContainerRef.current.children);
      const totalWidth = thumbnails.reduce((acc, thumbnail) => acc + thumbnail.offsetWidth, 0);
      if (totalWidth > containerWidth) {
        setItemsInCenter(true);
      } else {
        setItemsInCenter(false);
      }
    }
  }, [sliderContainerRef, carouselItems, carouselItems?.length, selectedIndex, insideModal]);

  // const spinThumbnail = getSpinThumbnail({images: carouselItems, firstAngle: 0});

  //scroll to selected thumbnail
  useEffect(() => {
    const scrollToThumbnail = () => {
      if (sliderContainerRef.current) {
        const container = sliderContainerRef.current;
        const thumbnails = Array.from(container.children);
        const selectedThumbnail = thumbnails[selectedIndex];
        if (selectedThumbnail) {
          const containerWidth = container.offsetWidth;
          const thumbnailRect = selectedThumbnail.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          // Calculate the scroll position to center the thumbnail
          const scrollLeft = selectedThumbnail.offsetLeft - (containerWidth - thumbnailRect.width) / 2;
          if (thumbnailRect.left >= containerRect.left && thumbnailRect.right <= containerRect.right) {
            return;
          }

          container.scrollTo({
            left: scrollLeft,
            behavior: 'smooth'
          });
        }
      }
    }
    //wait for 50ms to ensuredom is uodated
    const timeoutId = setTimeout(scrollToThumbnail, 50);
    return () => clearTimeout(timeoutId);
  }, [selectedIndex, sliderContainerRef, carouselItems, carouselItems?.length, insideModal])

  return (
    <div className='w-full bg-white pt-2 h-full flex flex-row items-center relative'>
      {carouselItems?.length > 3 && (

        <LeftArrow handleLeft={() => {
          scrollThumbnails('left')
          handleLeft(carouselItems?.length, selectedIndex)
        }} className="w-7 h-7 z-[100] cursor-pointer" />
      )}
      <div className={`h-full w-full flex gap-x-2 overflow-x-scroll scrollbar-hide scroll-smooth ${itemsInCenter ? "" : "justify-center"}`}
        onMouseEnter={() => setIsScrolling(true)}
        onMouseLeave={() => setIsScrolling(false)}
        style={{
          scrollBehavior: 'smooth',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          scrollSnapType: 'x mandatory',
          padding: '8px',
          overflowY: 'visible',
        }}
        ref={sliderContainerRef}>
        {
          carouselItems?.length > 0 &&
          carouselItems?.map((item, index) => {
            return (
              <GridItem item={item} index={index} template={"t1"} />
            )
          })
        }

      </div>

      {carouselItems?.length > 3 && (

        <RightArrow handleRight={() => {
          scrollThumbnails('right')
          handleRight(carouselItems?.length, selectedIndex)
        }} className="w-7 h-7 z-[100] cursor-pointer" />
      )}
    </div>
  )
}

export default BottomSlider