const MinusIcon = ({ className }) => {
    return (
        <svg 
            width="16" 
            height="3" 
            viewBox="0 0 16 3" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg" 
            className={`${className ? className : "fill-white"}`}
            style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.50))' }}>
            <path 
                d="M14.4998 2.58659H1.49978C1.21246 2.58659 0.936908 2.47245 0.733744 2.26929C0.53058 2.06612 0.416443 1.79057 0.416443 1.50326C0.416443 1.21594 0.53058 0.940387 0.733744 0.737223C0.936908 0.534059 1.21246 0.419922 1.49978 0.419922H14.4998C14.7871 0.419922 15.0626 0.534059 15.2658 0.737223C15.469 0.940387 15.5831 1.21594 15.5831 1.50326C15.5831 1.79057 15.469 2.06612 15.2658 2.26929C15.0626 2.47245 14.7871 2.58659 14.4998 2.58659Z" 
            />
        </svg>

    )
}

export default MinusIcon