import React from 'react'

const getSpinThumbnail = ({images, firstAngle=null}) => {
  if(images && images.length > 0){
    if(firstAngle){
        const image = images.find((image)=>image?.['AI_ANGLE'] === firstAngle);
        if(image){
            return image;
        }else return images?.[0];
    }
    else return images[0];
    
  }
  return;
}

export default getSpinThumbnail