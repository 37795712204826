import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Loader1 from '../Svgs/svg1.svg';
import Loader2 from '../Svgs/svg2.svg';
import Loader3 from '../Svgs/svg3.svg';
import Loader4 from '../Svgs/svg4.svg';

const AnimatedLoader = ({ backgroundColorClass = 'bg-transparent' }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const spin_motion = queryParams.get('spin_motion');

  const [currentIndex, setCurrentIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const mobileView = window.innerWidth < 470;
  const ANIMATION_DURATION = 80;
  const INTERVAL_DURATION = ANIMATION_DURATION;

  const keyframes = `
    @keyframes slideInUp {
      0% {
        transform: translateY(12%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes slideOutUp {
      0% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(-25%);
        opacity: 0;
      }
    }
  `;

  const svgs = useMemo(() => [
    { id: "1", src: Loader1 },
    { id: "2", src: Loader2 },
    { id: "3", src: Loader3 },
    { id: "4", src: Loader4 }
  ], []);

  useEffect(() => {
    svgs.forEach(svg => {
      const img = new Image();
      img.src = svg.src;
    });
  }, [svgs]);

  const handleAnimationEnd = useCallback(() => {
    setIsAnimating(false);
    setPreviousIndex(null);
  }, []);

  useEffect(() => {
    if (isAnimating) return;

    const interval = setInterval(() => {
      setIsAnimating(true);
      setPreviousIndex(currentIndex);
      setCurrentIndex(prevIndex => (prevIndex + 1) % svgs.length);
    }, INTERVAL_DURATION);

    return () => clearInterval(interval);
  }, [currentIndex, isAnimating, svgs.length]);

  if (spin_motion) {
    return null;
  }

  return (
    <>
      <style>{keyframes}</style>
      <div className={`absolute inset-0 flex items-center justify-center min-h-screen overflow-hidden ${backgroundColorClass}`}>
        <div
          className="relative flex flex-col items-center justify-center"
          style={{
            width: mobileView ? '50vw' : '32vw',
            height: mobileView ? '50vh' : '34vh',
          }}
        >
          {previousIndex !== null && isAnimating && (
            <div
              key={`prev-${svgs[previousIndex].id}`}
              className="absolute w-full h-full"
              style={{ 
                animation: 'slideOutUp 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards',
              }}
              onAnimationEnd={handleAnimationEnd}
            >
              <img 
                src={svgs[previousIndex].src}
                alt={`Loader ${svgs[previousIndex].id}`}
                className="w-full h-full object-contain"
              />
            </div>
          )}

          <div
            key={`current-${svgs[currentIndex].id}`}
            className="absolute w-full h-full"
            style={
              isAnimating
                ? { animation: 'slideInUp 500ms cubic-bezier(0.4, 0, 0.2, 0.5) forwards' }
                : {}
            }
          >
            <img 
              src={svgs[currentIndex].src}
              alt={`Loader ${svgs[currentIndex].id}`}
              className="w-full h-full object-contain"
            />
          </div>

          <div className="absolute top-[110%]">
            <span className="text-[8px] text-gray-600 sm:text-[12px] md:text-[14px]">
              Loading Spin Media...
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimatedLoader;