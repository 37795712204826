import React, { createContext } from 'react';

// Split context into smaller pieces
export const MediaContext = createContext();
export const NavigationContext = createContext();
export const UIContext = createContext();

// Provider components
export const MediaProvider = ({ children, value }) => (
    <MediaContext.Provider value={value}>
        {children}
    </MediaContext.Provider>
);

export const NavigationProvider = ({ children, value }) => (
    <NavigationContext.Provider value={value}>
        {children}
    </NavigationContext.Provider>
);

export const UIProvider = ({ children, value }) => (
    <UIContext.Provider value={value}>
        {children}
    </UIContext.Provider>
);
