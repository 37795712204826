import React from 'react'
import View360 from "../Components/View360/View360"
import { checkIsV2, checkIsV3 } from '../utils/getAppVersion';
import CommonIframeHome from './CommonIframeHome';
import CommonIframeV3 from './CommonIframeV3';

const AppLayout = () => {
    const isV2 = checkIsV2();
    const isV3 = checkIsV3();

  return (
    <>
    {isV3 ? <CommonIframeV3/> : isV2 ? <CommonIframeHome/>  : <View360 />}
    </>
  )
}

export default AppLayout