import React, { useEffect, useState } from 'react';

const Tabs = ({ options, onSelect, customStyle, isV3 = false, selectedTab = '' }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  useEffect(() => {
    if (selectedTab && selectedTab !== '') {
        let option = options?.find((option) => option?.key === selectedTab);
        if (option) {
          handleSelect(option);
        }
    }
  }, [selectedTab, options]);


  return (
    <div className={`w-full font-medium text-center text-black z-50 ${customStyle && customStyle}`}>
      <ul className={`flex items-center justify-around text-nowrap gap-2.5 p-2 sm:p-0 md:p-2 ${isV3 ?  "!p-1 sm:!p-0 md:!p-1 lg:!p-2 h-[50px]" : ""}`}>
        {options?.length > 0 && options.map((tabItem) => (
          <button
            className={`w-full h-full flex py-3 sm:px-4 items-center rounded-xl sm:rounded-none  md:rounded-xl  ${isV3 ? "!py-2 !px-2 " : ""} bg-white sm:bg-transparent md:bg-white justify-center gap-2 text-xs sm:text-xs font-medium cursor-pointer shadow-black-8 sm:shadow-none md:shadow-black-8  ${selectedOption.label === tabItem.label ? ` ${customStyle ? "border-none" : "sm:border-blue-activeBlue"} sm:border-b-[3px] bg-blue-activeTabBg sm:bg-transparent md:bg-blue-activeTabBg ${customStyle ? "text-white-default !font-bold" : "text-blue-activeBlue"}  md:border-none` : 'border-none bg-white-default sm:bg-transparent md:bg-white-default shadow-black-8'} ${tabItem.isDisabled ? "pointer-events-none text-gray-500" : `${customStyle ? "text-white-70 font-medium" : "text-black-60"} `}  `}
            key={tabItem?.label}
            onClick={() => handleSelect(tabItem)}>
            {tabItem?.icon && (selectedOption.label === tabItem.label ? <img className={`h-5 w-5 ${isV3 && '!w-4 !h-4'} `} src={tabItem.activeIcon} alt={tabItem?.label} /> : <img className={`h-5 w-5 ${isV3 && '!w-4 !h-4'} `} src={tabItem.icon} alt={tabItem?.label} />)}
            {tabItem?.label}
            {tabItem?.showItemsLength && <span className={`max-sm:hidden rounded-full text-sm ${selectedOption.label === tabItem.label ? "bg-blue-15 text-blue-activeBlue" : "bg-black-80 text-white-default"} px-2`}>{tabItem.totalItem}</span>}
          </button>
        ))}


      </ul>
    </div>

  )
};


export default Tabs