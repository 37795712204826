import { useState, useEffect } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    deviceWidth: window.innerWidth,
    deviceHeight: window.innerHeight,
  });

  useEffect(() => {
    let timerId;

    const handleResize = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setWindowSize({
          deviceWidth: window.innerWidth,
          deviceHeight: window.innerHeight,
        });
      }, 150); // Adjust debounce delay as needed
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timerId);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
