import React, { useContext, useEffect, useState } from 'react'
import { MediaContext } from './context';
import Styles from '../../Css/View360/View360Common.module.css'
const VideoItem = ({ item, template }) => {
    const { aspectRatio } = useContext(MediaContext);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (item?.data?.iFrameUrl) {
            setIsLoading(true);
        }
    }, [item?.data?.iFrameUrl])
    return (
        <>
            {isLoading && (
                <div className="absolute inset-0 z-[50] overflow-hidden rounded-xl">
                    <div className={`${Styles.shimmerBackground} absolute inset-0`} />
                </div>
            )}
            <iframe
                src={item?.data?.iFrameUrl}
                title="Video"
                className="w-full h-full border-none relative z-0 rounded-xl object-cover object-center"
                style={{
                    aspectRatio: aspectRatio,
                    overflow: 'hidden'
                }}
                onLoad={() => {
                    setIsLoading(false);
                }}
            />
        </>
    )
}

export default VideoItem