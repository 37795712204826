import { IFRAME_MODULE_CONFIG } from "../Components/common/config";

export const checkIsV2 = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const isV2 = queryParams.get(IFRAME_MODULE_CONFIG.paramsKey);
    return isV2 === IFRAME_MODULE_CONFIG.paramsValue;
};

export const checkIsV3 = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const isV3 = queryParams.get("version");
    return isV3 === "v3";
}