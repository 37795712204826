export const getParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    try {       
        const skuId = queryParams.get('sku_id');
        let skuName = queryParams.get('sku_name')
        let enterpriseId = queryParams.get('enterprise_id')
           let params = skuId ? {skuId} : {skuName,enterpriseId}

        params.spin = queryParams.get('spin')
        params.feature_video = queryParams.get('feature_video')
        params.catalog = queryParams.get('catalog')
        params.mediaId = queryParams.get('mediaId')
        params.videoId = queryParams.get('videoId')
        params.demo    = queryParams.get('demo')
        params.template = queryParams.get('template')
        params.cols = queryParams.get('cols')

        return {params}
    } catch (error) {
        
    }

}