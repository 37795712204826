import React, { PureComponent} from 'react'
import centralAPI from '../../CentralAPIHandler/CentralAPIHandler';
import View360HelperText from './View360HelperText';
import Styles from '../../Css/View360/View360.module.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';


// import Switch from "react-switch";
import 'animate.css';
import View360Slider from './View360Slider'
import Loader from '../common/Loader';
// redux
import {connect} from 'react-redux'
import {updateWholeHotSpotMapObj} from '../../Redux/Reducers/View360Reducer/View360ActionController'
import ToggleButton from './ToggleButton';
import MobileViewSlider from './MobileViewSlider';
import InteriorFrameDropdown from './InteriorFrameDropdown';
import InteriorIframe from './InteriorIframe';
import { captureEvents , amplitudeEvents } from '../../utils/utilityFunctions';
import PreFetchImages from './PreFetchImages/PreFetchImages';
import BeforeAfterHeading from './BeforeAfterContainer/BeforeAfterHeading';
import BeforeContainer from './BeforeAfterContainer/BeforeContainer';
import HotspotToggleHandler from './HotspotToggleHandler/HotspotToggleHandler';
import MobileToggleHandler from './HotspotToggleHandler/MobileToggleHandler';
import MobileViewHeadingContainer from './BeforeAfterContainer/MobileViewHeadingContainer';
import MobileViewAfterContainer from './BeforeAfterContainer/MobileViewAfterContainer';
import FallbackUrl from './FallbackUrl/FallbackUrl';
import UnderneathIframe from './UnderneathIframe';
import UnderneathFrameDropdown from './UnderneathFrameDropdown';
import throttle from 'lodash/throttle'; // For throttling move events
import { checkIsV3 } from '../../utils/getAppVersion';
import SVG from '../common/svg/SVG';

class View360 extends PureComponent {


    constructor(props){
        super(props);
        const savedTotalTime = parseInt(localStorage.getItem('totalEngagementTime'), 10);
        this.elementRef = React.createRef();
        this.state = {
            images: [],
            automatedImageSpin: true,
            clockwise: false,
            angleOrderIncreasing: true,
            imageIndex : 0,
            noOfImg: 0,
            oldx : 0,
            showDownloadBtn : true,
            firstImageLoadTime: 0,
            firstImageLoadedTime: 0,
            interiorPresent: false,
            underneathPresent: false,
            mobileView: false, 
            loading: true,
            output_video_url: '',
            imgId: '',
            dealerUrl : '',
            isEngagementStarted: false,
            engagementTimeout: null,
            totalEngagementTime: 0,
            engagementExterior: 0,
            city: '',
            country: '',
            hotspotTimespent: 0,
            allImagesLoaded: false,
            progressCount: 0,
            downloadProgress: 0,
            isDownloading: false,
            enterpriseid_for_download : '',
            skuId_for_download: '',
            iframePageViewTime: 0,
            hotspotImageId: '',
            completeSpinTime: 0,
            hotSpotsToMap: [],
            hotspotViewingTime: null,
            isIntTabActive: false,
            ToggleInteriorHotspots: false,
            hotspotDataInterior: [],
            dragSpeed: 5,
            hotSpotModal: {
                show: false,
            },

           //Mock Interior Hotspot
           interiorData: [
            {
                    
                hotspots: [
                            {
                                hotspot_id: "6d5da2028f844ecc996704fe32ab8659",
                                type: "tag1",
                                reasons: ["Reason1"],
                                hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                focus_image_url: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                coordinates: { x: 0, y: 0 , z:0 }
                            },
                            {
                                hotspot_id: "8da5b950a2274e769aa7696c346ca436",
                                type: "tag2",
                                reasons: ["Reason2"],
                                hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                focus_image_url: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                coordinates: { x: 2, y: 2 , z:2 }
                            },
                            {
                                hotspot_id: "8da5b950a2274e769aa7696c346ca436",
                                type: "tag4",
                                reasons: ["Reason4"],
                                hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                focus_image_url: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                                coordinates: { x: -4, y: 19 , z:3 }
                            }
                        ]
                    },
                ],
            

            freshLoad: true,
            toggleHotspotView: false,
            imageNewDimensions : {
                isNewDimensionsUpdated : false,
                isPadByWidth: false,
                newImgWidth: 1,
                newImgHeight: 1,
                newContainerWidth:  1,
                newContainerHeight:  1,
                paddingHalf: 0
            },
            modalList: new Map(),
            errStatus : {
                code: 500, // handle errors
                show: false
            },
            sessionId: "session_"+uuidv4(),
            sessionStartTime: null,
            goToSlide: 0, // open this slide on modal,
            lazyLoadImages: false,
            renderHotSpots: false,
            isAnyHotSpotPresent: false,
            isRequestFor3DModel: false,
            isInput360Requested: false,
            isSubmitting: false,
            totalTimeSpent: 0,
            isZoomActive: false,
            isDragging:false,
            isFunctionLoaded : false , 
            currentZoomLevel: 100,
            isToggleEnableJson : false,
            sourceLocation: '',
            angle: 0,
            showHelperText: true,
            skuName: '',
            showExtOrInt : 'EXT',
            isLoaded : false,
            interior360Url : [],
            showValue : {},
            showHotspot: false,
            animationEnd : {},
            zoomValue : 0,
            hotspotTimingTotal: 0,
            underneathViewingTime : 0,
            skuId: null,
            selectedHotspotObjKey : null,
            isDownloadEnabled : false,
            activeHotspot : null,
            isAnyFocusImage : true,
            hotspotActiveValue : null,
            clickHotspot: false,
            imageLoaded : false,
            zoomEnabled : false,
            hasZoomed: false,
            spinStartTime: null,
            interiorViewingTime: 0,
            notMoveImageOnHover: false,
            isOriginalCategory: false,
            hotspotStartTime: null,
            zoomLevel: 0,
            startX:0,
            startY:0,
            currentX:0,
            currentY:0,
            spinMotion : null,
            beforAfterView : false,
            showhotspotBeforeAfter : false,
            showInteriorHotspot: false,
            activeToggleBeforeAfter : 'hotspot',
            video360Urls : [],
            visitedIndexes: new Set(),
            visitCount: 0,
            frames: 0,
            showMinWidthWebView : null,
            selectedUrl : { 'name' : '' ,'url' : '' , 'id' : '' },
            selectedUnderneathUrl : { 'name' : '' ,'url' : '' },
            showDropdown : false,
            IsVdp: false,
            timeStart: null,
            timeEnd: null,
            userStats: {
                rotationStats: {
                    percentageFramesVisited: 0,
                    visitedIndexes: new Map(),
                    mouseFramesCount: 0
                },
                hotspotStats:{
                    clickCount: {},
                    viewedFullScreenModal: false
                },
                timeStats: {
                    fetchImagesAPITime: 0,
                    hotspotDataAPITime: 0,
                    firstImageLoadTime: 0,
                    userRetentionTime: 0
                }
            },
            showInteriorFrame : true,
            showHotspotWhenImagesLoaded : false,
            showOverlays : true
        }

        this.handleHotSpotViewToggle = this.handleHotSpotViewToggle.bind(this);
        this.lastMoveTimestamp = null;
        this.accumulatedDuration = 0;
        this.isEngagementStarted = false;
        this.endEngagementTimeout = null;
        this.engagementStartTime = null;
        this.isProcessingMove = false;
        this.moveQueue = [];
        this.keyPressTimeout = null;
        this.engagementInterval = null; // To hold the interval ID
        this.handleMouseMove = throttle(this.move, 100); 
        this.zoomInBtn = React.createRef();
        this.zoomOutBtn = React.createRef()
        this.resetBtn = React.createRef();
        this.transformRef = React.createRef();
        this.calculatePageTime = this.calculatePageTime.bind(this);
        this.closeSession = this.closeSession.bind(this);

    }
    componentDidMount = async () => {
        const queryParams = new URLSearchParams(window?.location?.search);
        // console.log('First Image Load' , this.state.first)
        const url =  window.location?.ancestorOrigins?.[0] || document?.referrer;
        this.setState({dealerUrl : url});
        this.setState({ sourceLocation : window.location.href})
        localStorage.removeItem('totalEngagementTime');

     
        
       
       
        await this.startSession();
        let timeStart = new Date().getTime();
        this.setState({
            ...this.state,
            timeStart: timeStart
        })

        this.fetchIpInfo();

        // track window resize, and update NewImageDimensions
        window.addEventListener('beforeunload', this.closeSession);
        window.addEventListener('resize', this.updateNewImageDimensions);
        
        
        // window.addEventListener('beforeunload', this.handleBeforeUnload);
        window.addEventListener('unload', this.closeSession);
        const lastApiResponse = localStorage.getItem('lastApiResponse');
        if (lastApiResponse) {
            // console.log('Last API Response:', JSON.parse(lastApiResponse));
            // Clear it after checking
            localStorage.removeItem('lastApiResponse');
        }
    
        // Check if there were any errors
        const lastError = localStorage.getItem('lastCloseSessionError');
        if (lastError) {
            // console.log('Last Error:', JSON.parse(lastError));
            localStorage.removeItem('lastCloseSessionError');
        }

        if(this.props && this.props.dragSpeed){
            this.setState({
                ...this.state,
                dragSpeed: this.props.dragSpeed
            })
        }
        window.addEventListener('message', this.handleMessage);
        const {imageCategory, type} = this.getSkuIdAndImageCategory();
        if( imageCategory && imageCategory==='3D_Model'){
            this.setState({...this.state, 'isRequestFor3DModel': true})
        }else if(type && type==='input'){
            this.setState({...this.state, 'isInput360Requested': true , showDownloadBtn:false })
        }
        if(window.innerWidth < 400) {
            this.setState({mobileView: true});
        }
        await this.fetchImages();
                document.addEventListener('keydown', this.handleKeyDown); 
              this.downloadStatus();
    }

    componentWillUnmount() {
        try {
            // Remove event listeners
            window.removeEventListener('beforeunload', this.closeSession);
            document.removeEventListener('keydown', this.handleKeyDown);
            window.removeEventListener('resize', this.updateNewImageDimensions);
            window.removeEventListener('message', this.handleMessage);
            window.removeEventListener('unload', this.closeSession);
    
            // Perform synchronous API call
            this.closeSession();
        } catch (error) {
            console.error('Error in componentWillUnmount:', error);
        }
    }
    

    componentDidUpdate = (prevProps, prevState) => {
        // Add check for selectedIndex change
        if (prevProps?.selectedIndex !== this?.props?.selectedIndex) {
            this.setState({
                zoomLevel: 0,
                currentX: 0,
                currentY: 0,
                hasZoomed: false,
                zoomEnabled: false,
                hotSpotModal: { show: false },
                automatedImageSpin: false
            });
        }
        if (prevState.loading !== this.state.loading && !this.state.loading) {
            const queryParams = new URLSearchParams(window.location.search);
            window?.parent?.postMessage(
                JSON.stringify({
                    type: "IFRAME_LOADED",
                    value: queryParams?.get("sku_id"),
                }),
                "*"
            );
        }
        if (this.state.allImagesLoaded) {
            this.setState({ automatedImageSpin: true });
        }
        // Fix: Check if zoomLevel crossed the threshold from previous state
        if (prevState.zoomLevel <= 0.1 && this.state.zoomLevel > 0.1) {
            this.setState({ toggleHotspotView: false });
        }
        if (prevState.automatedImageSpin !== this.state.automatedImageSpin) {
            if (this.state.automatedImageSpin) {
                this.handleImageSpin();
            }
        }
        if (prevState.showExtOrInt !== this.state.showExtOrInt) {
            if (this.state.showExtOrInt === 'INT') {
                this.setState({
                    ToggleInteriorHotspots: false,
                    showDownloadBtn: false,
                    zoomLevel: 0 , // Added zoom level reset
                    hasZoomed : false,
                });
            } else if (this.state.showExtOrInt === 'EXT') {
                this.setState({ showDownloadBtn: true });
            }
        }
        if (prevState.angleOrderIncreasing !== this.state.angleOrderIncreasing) {
            if (!this.state.angleOrderIncreasing) {
                this.setState({ images: this.state.images.reverse() });
            }
        }
        window.addEventListener('message', (event) => {
            if(event.data?.fetchData){
                window?.parent?.postMessage(
                    {image_id: this.state?.imgId}, "*"
                );
            }
        });

    }

    calculatePageTime() {
        if (this.state.timeStart) {
            const currentTime = new Date().getTime();
            const duration = Math.floor((currentTime - this.state?.timeStart) / 1000);
            this.setState({ 
                totalPageTimeInSeconds: duration 
            });
            return duration;
        }
        return 0;
    }

     fetchIpInfo = async () => {
        // try {
        //   const response = await fetch(`https://ipinfo.io/json?token=${process.env.REACT_APP_IP_INFO_TOKEN}`);
        //   if (!response.ok) {
        //     throw new Error(`Network response was not ok (${response.status})`);
        //   }
        //   const data = await response.json();
        //   this.setState({city: data.city});
        //   this.setState({country: data.country})
        // } catch (err) {
        //   console.error('There was a problem with the fetch operation:', err);
        // }
      };
      

    apiCallWrapper = async (skuId, value) => {
        try {
            const {skuName,enterpriseId} = this.getSkuIdAndImageCategory();
            this.setState({skuName: skuName});
            const event_key= value==='image_list'? 'start_json1': 'start_json2';
            const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
            const time = offsetDate?.toISOString()?.slice(0, -1);
            this.captureEvent(event_key);
            const URL= (value==='image_list' && skuName)? `https://dx58rqsf48le3.cloudfront.net/Spinjson/${enterpriseId}/${skuName}/${value}.json` : `https://dx58rqsf48le3.cloudfront.net/Spinjson/${skuId}/${value}.json`;
            let response = await centralAPI.handleGetRequest(URL);
            amplitudeEvents({
                event: event_key,
                customAmplitudeEvents: {
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download,
                    time: time
                }
            })
            return response;
            
        } 
        
        
        catch (error) {
            return {}; 
        }
        
    };

    // spinTimeEvent = () => {
    //     if (this.state.automatedImageSpin) {
    //         this.state.spinStartTime = Date.now();
    //     } 
        
    //     else {
    //         if (this.state.spinStartTime) {
    //             const endTime = Date.now();
    //             const timeSpentMs = endTime - this.state.spinStartTime;
    //             const timeSpentSeconds = timeSpentMs / 1000;
                
    //             console.log(`Spin time: ${timeSpentSeconds} seconds`);
    //             this.setState({
    //                 spinStartTime: null,
    //                 completeSpinTime: timeSpentSeconds
    //             } , ()=> {
    //                 console.log(this.state.completeSpinTime , 'spin time in seconds')
    //             });
    //         }
    //     }
    // }


    capturejsonResponseEndEvent =(value, failed=false)=>{
        try {
            const endTime= new Date().toISOString();
            const {skuId , skuName} = this.getSkuIdAndImageCategory();
            const key = 'image_list'? 'end_json1': 'end_json2'
            captureEvents({
                event: value==='image_list'? 'end_json1': 'end_json2', 
                custom: {
                    time: endTime,
                    res_failed: !!failed,
                    iframe_session_id: this.state.sessionId,
                    sku_id: skuId || this.state.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download,
                }
            }            
            );

            amplitudeEvents({
                event: value==='image_list'? 'end_json1': 'end_json2', 
                customAmplitudeEvents: {
                    time: endTime,
                    res_failed: !!failed,
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download,
                    skuName: skuName
                }
            })
        } catch (error) {
            
        }
    }

    
    captureEvent = (event)=>{
        try {
            const startTime= new Date().toISOString();
            const {skuId , skuName} = this.getSkuIdAndImageCategory();

            captureEvents({
                event,
                custom: {
                time: startTime,
                iframe_session_id: this.state.sessionId,
                sku_id: skuId || this.state.skuId_for_download ,
                enterprise_id: this.state.enterpriseid_for_download
                }
            })
        } catch (error) {
            
        }
        
    }



   

        startSession = async  ()=>{
            try {
                const istOffset = 5.5 * 60; 
                const startTime = new Date(Date.now() + (istOffset + new Date().getTimezoneOffset()) * 60000);
                this.setState({ sessionStartTime: startTime });
                const { skuId, skuName } = this.getSkuIdAndImageCategory();
                this.setState({ iframePageViewTime: startTime });

                captureEvents({
                    event: 'iframe_page_view',
                    custom: {
                        time: startTime,
                        iframe_session_id: this.state.sessionId,
                        sku_id: skuId || this.state.skuId_for_download,
                        enterprise_id: this.state.enterpriseid_for_download
                    }
                })
              
                amplitudeEvents({
                    event: 'iframe_page_view',
                    customAmplitudeEvents: {
                        time: startTime,
                        iframe_session_id: this.state?.sessionId,
                        sku_id: skuId || this.state?.skuId_for_download,
                        enterprise_id: this.state?.enterpriseid_for_download, 
                        skuName: skuName
                    }
                })
            } catch (error) {
            
            }
        
        }
 
        closeSession = async (event) => {
            // Prevent multiple simultaneous submissions
            if (this.state?.isSubmitting) {
                // console.log('Submission already in progress');
                return;
            }
            if (this.state?.showConfig) {
                return;
            }
            let seconds;
            const exteriorvalue =  localStorage.getItem('totalexteriortime');
            // Set submission flag immediately
            this.setState({ isSubmitting: true });
            try {
                const totalTimeSpentOnExterior = this.calculatePageTime();
                const totalEngagementDuration = 
                    (this.state?.totalTimeSpent || 0) + 
                    (this.state?.engagementExterior || 0) + 
                    (this.state?.interiorViewingTime || 0);
                localStorage.setItem('totalEnagagementDuration' , totalEngagementDuration , )
                localStorage.setItem('ExteriorEngagement' , this.state?.engagementExterior )

                const spinCompletionRate = (this.state?.visitCount / this.state?.frames)*100
                
                const additionalViewPercentage = 
                    ((this.state?.interiorViewingTime || 0) + (this.state?.underneathViewingTime || 0)) / 
                    (totalEngagementDuration || 1) * 100;
                    const iframePageViewTime = new Date(this.state?.iframePageViewTime);
                    const firstImageLoadTime = new Date(this.state?.firstImageLoadedTime);
                    const iframeLoadingSpeed = Math.abs(iframePageViewTime.getTime() - firstImageLoadTime.getTime());
                    seconds = iframeLoadingSpeed / 1000;

                 
                const requestBody = {
                    sessionId: this.state?.sessionId,
                    skuId: this.state?.skuId_for_download,
                    enterpriseId: this.state?.enterpriseid_for_download,
                    exteriorEngagementTime: exteriorvalue ?? this.state?.engagementExterior ?? 0,
                    interiorEngagementTime: this.state?.interiorViewingTime || 0,
                    totalEngagementDuration: totalEngagementDuration || 0,
                    spinCompletionRate: spinCompletionRate || 0,
                    iFrameLoadingSpeed: seconds || 0,
                    additionalViewPercentage: additionalViewPercentage || 0,
                    interiorViewingTime: this.state?.interiorViewingTime || 0,
                    exteriorViewingTime: totalTimeSpentOnExterior || 0,
                    hotspotViewingTime: this.state?.totalTimeSpent || 0,
                    interiors: this.state?.interiorPresent || false,
                    underneath: this.state?.underneathPresent || false,
                    hotspots: this.state?.isAnyHotSpotPresent || false,
                    url: this.state?.dealerUrl || '',
                    city: this.state?.city || '',
                    country: this.state?.country || '',
                    skuName: this.state?.skuName || "Test_Product"
                };
                const submissionId = `${this.state?.sessionId}-${Date.now()}`;
                const lastSubmission = localStorage.getItem('lastSubmissionId');
                if (lastSubmission === submissionId) {
                    // console.log('Duplicate submission prevented');
                    this.setState({ isSubmitting: false });
                    return;
                }
    
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 5000);
    
                try {
                    const response = await fetch(`${process.env.REACT_APP_BASEURL_API_SPYNE}/spin/create-update-amplitude`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            ...requestBody,
                            submissionId  
                        }),
                        keepalive: true,
                        mode: 'cors',
                        signal: controller.signal
                    });
    
                    clearTimeout(timeoutId);
    
                    localStorage.setItem('lastSubmissionId', submissionId);
                    localStorage.setItem('lastCloseSessionAttempt', JSON.stringify({
                        timestamp: new Date().toISOString(),
                        success: response.ok,
                        status: response.status,
                        submissionId
                    }));
    
                    return response;
                } catch (fetchError) {
                    clearTimeout(timeoutId);
                    const blob = new Blob([JSON.stringify({
                        ...requestBody,
                        submissionId
                    })], { type: 'application/json' });
                    
                    const beaconSuccess = navigator.sendBeacon(
                        `${process.env.REACT_APP_BASEURL_API_SPYNE}/spin/create-update-amplitude`, 
                        blob
                    );
                    
    
                    localStorage.setItem('lastCloseSessionError', JSON.stringify({
                        timestamp: new Date().toISOString(),
                        fetchError: fetchError.message,
                        beaconSuccess: beaconSuccess,
                        submissionId
                    }));
                } finally {
                    this.setState({ isSubmitting: false });
                }
            } catch (error) {
                this.setState({ isSubmitting: false });
    
                localStorage.setItem('lastCloseSessionError', JSON.stringify({
                    timestamp: new Date().toISOString(),
                    error: error.message,
                    stack: error.stack
                }));
            }
        }
        
        
        
        
    
    
    // Helper method to save failed metrics for retry
    saveFailedMetricsToStorage = (failedMetric) => {
        try {
            const failedMetrics = JSON.parse(localStorage.getItem('failedMetrics') || '[]');
            failedMetrics.push(failedMetric);
            localStorage.setItem('failedMetrics', JSON.stringify(failedMetrics));
        } catch (error) {
            console.error('Error saving failed metrics:', error);
        }
    }
    
    // Helper method to log errors
    logError = (errorData) => {
        // Implement your error logging logic here
        // This could be sending to a service like Sentry, LogRocket, etc.
        console.error('Logging error:', errorData);
    }


    handleInteriorTimeSpent = (time) => {
        this.setState({interiorViewingTime : time} , () => {
            // console.log('time spent in interior' ,  this.state.interiorViewingTime)
        });
        return time;
    }
    handleUnderneathTimeSpent = (time) => {
        this.setState({underneathViewingTime : time} , () => {
            // console.log('underneathViewingTime' , this.state.underneathViewingTime);
        })
        return time;
    }
    updateButtonState = () => {
        if (this.state.showExtOrInt === 'INT') {
          this.setState({ showDownloadBtn: true });
        } else {
          this.setState({ showDownloadBtn: false });
        }
      };

    
      
    // handleBeforeUnload = (event) => {
    // let clickCountJSON = JSON.stringify(this.state.userStats.hotspotStats.clickCount);
    // captureEvents({event: 'iFrame user statistics', custom: {
    // percentageFramesVisited: this.state.userStats.rotationStats.percentageFramesVisited,
    // mouseFramesCount: this.state.userStats.rotationStats.mouseFramesCount,
    // clickCount: clickCountJSON,
    // userRetentionTime: (new Date().getTime() - this.state.timeStart)/1000,
    // }
    // });
    // // Standard way to show a confirmation dialog
    // event.returnValue = ''; // some browsers require setting returnValue to show the dialog
    // }
   
    checkAngleDirection(images){
        try {
          const angles = new Set(images.map((image) => image.angle));
          const uniqueAngles = Array.from(angles);
          if(uniqueAngles.length < 3 ) return "Increasing";
          let increasingCnt = 0;
          let decreasingCnt = 0;
          for (let i = uniqueAngles.length - 1; i > 0; i--) {
            if (uniqueAngles[i] > uniqueAngles[i - 1]) {
              if (decreasingCnt > 0) decreasingCnt = 0;
              increasingCnt++;
              if (increasingCnt > 1) {
                return "Increasing";
              }
            } else {
              if (increasingCnt > 0) increasingCnt = 0;
              decreasingCnt++;
              if (decreasingCnt > 1) {
                return "Decreasing";
              }
            }
          }
        } catch (error) {}
        }

    getImageResolution=(imageUrl)=> {
        try {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = 'https://media.spyneai.com/unsafe/filters:format(webp)/'+imageUrl;
    
            img.onload = function() {
              const width = img.width;
              const height = img.height;
              resolve({ width, height });
            };
    
            img.onerror = function() {
              reject(new Error("Failed to load the image."));
            };
          });
        } catch (error) {
            console.log(error)
        }
      }

    getSkuIdAndImageCategory = ()=>{
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const skuId = queryParams.get('sku_id');
            const imageCategory = queryParams.get('image_category');
            let type = queryParams.get('type')
            let hotspot_val = queryParams.get('hotspot')
            ? JSON.parse(queryParams.get('hotspot'))
            : 'hotspot' in this.props 
                ? !!this.props.hotspot 
                : true;            
            let showHotspot = queryParams.get('showHotspot')?JSON.parse(queryParams.get('showHotspot')):false
            let config = queryParams.get('hideConfig')?JSON.parse(queryParams.get('hideConfig')):false
            let skuName = queryParams.get('sku_name')
            let enterpriseId = queryParams.get('enterprise_id')
            let spinMotion = queryParams?.get('spin_motion') ? JSON.parse(queryParams.get('spin_motion')) : false
            let beforAfterView = queryParams?.get('input')
            let webView = queryParams?.get('web_view') ? JSON.parse(queryParams.get('web_view')) : false
            let showInteriorFrame = queryParams?.get('enable_int_frame') ? JSON.parse(queryParams.get('enable_int_frame')) : true
            let mediaId = queryParams?.get('mediaId')
            if (!type) type = 'output'
            hotspot_val = !(webView || !hotspot_val)

            return { skuId, imageCategory, type, skuName, enterpriseId, hotspot_val, spinMotion,beforAfterView,showInteriorFrame , config, showHotspot, mediaId}
        } catch (error) {
            //console.log(error);
        }
    }

    /**
     * Unload preloaded media data, parsing as per requirements
     * @param {*} key 
     * @returns 
     */
    unloadPreloadedMediaData = (key)=>{
        if(key === 'hotspot_list'){
            return {hotspots:[this.props.preloadedData.hotspots]}
        }
        if(!this?.props?.preloadedData?.sequenceData || Object.keys(this?.props?.preloadedData?.sequenceData).length === 0){
            return {
                ...this?.props?.preloadedData,
                sequenceData: {
                    first_frame_angle: 40,
                    is_active: true,
                    rotation: "clockwise",
                }
            }
        }
        return this.props.preloadedData
    }
    /**
     * Fetch images and hotspots from db
     * @returns 
     */
    fetchImages = async()=>{
        try {
            const {skuId, imageCategory, type,skuName,enterpriseId,hotspot_val,spinMotion,beforAfterView,showInteriorFrame , config, showHotspot, mediaId} = this.getSkuIdAndImageCategory();
            let images=[];
            const allImageInObject = {}
            
            if(!skuId && !skuName && !mediaId) return;
            if(showHotspot){
                this.setState({showHotspot : true})
            }
            // console.log("Hotspot values " , hotspot_val)

            // const URL = `${process.env.REACT_APP_IMAGECREW_NODE_PROJ_PREFIX}/api/v1/hotspot/get-sku-image-data`
            const URL = `https://prod-360-api.tech-dc2.workers.dev`;
            var params = {}

            if(skuName && enterpriseId){
                params = {
                    'imageCategory' : imageCategory,
                    'type': type,
                    'skuName' : skuName,
                    'enterpriseId' : enterpriseId
                }
            }else{
                params = {
                    'skuId': skuId,
                    'imageCategory' : imageCategory,
                    'type': type
                }
            }

            let fetchImagesStartTime = new Date().getTime();
            // const { skuDetails,interior360Data,video_urls } = await centralAPI.handleGetRequest(URL, params);
       
            // this.updateStats({fetchImagesAPITime});

            // console.log(params)let skuDetails
            let skuDetails
            let interior360Data
            let underneathData
            let response
            let video_urls = []
            let IsVdp
            let rotationDirection
            let enterprise_id_download
            let sku_id_download
            // this.setState({...this.state, IsVdp: isVdp, skuId: skuId })
            // let cachePresent = sessionStorage?.getItem(`360`) ? JSON.parse(sessionStorage.getItem(`360`))?.[skuId] : null
            // if(cachePresent && type == 'output'){
            //     skuDetails = cachePresent?.skuDetails
            //     video_urls = cachePresent?.video_urls
            //     interior360Data = cachePresent?.interior360Data
            //     IsVdp = cachePresent?.isVdp
            // }else{
                let isV3= checkIsV3()
                let skuApiStartTime = new Date();
                let isJson = false;
                let urlParams = new URLSearchParams(window.location.search);
                let skipJSON = urlParams.get('skipJSON')==='true';
                response = ( mediaId || isV3 ) ? this.unloadPreloadedMediaData() : !skipJSON ? await this.apiCallWrapper(skuId, 'image_list') : {};
                let detect_hotspot = response?.detectHotspot;
                let jsonResponseStatus = response;
                if (!response?.skuDetails?.length) {
                    this.captureEvent('start_api1')
                    const startTime= new Date().toISOString();
                    const {skuId , skuName} = this.getSkuIdAndImageCategory();
                    amplitudeEvents({
                        event:'start_api1',
                        customAmplitudeEvents: (
                            {   
                                time: startTime,
                                iframe_session_id: this.state?.sessionId,
                                sku_id: skuId || this.state?.skuId_for_download ,
                                enterprise_id: this.state?.enterpriseid_for_download
                            }
                        )
                    })
                    response = await centralAPI.handleGetRequest(URL, params);
                    this.captureEvent('end_api1')
                    amplitudeEvents({
                        event:'end_api1',
                        customAmplitudeEvents: (
                            {   
                                time: startTime,
                                iframe_session_id: this.state?.sessionId,
                                sku_id: skuId || this.state?.skuId_for_download ,
                                enterprise_id: this.state?.enterpriseid_for_download
                            }
                        )
                    })
                    isJson = false;
                } else {
                        isJson = true;
                    if(detect_hotspot) {
                        this.setState({isToggleEnableJson: true})
                    }
                }
                this.capturejsonResponseEndEvent('image_list', !isJson);


                // captureEvents({event: 'sku_api_successful', custom: {
                //         'time_taken': skuApiEndTime - skuApiStartTime,
                //         // 'json_data': JSON.stringify(response)
                // }})   
                skuDetails = response?.skuDetails
                interior360Data = response?.interior360Data.filter(data => !data?.tags?.overlay_name?.includes('Underneath'))
                underneathData = response?.interior360Data.filter(data => data?.tags?.overlay_name?.includes('Underneath'));
                video_urls = response?.video_urls
                IsVdp = response?.isVdp
                rotationDirection = response?.sequenceData?.rotation;
                enterprise_id_download = response?.skuDetails[0].enterprise_id;
                sku_id_download = response?.skuId
                this.setState({enterpriseid_for_download: enterprise_id_download})
                this.setState({skuId_for_download : sku_id_download})
                this.setState({frames : response?.skuDetails?.length})

                if(response?.interior360Data.length)
                {
                    this.setState({interiorPresent : true,})
                    amplitudeEvents({
                        event: 'interior_present',
                        customAmplitudeEvents: {
                            iframe_session_id: this.state?.sessionId,
                            sku_id: skuId || this.state?.skuId_for_download ,
                            enterprise_id: this.state?.enterpriseid_for_download,
                            skuName: skuName,
                            }
                    })
                }
                if(underneathData?.length){
                    this.setState({underneathPresent: true})
                }

                // sessionStorage.setItem(`${skuId}`, JSON.stringify(response))
                // type==='output' && sessionStorage.setItem(`360`, JSON.stringify({[skuId] : response})) 
            // }
            let fetchImagesEndTime = new Date().getTime();
            let fetchImagesAPITime = (fetchImagesEndTime-fetchImagesStartTime)/1000;            
            if(!skuDetails || !skuDetails.length) throw Error('Fetch req successful, still skuDetails came out as null or empty array');
            if(IsVdp && imageCategory==='360_exterior' ){
                const exteriorURL = `${process.env.REACT_APP_IMAGECREW_NODE_PROJ_PREFIX}/spin/impel-exterior-image-data`
                let params = {}
                if(skuName && enterpriseId){
                params = {
                    'skuName' : skuName,
                    'enterpriseId' : enterpriseId
                }
                }else{
                    params = {
                    'skuId': skuId,
                }}

                const exteriorResponse = await centralAPI.handleGetRequest(exteriorURL, params);
                skuDetails= exteriorResponse?.skuDetails
                    
            }

            let result;
            if(window?.innerWidth < 440 && !spinMotion){
                let response =await this.getImageResolution(skuDetails[0]?.['output_image_hres_url'])
                const {width,height} = response
                result = this.calculateAspectRatio(width, height)

            }
            const allImageIDs = [];
            let imageCategoryForJson = '3D_Model'
            let notShowCategory = {
                '3D_Model': '360_original',
                '360_original': '3D_Model',
                '3d_model': '360_original'
            };
            let image = ['3D_Model', '360_original','3d_model']; 
            if (isJson) imageCategoryForJson = this.checkCategory(skuDetails);
            let effectiveCategory = image?.includes(imageCategory) ? imageCategory : null;
            const orignalCategory = response?.skuDetails?.some(item => item.image_category?.includes('360_original'));
            
            if(imageCategory === '360_original' && !orignalCategory){
                effectiveCategory = '3d_model';
            }

            // Add this sorting function
            const sortImagesByFirstFrameAngle = (images, firstFrameAngle, rotationDirection) => {
                // More defensive checks
                if (!Array.isArray(images) || images.length === 0 || firstFrameAngle === undefined) {
                    return images || [];
                }
            
                // Find index of image with angle closest to firstFrameAngle
                // Also consider sequence continuity
                let closestIndex = 0;
                let minDiff = 360;
            
                images.forEach((img, index) => {
                    const diff = Math.abs(img.angle - firstFrameAngle);
                    const wrappedDiff = Math.min(diff, 360 - diff); // Handle wraparound
                    // Check if this angle makes sense in sequence
                    let isValidSequence = true;
                    if (index > 0 && index < images.length - 1) {
                        const prevAngle = images[index - 1].angle;
                        const nextAngle = images[index + 1].angle;
                        
                        // If angle is repeated, check if this instance maintains better sequence
                        const sameAngleImages = images.filter(x => x.angle === img.angle);
                        if (sameAngleImages.length > 1) {
                            // Check if this instance maintains better angle progression
                            const angleDiffWithPrev = Math.abs(img.angle - prevAngle);
                            const angleDiffWithNext = Math.abs(nextAngle - img.angle);
                            
                            // If angle differences are too large, this might be a wrongly mapped angle
                            if (angleDiffWithPrev > 30 && angleDiffWithNext > 30) {
                                isValidSequence = false;
                            }
                        }
                    }
                    // console.log(wrappedDiff)
                    if (isValidSequence && wrappedDiff < minDiff) {
                        minDiff = wrappedDiff;
                        closestIndex = index;
                    }
                });
                // console.log(closestIndex , 'closestIndex')
                // Rotate array so closestIndex becomes first element
                return [...images.slice(closestIndex), ...images.slice(0, closestIndex)];
            };
            skuDetails.forEach(image=>{
                if(isJson && image['image_category'] === notShowCategory[effectiveCategory || imageCategoryForJson])return;
                let originalUrl = image['output_image_hres_url']
                let inputResolution =  IsVdp ? '/350x196' : ''
                let inputUrl = `https://media.spyneai.com/unsafe${inputResolution}/filters:format(webp)/` + image['input_image_hres_url']
                // let inputUrl = 'https://media.spyneai.com/unsafe/filters:format(webp)/' + image['input_image_hres_url']

                if(spinMotion){
                    originalUrl = `https://media.spyneai.com/unsafe/350x196/filters:format(webp)/` + originalUrl
                }
                // }else if(window?.innerWidth < 500){
                //     originalUrl = `https://media.spyneai.com/unsafe/${window?.innerWidth}x${Math.round((window.innerWidth/(result?.simplifiedWidth || 16))*(result?.simplifiedHeight || 9))}/filters:format(webp)/` + originalUrl
                // }
                else{
                    originalUrl = 'https://media.spyneai.com/unsafe/filters:format(webp)/' + originalUrl
                }
                // originalUrl = 'https://media.spyneai.com/unsafe/375x211/filters:format(webp)/' + originalUrl
                // mobileUrl = 'https://media.spyneai.com/unsafe/375x211/filters:format(webp)/' + mobileUrl

                // console.log(originalUrl)
                


                allImageInObject[image['image_id']] = { 
                    src: originalUrl,
                    angle: image['AI_ANGLE'],
                    imageId: image['image_id'],
                    imageName: image['image_name']
                }
                images.push({
                    src: (isJson && type == 'input')? inputUrl : originalUrl,
                    angle: image['AI_ANGLE'],
                    imageId: image['image_id'],
                    imageName: image['image_name'],
                    inputUrl: inputUrl
                });

                allImageIDs.push(image['image_id']);
            });
            let angleDirection= this.checkAngleDirection(images);

            
            let first_frame_angle = response?.sequenceData?.first_frame_angle;
            // let rotation = response?.sequenceData?.rotation;
            images = sortImagesByFirstFrameAngle(images, first_frame_angle, rotationDirection);
            this.setState((prev) => ({
                ...prev,
                images: images,
                angleOrderIncreasing: (angleDirection==="Increasing")? true : false,
                noOfImg: images.length || 0,
                imgId: images?.[0]?.imageId || '',
                loading: false,
                lazyLoadImages: true,
                toggleHotspotView: hotspot_val,
                spinMotion: spinMotion ? true : false,
                beforAfterView: beforAfterView ? true : false,
                showConfig: config,
                video360Urls: video_urls,
                showMinWidthWebView: !hotspot_val,
                IsVdp: IsVdp,
                clockwise: Boolean(rotationDirection === 'clockwise') ? true : false,
                skuId: skuId,
                // interiorData: [
                //     {
                //         hotspots: [
                //             {
                //                 hotspot_id: "hotspot1",
                //                 type: "Infotainment Screen",
                //                 reasons: ["Reason1"],
                //                 hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                //                 focus_image_url: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_dddec78c-c8fb-4a76-8b2d-f739b6412aee.png",
                //                 coordinates: { x: 5, y: -5 , z: 0 }
                //             },
                //             {
                //                 hotspot_id: "hotspot2",
                //                 type: "AC Vent",
                //                 reasons: ["Reason2"],
                //                 hotspot_icon: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_85914232-b90c-46a4-bc54-78b99f556705.png",
                //                 focus_image_url: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_36b3362c-ee5f-4fea-a996-3c808b815f87.png",
                //                 coordinates: { x: 40, y: -15 , z: 0 }
                //             },
                //             {
                //                 hotspot_id: "hotspot3",
                //                 type: "Steering wheel",
                //                 reasons: ["Reason4"],
                //                 hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                //                 focus_image_url: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_502fb716-eb27-478b-aeb6-4b410a6adbae.png",
                //                 coordinates: { x: -40, y: -15 , z: 0 }
                //             },
                //             {
                //                 hotspot_id: "hotspot4",
                //                 type: "Gear Lever",
                //                 reasons: ["Reason4"],
                //                 hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
                //                 focus_image_url: "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_f33c2f96-9071-4c54-ba45-fc22c6c6a1de.png",
                //                 coordinates: { x: -5, y: -35 , z: 0 }
                //             }
                //         ]
                //     },
                // ]
            }));
            
            let hotSpotMapURL = `${process.env.REACT_APP_IMAGECREW_NODE_PROJ_PREFIX}/api/v1/hotspot/get-hotspots`;
            let hotspotApiStartTime = new Date();
            let mapping={};
            let isHotspotJson = false;
            
            mapping =  await this.apiCallWrapper(sku_id_download, 'hotspot_list');
            if(!!mapping?.hotspots?.length){
                this.setState({
                    ...this.state,
                    isAnyHotSpotPresent: true
                })
                isHotspotJson=true;
            }
            this.capturejsonResponseEndEvent('hotspot_list', !isHotspotJson);

            let targetAngle = response?.sequenceData?.first_frame_angle;
            let imagesarray = response?.skuDetails;
            if (!Array.isArray(imagesarray) || imagesarray.length === 0 || targetAngle === undefined) {
                return null;
            }
        
            const angles = imagesarray.map(item => item.AI_ANGLE);

            const nearestAngle = angles.reduce((nearest, current) => {
                const currentDiff = Math.abs(current - targetAngle);
                const nearestDiff = Math.abs(nearest - targetAngle);
                return currentDiff < nearestDiff ? current : nearest;
            }, angles[0]);
      
            
        
            // const nearestAngleImage = imagesarray.find(item => item.AI_ANGLE === nearestAngle);

            if(this.state?.isAnyHotSpotPresent) {
                amplitudeEvents({
                    event: 'hotspots_present',
                    customAmplitudeEvents: {
                        iframe_session_id: this.state?.sessionId,
                        sku_id: skuId || this.state?.skuId_for_download,
                        enterprise_id: this.state?.enterpriseid_for_download,
                        skuName: skuName,
                    }
                })
            }
            
            if ((!isV3 && !isJson) || !mapping?.hotspots?.length) {
                this.captureEvent('start_api2');
                amplitudeEvents({
                    event: 'start_api2',
                    customAmplitudeEvents: {
                        iframe_session_id: this.state?.sessionId,
                        sku_id: skuId || this.state?.skuId_for_download,
                        enterprise_id: this.state?.enterpriseid_for_download,
                        skuName: skuName,
                    }
                })

            mapping = await centralAPI.handleGetRequest(hotSpotMapURL, {
            skuId: sku_id_download,
            });
            this.captureEvent('end_api2');
            amplitudeEvents({
                event: 'end_api2',
                customAmplitudeEvents: {
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download,
                    skuName: skuName,
                }
            })

            if(mapping?.hotspots?.length)
                this.setState({
                    ...this.state,
                    isAnyHotSpotPresent: true
                })
                
                    amplitudeEvents({
                        event: 'hotspots_present',
                        customAmplitudeEvents: {
                            iframe_session_id: this.state?.sessionId,
                            sku_id: skuId || this.state?.skuId_for_download,
                            enterprise_id: this.state?.enterpriseid_for_download,
                            skuName: skuName,
                        }
                    })
            }
            const newHotspotData = mapping?.hotspots?.[0]?.interior_tagged_images?.[0]?.hotspots;
            const hotspotImage = mapping?.hotspots?.[0]?.interior_tagged_images?.[0]?.image_id;
            
            if (newHotspotData) {
            this.setState({ hotspotDataInterior: newHotspotData, hotspotImageId: hotspotImage }, () => {
            });
            } else {
            // console.log('No data available for hotspots');
            }

            
            let hotspotApiEndTime = new Date();
            // captureEvents({event: 'hotspot_api_successful', custom: {
            //     'time_taken': hotspotApiEndTime - hotspotApiStartTime
            // }})   
            // let mapping = {
            //     'hotspots' : []
            // }
            if(mapping)
                this.setHotSpotMapObjInStore(mapping, images, type === 'input', interior360Data, underneathData, allImageInObject, hotspot_val, spinMotion,beforAfterView,video_urls,showInteriorFrame);
        } catch (error) {
            console.log(error)
            if(error && error.message && error.message.match(/404/gmi)){
                this.setState({...this.state, loading: false, errStatus: {code: 404, show: true}})
            }
            //console.log(error);
        }


    };

    downloadStatus = async () => {
        
    //     try {
    //         const responseforDownload = await fetch(
    //             `${process.env.REACT_APP_BASEURL_API_SPYNE}/spin/download-enabled?enterprise_id=${this?.state?.enterpriseid_for_download}`
    //         );
    //         if (!responseforDownload.ok) {
    //             throw new Error(`HTTP error! Status: ${responseforDownload.status}`);
    //         }
    //         const isDownloadEnabled = await responseforDownload.json();
    //         const valueForDownload = isDownloadEnabled?.data?.showDownloadButton;
    //         this.setState({ isDownloadEnabled: valueForDownload });
    //     } catch (error) {
    //         console.error('Error fetching download status:', error);
    //    }
    }
    

// Interior Hotspot
async fetchData() {
    try {
        // Using mock data instead of API call
        let mapping = this.state.IntriorMockhotspots;

        if (mapping) {
            this.setHotSpotMapObjInStore(mapping, this.state.images, this.state.type === 'input',this.state.allImageInObject, this.state.hotspot_val, this.state.spinMotion, this.state.beforAfterView,  this.state.showInteriorFrame, this.state.cachePresent);
        }
    } catch (error) {
        console.log(error);
        if (error && error.message && error.message.match(/404/gmi)) {
            this.setState({ ...this.state, loading: false, errStatus: { code: 404, show: true } });
        }
    }
}





handleImagesLoaded = () => {
    try {
        if(!this.state.beforAfterView){
            window.parent.postMessage('allImagesLoaded', '*');
        }
        if (this?.props?.onLoadComplete) {
            this?.props?.onLoadComplete();
        }

       } catch (error) {
   }
}


handleIframeLoad =()=>{
    if(this.state.beforAfterView){
    this.setState({...this.state, automatedImageSpin: true, 
        
});
}
}

handleIframeKeyDown = (event) => {
    const {key, code, keyCode, which, location, ctrlKey, shiftKey, altKey, metaKey, repeat, isComposing, bubbles, cancelable} = event.data;
    const keyboardEvent = new KeyboardEvent('keydown', {
        key,
        code,
        keyCode,
        which,
        location,
        ctrlKey,
        shiftKey,
        altKey,
        metaKey,
        repeat,
        isComposing,
        bubbles,
        cancelable,
      });
      this.handleKeyDown(keyboardEvent, true);
}
handleIframeMouseDown = () => {
    try {
        this.setState({
            ...this.state,
            hotSpotModal: { show: false },
            automatedImageSpin: false,
        });
        this.startEngagement();
    } catch (error) {
        console.error('Error in handleIframeMouseDown:', error);
    }
}

handleIframeMouseUp = (e) => {
    try {
        this.handleMouseUp(e, true);
        this.endEngagement();
    } catch (error) {
        console.error('Error in handleIframeMouseUp:', error);
    }
}

handleIframeMouseMove = (type, x, y, buttons) => {
    try {
        //create new mouse event for iframe
        const event = new MouseEvent(type, {
            clientX: x,
            clientY: y,
            bubbles: true,
            cancelable: true,
            buttons: buttons,
          });
        this.move(event, true);
    } catch (error) {
        
    }
}

handleMessage = (event) => {
    if (event.data === "allImagesLoaded") {
      this.handleIframeLoad();
    } else if(event.data === 'INT' || event.data === 'EXT' || event.data==='UNDERNEATH'){
        this.setState({
            ...this.state, 
            showExtOrInt: event.data,
            isLoaded: false,
            isZoomActive: false,
            currentZoomLevel: 100,
        })
    }
    else {
      const { type, clientX, clientY, height, width, buttons } = event.data;
      const docWidth = document.documentElement.clientWidth;
      const docHeight = document.documentElement.clientHeight;

      //calculate ratios
      const xRatio = docWidth / width;
      const yRatio = docHeight / height;

      const adjustedX = clientX * xRatio;
      const adjustedY = clientY * yRatio;

      switch (type) {
        case "mouse_down":
          this.handleIframeMouseDown();
          break;
        case "mouse_up":
          this.handleIframeMouseUp(event);
          break;
        case "mouse_move":
          this.handleIframeMouseMove(type, adjustedX, adjustedY, buttons);
          break;
        case "key_down":
          this.handleIframeKeyDown(event)
          break;
        default:
          break;
      }
    }
}


    gcd=(a, b)=>{
        try {
            return b === 0 ? a : this.gcd(b, a % b);
        } catch (error) {
            
        }
    }


    
    // Function to calculate aspect ratio and simplify it
     calculateAspectRatio=(width, height)=> {
        try {
            const aspectRatio = width / height;
            const divisor = this.gcd(width, height);
            const simplifiedWidth = width / divisor;
            const simplifiedHeight = height / divisor;
            return {
                aspectRatio: aspectRatio,
                simplifiedWidth: simplifiedWidth,
                simplifiedHeight: simplifiedHeight
            };
        } catch (error) {
            console.log(error)
        }
    }
    
    /**
     * Create hotspot mapping, if isHidden is false
     * and hotSpotMap
     * @param {*} mapping 
     */
    setHotSpotMapObjInStore = (mapping, images, isInputRequested = false, interior360Data, underneathData, allImageInObject, hotspot_val, spinMotion,beforAfterView=false,video_urls,showInteriorFrame) => {
        try {
            //console.log(mapping.hotspots);

            const newHotSpotMapObj = new Map();

            const newModalList = new Map() // {title, desc, url, closeUpURL, hotSpotId}
            /**
             * imageId use as map key, if isHidden is false
             * 
             * if the hotSpots array contains elements, put them under this imageId,
             * else leave as it is
             * 
             */
            let isAnyHotSpotPresent = false;
            // console.log(mapping )
            // debugger
            let isAnyFocusImage = true
            mapping?.hotspots[0]?.exterior_tagged_images?.forEach((element)=>{
                // debugger
                const {image_id, hotspots} = element;
                // console.log("This is element" , element)
                if(!allImageInObject[image_id])return;
                const newImageHotSpots = new Map();
                // console.log("This is mapping of newImage HotSpots" , newImageHotSpots);
                // if(!isHidden){    
                hotspots.forEach(hotSpotObj=>{
                    if(hotSpotObj?.focus_image_url)isAnyFocusImage = true // if any image has zoom in view it would be visible
                    // mark 'isAnyHotSpotPresent' true if hot spot present
                    if(isInputRequested){
                        // here we are setting input coordinates as coordinates to let the flow work same as it works for output
                        hotSpotObj['coordinates'] = {
                            "x": hotSpotObj?.coordinates?.x,
                            "y": hotSpotObj?.coordinates?.y
                        }
                    }

                    isAnyHotSpotPresent = true;
                    newImageHotSpots.set(hotSpotObj.hotspot_id, {...hotSpotObj}); // state to set hotspot
                    // set modal-list, if this id is not there in newModalList
                    if(!newModalList.has(hotSpotObj.hotspot_id)){
                        newModalList.set(hotSpotObj.hotspot_id, {
                            title: hotSpotObj.type || '',
                            desc: hotSpotObj.reasons?.[0] || '',
                            url: hotSpotObj.hotspot_icon || '',
                            closeUpURL: hotSpotObj?.focus_image_url ? `https://media.spyneai.com/unsafe/filters:format(webp)/${hotSpotObj?.focus_image_url}` : '',
                            image_id: image_id
                        });
                    }
                });

                // }
                newHotSpotMapObj.set(image_id, {isHidden : false,imageHotSpots:newImageHotSpots});
            });

            // console.log("This is modelList" , newModalList)
            // console.log("This is before newHotSpotMapObj" , newHotSpotMapObj); 
            // console.log("This is the updated" , updateWholeHotSpotMapObj);  
            this.props.updateWholeHotSpotMapObj(newHotSpotMapObj);

            // console.log("This is  after newHotSpotMapObj" , newHotSpotMapObj);
            this.setState((prevState)=>({
                ...prevState,
                images : [...images],
                noOfImg : images.length || 0,
                modalList: newModalList,
                isAnyHotSpotPresent: isAnyHotSpotPresent? hotspot_val : false,
                interior360Url : interior360Data,
                underneathData: underneathData,
                selectedUrl : interior360Data.length ? {name : interior360Data?.[0]?.image_name, url: interior360Data[0]?.output_image_hres_url?.length ? interior360Data[0]?.output_image_hres_url : interior360Data[0]?.input_image_hres_url,tags : interior360Data?.[0]?.tags , image_id: interior360Data?.[0]?.image_id } : { name : '' ,url : '' , id: '' },
                selectedUnderneathUrl: underneathData.length ? {name : underneathData?.[0]?.image_name,url:underneathData?.[0]?.input_image_hres_url,tags : underneathData?.[0]?.tags} : { name : '' ,url : '' },
                isAnyFocusImage : isAnyFocusImage,
                imgId : prevState?.imgId || '',
                // spinMotion : spinMotion ? true :false,
                beforAfterView : beforAfterView ? true : false,
                video360Urls : video_urls,
                // showMinWidthWebView : !hotspot_val,
                showInteriorFrame : showInteriorFrame
            }))

            // stop loading after 100ms
            // if(!cachePresent){
            //     setTimeout(()=>{
            //         this.setState({...this.state,loading: false,lazyLoadImages: true})
            //     },100)
            setTimeout(()=>{
                this.setState({...this.state,lazyLoadImages: true})
                let imageLoadingTime = new Date().getTime();
                this.updateStats({imageLoadingTime});
                // captureEvents({event: 'first_image_load', custom: {
                //     'time_taken': imageLoadingTime - this.state.timeStart 
                // }})
              
    
            },100)  
        } catch (error) {
            //console.log(error);

            this.setState({
                ...this.state,
                lazyLoadImages: true
            })
        }
    }


    setImageSrcInPlaceOfCloseUpURL = (imageIdToMatch, imagesList)=>{
        let imgSrc = '';
        try {

            for(let i=0;i<imagesList.length;i++){
                const {src,imageId} = imagesList[i];

                if(imageIdToMatch===imageId){
                    imgSrc = src;
                    break;
                }
            }

        } catch (error) {
            //console.log(error)
        }

        return imgSrc;
    }
   
    sendMessageToIframe = (type, event) => {
        try {
        const iframe = document.getElementById("iframe-vdp");
              if (type === "key_down") {
                const eventData = {
                  type: "key_down",
                  key: event.key,
                  code: event.code,
                  keyCode: event.keyCode,
                  which: event.which,
                  location: event.location,
                  ctrlKey: event.ctrlKey,
                  shiftKey: event.shiftKey,
                  altKey: event.altKey,
                  metaKey: event.metaKey,
                  repeat: event.repeat,
                  isComposing: event.isComposing,
                  bubbles: event.bubbles,
                  cancelable: event.cancelable,
                };

                if (iframe && iframe.contentWindow) {
                  iframe.contentWindow.postMessage(eventData, "*");
                } else {
                  window?.parent?.postMessage(eventData, "*");
                }
              } else if(type === 'EXT'){
                if (iframe && iframe.contentWindow) {
                    iframe.contentWindow.postMessage(type, "*");
                } 
              }else {
                const width = window.innerWidth;
                const height = window.innerHeight;
                const { clientX, clientY } = event;
            if (iframe && iframe.contentWindow) {
              const { clientX, clientY } = event;
                  iframe.contentWindow.postMessage(
                    {
                      type,
                      clientX,
                      clientY,
                      width,
                      height,
                    },
                    "*"
                  );
                } else {
                  window?.parent?.postMessage(
                    {
                      type,
                      clientX,
                      clientY,
                      width,
                      height,
                    },
                    "*"
                  );
                }
              }
        } catch (error) {}
    }

    /**
     * Mouse move handle
     * @param {*} e 
     */

  // Tracking and helper variables
 lastMoveTimestamp = null
 accumulatedDuration = 0






 move = (e, calledFromMessage = false) => {
    this.setState((prev)=>(
        {
            ...prev,
            showOverlays: false
        }
    ))
    if (e?.preventDefault) {
        e.preventDefault();
    }
    if(this.state?.zoomLevel > 0.1){
        return;
    }

    const getPageX = (e) => {
        const touchX = e?.changedTouches?.[0]?.pageX;
        const mouseX = e?.pageX;
        if (!touchX && !mouseX) {
            return this.state?.oldx ?? 0; 
        }
        return mouseX || touchX;
    };

    if(this.state?.zoomLevel > 0.1){
        return;
    }

    try {
        // Movement Logic
        let framesAtOnce = 1;
        if (this.state?.noOfImg >= 100) {
            framesAtOnce = 2;
        }
        if (this.state?.noOfImg >= 150) {
            framesAtOnce = 3;
        }
        if (!this.state?.imageNewDimensions?.isNewDimensionsUpdated) {
            this.updateNewImageDimensions();
        }

        const oldIndex = this.state?.imageIndex;
        const perImgSpeed = Math.floor(
            this.state?.imageNewDimensions?.newContainerWidth / (2 * (this.state?.images?.length || 1))
        );

        this.setState((prev) => ({
            ...prev,
            renderHotSpots: !this.state?.renderHotSpots,
            dragSpeed: perImgSpeed,
            automatedImageSpin: false,
        }));

        const pageX = getPageX(e);
        let newIndex = null;

        // Ensure we have valid previous coordinates
        const previousX = this.state?.oldx ?? pageX;
        const dragSpeed = this.state?.dragSpeed ?? perImgSpeed;

        if (pageX <= previousX - dragSpeed) {
            newIndex = (parseInt(this.state?.imageIndex) + 1) % parseInt(this.state?.noOfImg || 1);
        } else if (pageX >= previousX + dragSpeed) {
            newIndex =
                (this.state?.imageIndex - 1 < 0
                    ? parseInt(this.state?.noOfImg || 1) - 1
                    : this.state?.imageIndex - 1);
        }

        const updatedVisitedIndexes = new Set(this.state?.visitedIndexes);
        let updatedVisitCount = this.state?.visitCount || 0;

        if (newIndex !== null && newIndex !== this.state?.imageIndex) {
            if (!updatedVisitedIndexes.has(newIndex)) {
                updatedVisitedIndexes.add(newIndex);
                updatedVisitCount += 1;
            }
            this.setState((prev) => ({
                ...prev,
                imageIndex: newIndex,
                oldx: pageX,
                imgId: this.state?.images?.[newIndex]?.imageId,
                notMoveImageOnHover: true,
                visitedIndexes: updatedVisitedIndexes,
                visitCount: updatedVisitCount,
            }));
            this.updateStats({ oldIndex, newIndex });
        }
        

        if (!calledFromMessage) {
            this.sendMessageToIframe('mouse_move', e);
        }

        // Engagement Tracking
        if (!this?.isProcessingMove) {
            this.isProcessingMove = true;

            const now = Date.now();

            if (!this.isEngagementStarted) {
                const startResult = this.startEngagement();
                if (!startResult?.success) {
                    this.isProcessingMove = false;
                    return;
                }
            }

            // Add minimum time threshold between updates
            if (!this.lastUpdateTime || now - this.lastUpdateTime >= 100) {
                if (!this.lastMoveTimestamp || now - this.lastMoveTimestamp <= 500) {
                    const updateResult = this.updateEngagementDuration();
                    if (updateResult?.success) {
                        this.lastUpdateTime = now;
                    }
                }
            }

            if (this.endEngagementTimeout) {
                clearTimeout(this.endEngagementTimeout);
            }

            this.endEngagementTimeout = setTimeout(() => {
                if (this.isEngagementStarted) {
                    const endResult = this.endEngagement();
                    if (endResult?.success) {
                        localStorage.setItem('totalexteriortime', endResult?.totalEngagementTimeSec);
                    }
                }
            }, 2000);

            this.isProcessingMove = false;

            // Process queued moves
            if (this.moveQueue?.length > 0) {
                setTimeout(() => {
                    const nextMove = this.moveQueue?.shift();
                    if (nextMove) {
                        this.move(nextMove, calledFromMessage);
                    }
                }, 16); // approximately one frame at 60fps
            }
        } else {
            // Initialize moveQueue if it's undefined
            this.moveQueue = this.moveQueue ?? [];
            // Queue the move event if we're currently processing
            this.moveQueue.push(e);
        }
    } catch (error) {
        console.error('Error in move function:', error);
        if (this.endEngagementTimeout) {
            clearTimeout(this.endEngagementTimeout);
            this.endEngagementTimeout = null;
        }
        this.isProcessingMove = false;
    }
};

 startEngagement = () => {
    if (this.isEngagementStarted) {
        return { success: false, message: 'Engagement already started.' };
    }

    this.isEngagementStarted = true;
    this.engagementStartTime = Date.now();
    this.lastMoveTimestamp = this.engagementStartTime;
    this.accumulatedDuration = 0;

    const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
    const overallTime = offsetDate.toISOString().slice(0, -1);
    const { skuName, skuId } = this.getSkuIdAndImageCategory();

    amplitudeEvents({
        event: 'iframe_exterior_engage_started',
        customAmplitudeEvents: {
            iframe_session_id: this.state?.sessionId,
            sku_id: skuId || this.state?.skuId_for_download,
            enterprise_id: this.state?.enterpriseid_for_download,
            skuName: skuName,
            time: new Date(this.engagementStartTime),
            dateTime: overallTime
        }
    });

    return {
        success: true,
        message: 'Engagement started.',
        engagementStartTime: this.engagementStartTime
    };
};

updateEngagementDuration = () => {
    if (!this.isEngagementStarted || !this.engagementStartTime || !this.lastMoveTimestamp) {
        return {
            success: false,
            message: 'Engagement not started or tracking not initialized.',
            engagementDurationSec: 0
        };
    }

    const currentTime = Date.now();
    const incrementalDuration = (currentTime - this.lastMoveTimestamp) / 1000;

    // Strict validation for duration
    if (isNaN(incrementalDuration) || incrementalDuration <= 0 || incrementalDuration > 0.5) {
        // If the duration is too large, only add a small fixed increment
        const fixedIncrement = 0.1; // 100ms fixed increment for continuous movement
        this.accumulatedDuration = Number((this.accumulatedDuration + fixedIncrement).toFixed(2));
        this.lastMoveTimestamp = currentTime;
        
        return {
            success: true,
            message: 'Using fixed increment for continuous movement',
            engagementDurationSec: this.accumulatedDuration
        };
    }

    this.accumulatedDuration = Number((this.accumulatedDuration + incrementalDuration).toFixed(2));
    this.lastMoveTimestamp = currentTime;

    return {
        success: true,
        message: 'Duration updated.',
        engagementDurationSec: this.accumulatedDuration
    };
};

endEngagement = () => {
    if (!this.isEngagementStarted || !this.engagementStartTime) {
        return {
            success: false,
            message: 'No active engagement to end.',
            totalEngagementTimeSec: Number((this.state?.totalEngagementTime || 0).toFixed(2))
        };
    }

    const finalSessionDuration = Number(this.accumulatedDuration.toFixed(2));
    const prevTotal = Number((this.state?.totalEngagementTime || 0).toFixed(2));
    const newTotalEngagementTime = Number((prevTotal + finalSessionDuration).toFixed(2));

    const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
    const overallTime = offsetDate.toISOString().slice(0, -1);
    const { skuName, skuId } = this.getSkuIdAndImageCategory();

    amplitudeEvents({
        event: 'iframe_exterior_engage_end',
        customAmplitudeEvents: {
            iframe_session_id: this.state?.sessionId,
            sku_id: skuId || this.state?.skuId_for_download,
            enterprise_id: this.state?.enterpriseid_for_download,
            skuName: skuName,
            engagementDuration: `${finalSessionDuration} Seconds`,
            totalEngagementTime: `${newTotalEngagementTime} Seconds`,
            overallTime: overallTime
        }
    });

    this.setState({
        totalEngagementTime: newTotalEngagementTime,
        engagementExterior: newTotalEngagementTime
    });

    clearInterval(this.engagementInterval);
    this.engagementInterval = null;
    this.isEngagementStarted = false;
    this.engagementStartTime = null;
    this.lastMoveTimestamp = null;
    this.accumulatedDuration = 0;
    this.moveQueue = [];

    if (this.endEngagementTimeout) {
        clearTimeout(this.endEngagementTimeout);
        this.endEngagementTimeout = null;
    }

    return {
        success: true,
        message: 'Engagement ended.',
        engagementDurationSec: finalSessionDuration,
        totalEngagementTimeSec: newTotalEngagementTime
    };
};

         
    

        /**
         * Mouse grab
         * @param {*} e 
         */
        handleMouseDown = (e)=>{
            // close modal if open
            this.setState({...this.state,hotSpotModal:{ show: false}, automatedImageSpin: false, showOverlays: false});
        e.target.addEventListener('mousemove', this.move);
        this.setState(showHelperText => ({showHelperText: false}));
                this.sendMessageToIframe('mouse_down', e);
        
        }


    /**
     * Mouse ungrab
     * @param {*} e 
     */
    handleMouseUp = (e, calledFromMessage = false)=>{
        e.target.removeEventListener('mousemove', this.move);
        this.setState((prev) => ({
            ...prev,
            showOverlays: true
        }))
        if(!calledFromMessage)
            this.sendMessageToIframe('mouse_up', e);
    }

    handleKeyUp = (e, calledFromMessage = false) => {
        try {
            const engagementKeys = ['ArrowLeft', 'ArrowRight'];
            
            if (engagementKeys.includes(e.code)) {
                // Clear debounce timeout if it exists
                if (this.keyPressTimeout) {
                    clearTimeout(this.keyPressTimeout);
                    this.keyPressTimeout = null;
                }
    
                // Reset key press duration tracking
                this.keyPressStart = null;
    
                // Clear any intervals that might have been set for continuous movement
                if (this.continuousInterval) {
                    clearInterval(this.continuousInterval);
                    this.continuousInterval = null;
                }
    
                this.endEngagement();
    
                // Reset any key-related states
                this.setState(prevState => ({
                    ...prevState,
                    automatedImageSpin: false
                }));
            }
    
            if (!calledFromMessage) {
                this.sendMessageToIframe('key_up', e);
            }
        } catch (err) {
            console.error('Error in handleKeyUp:', err);
        }
    };
    handleKeyDown = (e, calledFromMessage = false) => {
        try {
            const engagementKeys = ['ArrowLeft', 'ArrowRight'];
            
            if (engagementKeys.includes(e.code)) {
                this.startEngagement();
    
                if (!this.state?.imageNewDimensions?.isNewDimensionsUpdated) {
                    this.updateNewImageDimensions();
                }
                if (!this.keyPressStart) {
                    this.keyPressStart = Date.now();
                }
                const pressDuration = Date.now() - this.keyPressStart;
                if (pressDuration > 2000) {
                    const oldIndex = this.state?.imageIndex;
                    const totalImages = parseInt(this.state?.noOfImg || 1);
                    
                    let newIndex = oldIndex;
                    if (e.code === 'ArrowLeft') {
                        newIndex = (oldIndex + 1) % totalImages;
                    } else if (e.code === 'ArrowRight') {
                        newIndex = oldIndex - 1 < 0 ? totalImages - 1 : oldIndex - 1;
                    }
                    if (newIndex !== oldIndex) {
                        this.setState(prevState => ({
                            ...prevState,
                            imageIndex: newIndex,
                            imgId: this.state?.images?.[newIndex]?.imageId,
                            renderHotSpots: !prevState.renderHotSpots,
                            dragSpeed: 3,
                            automatedImageSpin: false
                        }));
                    }
                } 
                // For quick presses or initial press, use debouncing
                else if (!e.repeat || !this.keyPressTimeout) {
                    // Clear any existing timeout
                    if (this.keyPressTimeout) {
                        clearTimeout(this.keyPressTimeout);
                    }
    
                    this.keyPressTimeout = setTimeout(() => {
                        const oldIndex = this.state?.imageIndex;
                        const totalImages = parseInt(this.state?.noOfImg || 1);
                        
                        let newIndex = oldIndex;
                        if (e.code === 'ArrowLeft') {
                            newIndex = (oldIndex + 1) % totalImages;
                        } else if (e.code === 'ArrowRight') {
                            newIndex = oldIndex - 1 < 0 ? totalImages - 1 : oldIndex - 1;
                        }

                        if (newIndex !== oldIndex) {
                            this.setState(prevState => ({
                                ...prevState,
                                imageIndex: newIndex,
                                imgId: this.state?.images?.[newIndex]?.imageId,
                                renderHotSpots: !prevState.renderHotSpots,
                                dragSpeed: 3,
                                automatedImageSpin: false
                            }));
                        }
                        this.keyPressTimeout = null;
                    }, 50);
                }
            } else {
                // Reset key press tracking for non-engagement keys
                this.keyPressStart = null;
                
                const keyCombination = [
                    e.ctrlKey && 'Ctrl',
                    e.shiftKey && 'Shift',
                    e.key.toUpperCase()
                ].filter(Boolean).join(' + ');
    
                window.parent.postMessage({
                    key: e.ctrlKey || e.shiftKey ? keyCombination : e.key,
                    type: 'iframeKeydown'
                }, '*');
            }
    
            if (!calledFromMessage) {
                this.sendMessageToIframe('key_down', e);
            }
        } catch (err) {
            console.error('Error in handleKeyDown:', err);
        }
    };
    
    // Add key up handler to reset the tracking
    handleKeyUp = (e) => {
        if (['ArrowLeft', 'ArrowRight'].includes(e.code)) {
            this.keyPressStart = null;
            if (this.keyPressTimeout) {
                clearTimeout(this.keyPressTimeout);
                this.keyPressTimeout = null;
            }
        }
    };


    /**
     * Get img container dimensions
     */
    updateNewImageDimensions = () => {
        try {
            const containerRef = document.getElementById('imageActualContainer');
            if (!containerRef?.children?.[0]) {
                return;
            }
    
            const containerCoordinates = containerRef.getBoundingClientRect();
            const container = {
                width: containerCoordinates.width || 1,
                height: containerCoordinates.height || 1
            };
    
            const image = {
                width: containerRef.children[0].naturalWidth || 1,
                height: containerRef.children[0].naturalHeight || 1
            };
    
            // Calculate aspect ratios
            const containerRatio = container.width / container.height;
            const imageRatio = image.width / image.height;
            
            let newImage, padding, isPadByWidth;
    
            // If image is wider relative to container
            if (imageRatio > containerRatio) {
                const ratio = container.width / image.width;
                newImage = {
                    width: container.width,
                    height: image.height * ratio
                };
                padding = (container.height - newImage.height) / 2;
                isPadByWidth = false;
            } else {
                const ratio = container.height / image.height;
                newImage = {
                    width: image.width * ratio,
                    height: container.height
                };
                padding = (container.width - newImage.width) / 2;
                isPadByWidth = true;
            }
    
            this.setState(prev => ({
                ...prev,
                imageNewDimensions: {
                    isNewDimensionsUpdated: true,
                    isPadByWidth,
                    newImgWidth: Math.max(newImage.width, 1),
                    newImgHeight: Math.max(newImage.height, 1),
                    newContainerWidth: container.width,
                    newContainerHeight: container.height,
                    paddingHalf: Math.max(padding, 0),
                    notMoveImageOnHover: !isPadByWidth
                }
            }));
        } catch (error) {
            // console.error( error);
        }
    };

    calculateLeftAsPerAspect = (leftPercentageFromImageLeft)=>{
        try {
            // debugger
            if(!leftPercentageFromImageLeft) return 50;
            if(!this.state.imageNewDimensions.isNewDimensionsUpdated)
                this.updateNewImageDimensions();
            let newImageDimensions = this.state.imageNewDimensions;
            if(!newImageDimensions.isPadByWidth) return leftPercentageFromImageLeft;
            // convert to pixels
            const leftPixelFromImgLeft = (leftPercentageFromImageLeft * newImageDimensions.newImgWidth )/100;
            const newLeftFromContainer = leftPixelFromImgLeft + newImageDimensions.paddingHalf;
            const newPercentFromContainer =  ( newLeftFromContainer / newImageDimensions.newContainerWidth )*100;
            return newPercentFromContainer
        } catch (error) {
            // console.log(error);
        }
    }

    calculateTopAsPerAspect = (topPercentageFromImageTop)=>{
        try {
            if(!topPercentageFromImageTop) return 50;

            if(!this.state.imageNewDimensions.isNewDimensionsUpdated)
                this.updateNewImageDimensions();
            let newImageDimensions = this.state.imageNewDimensions;
            if(newImageDimensions.isPadByWidth) return topPercentageFromImageTop;
            // convert to pixels
            const topPixelFromImgTop = (topPercentageFromImageTop * newImageDimensions.newImgHeight )/100;
            const newTopFromContainer = topPixelFromImgTop + newImageDimensions.paddingHalf;
            const newPercentFromContainer =  ( newTopFromContainer / newImageDimensions.newContainerHeight )*100;
            return newPercentFromContainer;
        } catch (error) {
            //console.log(error);   
        }
    }

    handleHotSpotClick = async(e,hotspotObjKey,x = 0,y=0)=>{
        try {
            this.setState({clickHotspot: true})
            // console.log('click hotspot' , this.state.clickHotspot)
            const startTime = Date.now(); 
            if(e)e.preventDefault();
            let modalListKeys = [...this.state.modalList.keys()];
            let index = modalListKeys?.findIndex(hotspotkey => hotspotkey == hotspotObjKey)
            // console.log(index,'hotspotclick')
            this.setState({
                clickHotspot: true,  
                goToSlide: index,
                activeHotspot: hotspotObjKey,
                selectedHotspotObjKey: hotspotObjKey,
                automatedImageSpin: false,
                hotspotActiveValue: { x, y },
                hotspotStartTime: startTime  
            }, () => {
                // console.log(this.state.hotspotStartTime, 'start time');
            });

            this.updateStats({hotspotObjKey});
            let hotspot_name = this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).reasons[0];
            // captureEvents({event: 'hotspot_clicked', custom: {
            //     'hotspot_name': hotspot_name,
            //     'frame_number': [this.state.imageIndex]
            // }})
            const { skuName, skuId } = this.getSkuIdAndImageCategory();
            amplitudeEvents({
                event: 'click_hotspot',
                customAmplitudeEvents: {
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download, 
                    hotspot_name: hotspot_name,
                    skuName: skuName
                }
            })


        } catch (error) {
            // console.log(error)
        }
        this.handleHotspotViewingTime();
        
    }

    showHotspot=()=>{
        try {
            this.setState({
                ...this.state,
                showHotspotWhenImagesLoaded : true
            })
        } catch (error) {
            
        }
    }

    handleHotspotViewingTime = () => {

        if (this.state.clickHotspot && this.state.hotspotStartTime) {
            const endTime = Date.now();
            const timeSpentMs = endTime - this.state.hotspotStartTime;
            const timeSpentSeconds = timeSpentMs / 1000;
            
            // console.log(`Hotspot viewing time: ${timeSpentSeconds} seconds`);
            
            this.setState({
                hotspotViewingTime: timeSpentSeconds,
            });
        }
    }
    
    checkCategory(skuDetails){
        try {
            let contain3dImages = false
            let containOriginalImages = false
            skuDetails.forEach((images)=>{
                if(images['image_category'] === "360_original") containOriginalImages = true
                if(images['image_category'] === "3D_Model") contain3dImages = true
            })
            //Json :for 360 original, get both 3d_model and 360_original , it should render only one image category
            if(contain3dImages && containOriginalImages) return '3D_Model'
            else if(!contain3dImages && containOriginalImages) return '360_original'
            else return '3D_Model'
        } catch (error) {
            return '3D_Model'
        }
    }


    updateStats = ({hotspotObjKey, viewedFullScreenModal, oldIndex, newIndex, fetchImagesAPITime, hotspotMapAPITime, imageLoadingTime}) => {
        try {            
            //rotationStats
            if (oldIndex && newIndex) { //Means the Car is Rotated

                let currentIndex = this.state.imageIndex;

                this.setState(() => ({
                    userStats: {
                        ...this.state.userStats,
                        rotationStats: {
                            ...this.state.userStats.rotationStats,
                            visitedIndexes: {
                                ...this.state.userStats.rotationStats.visitedIndexes,
                                [currentIndex]: true
                            }
                        }
                    }
                }), () => {
                    let noOfFramesVisited = Object.keys(this.state.userStats.rotationStats.visitedIndexes).length;
                    let percentageFramesVisited = (noOfFramesVisited / (this.state.noOfImg - 1)) * 100;
                    this.setState(() => ({
                        userStats: {
                            ...this.state.userStats,
                            rotationStats: {
                                ...this.state.userStats.rotationStats,
                                percentageFramesVisited: percentageFramesVisited
                            }
                        }
                    }), () => {
                        let currentFramesCount = this.state.userStats.rotationStats.mouseFramesCount;

                        this.setState({
                            userStats: {
                                ...this.state.userStats,
                                rotationStats: {
                                    ...this.state.userStats.rotationStats,
                                    mouseFramesCount: currentFramesCount + 1,
                                },
                            }
                        })
                    })
                })
            }
            //hotspotStats
            if (hotspotObjKey) {
            
                let hotspot_name = this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).reasons[0];
                let currentCount = this.state.userStats.hotspotStats.clickCount[hotspot_name] || 0;

                this.setState({
                    userStats: {
                        ...this.state.userStats,
                        hotspotStats: {
                            ...this.state.userStats.hotspotStats,
                            clickCount: {
                                ...this.state.userStats.hotspotStats.clickCount,
                                [hotspot_name]: currentCount + 1
                            }
                        }
                    }
                })
            }

            if(viewedFullScreenModal){
                this.setState({
                    ...this.state, 
                    userStats : {
                        ...this.state.userStats,
                        hotspotStats : {
                            ...this.state.userStats.hotspotStats,
                            viewedFullScreenModal: true
                        }
                    }
                })
            }

            const top10Hotspots = Object.entries(this.state.userStats.hotspotStats.clickCount)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);
            // console.log(top10Hotspots, 'top10hotspots')

            //timeStats
            if (fetchImagesAPITime) {
                this.setState({
                    userStats: {
                        ...this.state.userStats,
                        timeStats: {
                            ...this.state.userStats.timeStats,
                            fetchImagesAPITime: fetchImagesAPITime,
                        }
                    }
                })
            }
            if (hotspotMapAPITime) {
                this.setState({
                    userStats: {
                        ...this.state.userStats,
                        timeStats: {
                            ...this.state.userStats.timeStats,
                            hotspotDataAPITime: hotspotMapAPITime,
                        }
                    }
                })
            }

            if(imageLoadingTime){
                this.setState({
                    userStats: {
                        ...this.state.userStats,
                        timeStats: {
                            ...this.state.userStats.timeStats,
                            firstImageLoadTime: (imageLoadingTime - this.state?.timeStart)/1000,
                        }
                       
                    }
                })
                // console.log('printing lines' , this.state.iframePageViewTime )
            }
            
        }
        catch (error) {
        }
    }


    /**
     * touch grab
     * @param {*} e 
     */
    handleTouchStarted = (e) => {
        
        this.startEngagement();
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.setState({ showHelperText: false, showOverlays: false });
        // console.log(this.state.zoomLevel, 'zoom level')
        if(this.state.zoomLevel > 0.1){
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                isDragging: true,
                startX: e.touches[0].clientX - this.state.currentX,
                startY: e.touches[0].clientY - this.state.currentY,
            });
            window.addEventListener('touchmove', this.handleTouchMoveDrag);
            window.addEventListener('touchend', this.handleTouchMoveEnd);
        } else {
            e.target.addEventListener('touchmove', this.move);
        }
    }

    /**
    * touch ungrab
    * @param {*} e 
    */
    touchEnd = (e) => {
        this.endEngagement();
        this.setState({ showOverlays: true });
        e.target.removeEventListener('touchmove', this.move);

    }

    handleTouchMoveDrag = (event) => {
        if (this.state.zoomLevel > 0.1) {
            const { zoomLevel, startX, startY } = this.state;
            const scale = 1 + zoomLevel;

            const deltaX = event.touches[0].clientX - startX;
            const deltaY = event.touches[0].clientY - startY;

            const container = document.getElementById('imageActualContainer');
            if (!container) return; // Guard against null container

            const img = container.querySelector('img');
            if (!img) return; // Guard against null image

            const rect = container.getBoundingClientRect();

            const imgWidth = img.naturalWidth;
            const imgHeight = img.naturalHeight;

            // Calculate boundaries based on zoom level
            const maxX = Math.max(0, (imgWidth * scale - rect.width) / 2);
            const maxY = Math.max(0, (imgHeight * scale - rect.height) / 2);

            // More restrictive boundaries based on zoom level
            const restrictionFactor = zoomLevel <= 0.9 ? 18 : // Very restricted at low zoom
                zoomLevel <= 1.8 ? 16 : // More restricted at medium zoom
                    14; // Less restricted at high zoom

            // Add minimum movement threshold
            const minMovement = 2; // Minimum pixels to move
            if (Math.abs(deltaX) < minMovement && Math.abs(deltaY) < minMovement) {
                return;
            }

            // Clamp the movement within boundaries with increased restrictions
            const clampedX = Math.max(-maxX / restrictionFactor, Math.min(deltaX, maxX / restrictionFactor));
            const clampedY = Math.max(-maxY / restrictionFactor, Math.min(deltaY, maxY / restrictionFactor));

            // Add movement dampening
            const dampening = 0.8; // Reduce movement speed
            const smoothX = this.state.currentX + (clampedX - this.state.currentX) * dampening;
            const smoothY = this.state.currentY + (clampedY - this.state.currentY) * dampening;

            // Only update if the new position is within bounds
            if (Math.abs(smoothX) <= maxX && Math.abs(smoothY) <= maxY) {
                this.setState({
                    currentX: smoothX,
                    currentY: smoothY
                });
            }
        }
    }




    /**
     * Hotspot toggle
     * @param {*} e 
     */
    handleHotSpotViewToggle = (e)=>{
        try {
            this.setState({
                ...this.state,
                toggleHotspotView : !this.state.toggleHotspotView,
                isZoomActive: false,
                currentZoomLevel: 100,
                activeHotspot : null,
                ToggleInteriorHotspots: !this.state.ToggleInteriorHotspots,
                selectedHotspotObjKey:null
            });
            if(this.state?.toggleHotspotView == false)
                {
                    const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
                    const time = offsetDate?.toISOString()?.slice(0, -1);

                    const { skuName, skuId } = this.getSkuIdAndImageCategory();
                    amplitudeEvents({
                       event: 'hotspot_toggle_on' , 
                        customAmplitudeEvents: {
                            iframe_session_id: this.state?.sessionId,
                            sku_id: skuId || this.state?.skuId_for_download ,
                            enterprise_id: this.state?.enterpriseid_for_download,
                            time: time, 
                            skuName: skuName
                            }
                    })
                }
         
            // if(this.state.toggleHotspotView) captureEvents({event: 'hotspot_toggle_off'})
            //     else captureEvents({event: 'hotspot_toggle_on'})
        } 
        
        
        
        catch (error) {
            //console.log(error)
        }
    }
    
    


    /**
     * @returns left position in pixels
     */
    computeHotSpotTogglePositionLeft = ()=>{
        try {
            // if padding is not by width, return 10px
            const newImageDimensions = this.state.imageNewDimensions;
            if(!newImageDimensions || !newImageDimensions.isPadByWidth) return 10;

            // else add padding + 10 pixels
            return newImageDimensions.paddingHalf + 10;
        } catch (error) {
            // console.log(error);
        }
    }

    /**
     * @returns top position in pixels
     */
    computeHotSpotTogglePositionTop = ()=>{
        try {
            // if padding is by width, return 10px
            const newImageDimensions = this.state.imageNewDimensions;
            if(!newImageDimensions) return 10;

            let hotSpotToggleViewContainer = document.getElementById('hotspot-toggle-view-id');
            let containerHeight = 30;
            if( hotSpotToggleViewContainer){
                containerHeight = hotSpotToggleViewContainer.getBoundingClientRect().height;
            }
            // if padding by width
            if(newImageDimensions.isPadByWidth){
                return newImageDimensions.newImgHeight - containerHeight - 10 ;
            }
            // else add padding + newImageHeight - 10px (from bottom) - height os hotspotcontainer pixels
            return newImageDimensions.paddingHalf + newImageDimensions.newImgHeight - containerHeight - 10 ;
        } catch (error) {
            //console.log(error);
        }
    }

    // componentDidUpdate = ()=>{
    //     if(this.state.showHelperText){
    //         setTimeout(()=>{
    //             this.setState({
    //                 ...this.state,
    //                 showHelperText: false
    //             })
    //         },4000)
    //     }
    // }

    
    handleZoomInLevel = () => {
        this.setState(prevState => {
          const newZoomLevel = Math.min(prevState.zoomLevel + 0.9, 2.7);
          return { 
            zoomLevel: newZoomLevel,
            hasZoomed: true, // Set hasZoomed to true when zooming in
            zoomEnabled: newZoomLevel > 0.9,
            hotSpotModal: { show: false },
            automatedImageSpin: false
          };
        });
      };
      
      handleZoomOutLevel = () => {
        this.setState(prevState => {
          const newZoomLevel = Math.max(prevState.zoomLevel - 0.9, 0.0);
          const transitionRatio = newZoomLevel / prevState.zoomLevel;
          
          return { 
            zoomLevel: Number(newZoomLevel.toFixed(1)),
            currentX: prevState.currentX * transitionRatio,
            currentY: prevState.currentY * transitionRatio,
            hasZoomed: newZoomLevel > 0,
            zoomEnabled: newZoomLevel > 0.9,
            hotSpotModal: { show: newZoomLevel <= 0.9 },
            automatedImageSpin: false

          };
        });
      };
      
      handleWheel = (event) => {
        event.preventDefault();
        const { deltaY } = event;
        const { zoomLevel, currentX, currentY } = this.state;
        const rect = event.target.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;
      
        const delta = -deltaY * 0.002;
        const newZoomLevel = Math.min(Math.max(zoomLevel + delta, 0.1), 2.7);
        const zoomRatio = newZoomLevel / zoomLevel;
      
        let newX = currentX;
        let newY = currentY;
      
        if (newZoomLevel < zoomLevel) {
          // Zooming out - transition towards center proportionally
          const centeringFactor = Math.pow(newZoomLevel / 2.7, 0.5); // Square root for smoother transition
          newX = currentX * centeringFactor;
          newY = currentY * centeringFactor;
        } else {
          // Zooming in - adjust position based on mouse
          newX = (currentX - mouseX) * zoomRatio + mouseX;
          newY = (currentY - mouseY) * zoomRatio + mouseY;
        }
      
        // Bound checking
        if (newZoomLevel < 2.7) {
          const maxX = this.imageWidth * (1 + newZoomLevel) - this.containerWidth;
          const maxY = this.imageHeight * (1 + newZoomLevel) - this.containerHeight;
          newX = Math.min(Math.max(newX, -maxX), maxX);
          newY = Math.min(Math.max(newY, -maxY), maxY);
        }
      
        this.setState({
          zoomLevel: newZoomLevel,
          currentX: newX,
          currentY: newY
        });
      };
      
      handleZoomChange = (event) => {
        const newZoomLevel = Math.min(Math.max(parseFloat(event.target.value), 0.0), 2.7);
        const zoomRatio = newZoomLevel / this.state.zoomLevel;
        
        let newX = this.state.currentX * zoomRatio;
        let newY = this.state.currentY * zoomRatio;
      
        if (newZoomLevel < this.state.zoomLevel) {
          // Apply centering force when zooming out
          const centeringFactor = Math.pow(newZoomLevel / 2.7, 0.5);
          newX *= centeringFactor;
          newY *= centeringFactor;
        }
      
        // Bound checking
        if (newZoomLevel < 2.7) {
          const maxX = this.imageWidth * (1 + newZoomLevel) - this.containerWidth;
          const maxY = this.imageHeight * (1 + newZoomLevel) - this.containerHeight;
          newX = Math.min(Math.max(newX, -maxX), maxX);
          newY = Math.min(Math.max(newY, -maxY), maxY);
        }
      
        this.setState({
          zoomLevel: newZoomLevel,
          currentX: newX,
          currentY: newY,
          hasZoomed: newZoomLevel > 0
        });
      };
      

  

    updateState = (value,key) => {

        // console.log("This is the key" , key , "and this is the value" , value);
        const {skuName , skuId} = this.getSkuIdAndImageCategory();
        if(key === 'INT' ){
            this.setState({
                ...this.state , isIntTabActive: true 

            })
          

        } 
        
        try {
            // if(value === 'EXT') captureEvents({event: 'exterior_360_switcher'})
            if(value === 'INT'){ 
                const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
                const time = offsetDate?.toISOString()?.slice(0, -1);
                amplitudeEvents({
                    event: 'click_interior',
                    customAmplitudeEvents: {
                        iframe_session_id: this.state?.sessionId,
                        sku_id: skuId || this.state?.skuId_for_download ,
                        enterprise_id: this.state?.enterpriseid_for_download,
                        skuName: skuName,
                        time: time
                        }
                })
                
            }
          
            if(key === "exteriorInterior"){
                this.setState({
                    ...this.state, showExtOrInt: value, isLoaded: false,
                    isZoomActive: false,
                    currentZoomLevel: 100,
                })
                //SEND event to parent
                if(value === 'INT' || value==='UNDERNEATH'){
                    //send a postmessage to parent
                    window.parent.postMessage(value, '*');
                }
                if(value === 'EXT'){
                    //send postimessage to iframe
                    this.sendMessageToIframe(value)
                }

                if(value === 'UNDERNEATH'){ 
                    const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
                    const time = offsetDate?.toISOString()?.slice(0, -1);
                    amplitudeEvents({
                        event: 'click_Underneath',
                        customAmplitudeEvents: {
                            iframe_session_id: this.state?.sessionId,
                            sku_id: skuId || this.state?.skuId_for_download ,
                            enterprise_id: this.state?.enterpriseid_for_download,
                            skuName: skuName,
                            time: time
                            }
                    })
                    
                }



            }else{
                this.setState({
                    ...this.state,
                    showhotspotBeforeAfter : value === "hotspot" ? false : true,
                    activeToggleBeforeAfter : value,
                })
            }

            this.resetBtn.current.click();
        } catch (error) {

        }
    }
    handleOnLoad = () => {
        try {
            this.setState({...this.state,isLoaded : true})
        } catch (error) {

        }
    }
    handleMouseOver(index) {
        try {
            this.setState({
                ...this.state,
                showValue : { [index] : true }
            })
        } catch (error) {

        }
    }
    hideLabel() {
        try {
            this.setState({
                ...this.state,
                showValue : {}
            })
        } catch (error) {

        }
    }
    handleAnimationEnd = (index) => {
        // console.log('Animation finished');
        try {
            if (this.elementRef.current) {
                this.elementRef.current.style.transform = 'scale(1)';
            }
        } catch (error) {

        }
        // Your code to run after animation finishes
    };

    increment = async () => {
        try {
            let modalListKeys = [...this.state.modalList.keys()];
            let index = this.state.images.findIndex(obj => obj.imageId == this.state.modalList.get(modalListKeys[this.state.goToSlide + 1 >= modalListKeys.length ? 0 : this.state.goToSlide + 1])?.image_id)
            if(index >= 0){
                await this.setState({
                    ...this.state,
                    hotSpotModal: { show: true },
                    goToSlide: this.state.goToSlide + 1 >= modalListKeys.length ? 0 : this.state.goToSlide + 1,
                    imageIndex: index,
                    selectedImageID: this.state.images[index].imageId,
                    imgId: this.state.images[index].imageId,
                    selectedHotspotObjKey : modalListKeys[this.state.goToSlide + 1 >= modalListKeys.length ? 0 : this.state.goToSlide + 1]

                })

                // }    
            }else{
                if (this.state.goToSlide + 1 === modalListKeys.length) {
                    this.setState({
                        ...this.state,
                        hotSpotModal: { show: true },
                        goToSlide: 0,
                    })
                }else{
                    this.setState({
                        ...this.state,
                        hotSpotModal: { show: true },
                        goToSlide: this.state.goToSlide + 1,
                    })
                }
            }

        } catch (error) {
            // console.log(error)
        }
    }


    decrement = async () => {
        try {
            let modalListKeys = [...this.state.modalList.keys()];
            let index = this.state.images.findIndex(obj => obj.imageId == this.state.modalList.get(modalListKeys[this.state.goToSlide - 1 <  0 ? modalListKeys.length - 1 : this.state.goToSlide - 1])?.image_id)
            if(index >= 0){
                await this.setState({
                    ...this.state,
                    hotSpotModal: { show: true },
                    goToSlide: this.state.goToSlide - 1 <  0 ? modalListKeys.length - 1 : this.state.goToSlide - 1,
                    imageIndex: index,
                    selectedImageID: this.state.images[index].imageId,
                    imgId: this.state.images[index].imageId,
                    selectedHotspotObjKey : modalListKeys[this.state.goToSlide - 1 <  0 ? modalListKeys.length - 1 : this.state.goToSlide - 1]

                })
            }else{
                if (this.state.goToSlide - 1 < 0) {
                    this.setState({
                        ...this.state,
                        hotSpotModal: { show: true },
                        goToSlide: modalListKeys.length,
                    })
                }else{
                    this.setState({
                        ...this.state,
                        hotSpotModal: { show: true },
                        goToSlide: this.state.goToSlide - 1,
                    })
                }
            }

        } catch (error) {
            // console.log(error)
        }
    }

    focusPoint=async(index)=>{
        try {
            if(index == this.state.goToSlide)return;
            let modalListKeys = [...this.state.modalList.keys()];
            let imageIndex = this.state.images.findIndex(obj => obj.imageId == this.state.modalList.get(modalListKeys[index])?.image_id)
            await this.setState((prev)=>({
                ...prev,
                hotSpotModal: { show: true },
                goToSlide: index,
                imageIndex: imageIndex,
                selectedImageID: this.state.images[imageIndex].imageId,
                imgId: this.state.images[imageIndex].imageId,
                selectedHotspotObjKey : modalListKeys[index]

            }))
        } catch (error) {

        }
    }
    

    Capitalize = (sentence) => {
        if (!sentence) return '';
        try {
            let words = sentence?.split(/[\s_]+/);
            let capitalizedWords = words?.map(word => {
                return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
            });
            return capitalizedWords.join(" ");
        } catch (error) {
        }
    }
    ConvertingPercentageToPixel=(valueInPercentage,coordinatesValue = "x",value)=>{
        try {
            if(coordinatesValue === "x"){
                let percentageValue = (valueInPercentage/100) *  this.state.imageNewDimensions.newImgWidth
                return percentageValue + value;
            }else{
                let percentageValue = (valueInPercentage/100) * this.state.imageNewDimensions.newImgHeight
                return percentageValue + value;
            }
        } catch (error) {

        }
    }
    isImageLoaded = () => {
        try {
            if (this.state.imageIndex || this.state.isFunctionLoaded) return;
            const {skuId, skuName, enterpriseId} = this.getSkuIdAndImageCategory();
            
            const istOffset = 5.5 * 60; 
            const startTime = new Date(Date.now() + (istOffset + new Date().getTimezoneOffset()) * 60000);
            

            // Set state with the calculated time
            this.setState({ 
                firstImageLoadedTime: startTime 
            }, () => {
                // console.log('Stored first image loaded time:', this.state.firstImageLoadedTime);
                amplitudeEvents({
                    event: 'first_image_loaded',
                    customAmplitudeEvents: {
                        iframe_session_id: this.state?.sessionId,
                        sku_id: skuId,
                        enterprise_id: enterpriseId,
                        skuName: skuName,
                        time: this.state?.firstImageLoadedTime
                    }
                });
            });
            // Update state for image loading
            this.setState({
                imageLoaded: true,
                isFunctionLoaded: true
            }, () => {
                // Check for automated image spin
                if (!this.calledOnce && this.state.automatedImageSpin) {
                    this.calledOnce = true;
                    this.handleImageSpin();
                }
            });
    
        } catch (error) {
            // Proper error logging
            console.error('Error in isImageLoaded:', error);
        }
    }


    updateIndexValue = () => {
        try {
            const {hotspot_val}= this.getSkuIdAndImageCategory();
            if (  !this.state.imageLoaded || (!this?.state?.automatedImageSpin && !this?.state?.spinMotion) ) return;
            let intervalSpan;
            const frames = this?.state?.frames || 0;
            
            if (frames < 12) {
                intervalSpan = 400;  
            } else {
                intervalSpan = this?.state?.spinMotion ? 75 : 150; 
            }
            this.interval = setInterval(() => {
              
              if (
                this?.state?.automatedImageSpin
              ) {
                let newIndex;
                if (this?.state?.clockwise) {
                  newIndex =
                    (this?.state?.imageIndex + 1) % this?.state?.images.length;
                } else {
                  newIndex =
                    this?.state?.imageIndex === 0
                      ? this?.state?.images?.length - 1
                      : this?.state?.imageIndex - 1;
                }

                let newImageId = this?.state?.images?.[newIndex]?.imageId;
                this.setState((prevState) => ({
                  ...prevState,
                  imageIndex: newIndex,
                }));
                if (this?.state?.imgId !== newImageId) {
                  this.setState({ ...this.state, imgId: newImageId });
                }
              } else {
                //reached end of spin
                this.clearInterval(this.interval);
                this.setState((prevState) => ({
                  ...prevState,
                  toggleHotspotView: !hotspot_val? false : this.state.automatedImageSpin && true,
                  automatedImageSpin: false,
                }));
                if(this.state?.showConfig){
                    this.setState((prevState) => ({
                        ...prevState,
                        toggleHotspotView: hotspot_val? false : this.state.automatedImageSpin && true,
                        automatedImageSpin: false,
                      }));
                }
              }
            }, intervalSpan);
        } catch (error) {
            // console.log(error);
        }
    };

    handleImageSpin = () => {
        this.setState((prevState) => ({
            ...prevState,
            toggleHotspotView: false,
            spinStartTime: Date.now()
            
            
        }));
        try {
            this.updateIndexValue();
            
      } catch (error) {}
      }


    clearInterval = () => {
        try {
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        } catch (error) {

        }
    };

    handleMouseEnter = () => {
        try {
            this.updateIndexValue();
        } catch (error) {

        }
    };

    handleMouseLeave = () => {
        try {
            this.clearInterval();
        } catch (error) {

        }
    };

    changeGridListView=()=>{
        try {
            this.setState({...this.state,showhotspotBeforeAfter:!this.state.showhotspotBeforeAfter})
        } catch (error) {
            
        }
    }
    

    selectedName=(item)=>{
        const {image_name,output_image_hres_url, input_image_hres_url , tags={} , image_id} = item
        try {
            // console.log(name,url)
            // if(image_name === this?.state?.selectedUrl?.url)
            this.setState((prev)=>({...prev,isLoaded:false, selectedUrl:{ name:image_name, url:output_image_hres_url?output_image_hres_url : input_image_hres_url, tags : tags , image_id : image_id},showDropdown:false}))
        } catch (error) {
            // console.log(error)
        }
    }
    selectedUnderneathName=(item)=>{
        const {image_name,input_image_hres_url,tags={}} = item
        try {
            this.setState((prev)=>({...prev,isLoaded:false,
                
                selectedUnderneathUrl:{ name:image_name, url:input_image_hres_url, tags : tags},showDropdown:false}))
        } catch (error) {
            // console.log(error)
        }
    }
    updateDropdown=()=>{
        try {
            this.setState({...this.state,showDropdown: !this.state.showDropdown})
        } catch (error) {
            
        }
    }

    handleCombinedMouseDown = (e) => {
        // Always run base mouse down logic
        this.setState({
          hotSpotModal: { show: false },
          automatedImageSpin: false,
          showHelperText: false
        });
        this.sendMessageToIframe('mouse_down', e);
      
        // Conditionally enable dragging
        if (this.state.zoomLevel > 0.1) {
          e.preventDefault();
          e.stopPropagation();
          this.setState({
            isDragging: true,
            startX: e.clientX - this.state.currentX,
            startY: e.clientY - this.state.currentY,
          });
          window.addEventListener('mousemove', this.handleMouseMoveDrag);
          window.addEventListener('mouseup', this.handleMouseUpDrag);
        } else {
          // Only add regular mouse move listener if not zoomed
          e.target.addEventListener('mousemove', this.move);
        }
      };
      
      
    
      handleMouseMoveDrag = (event) => {
        if (this.state.isDragging && this.state.zoomLevel > 0.1) { // Only allow dragging when zoomed in
            const { zoomLevel, startX, startY } = this.state;
            const scale = 1 + zoomLevel;

            const deltaX = event.clientX - startX; 
            const deltaY = event.clientY - startY;

            const container = document.getElementById('imageActualContainer');
            if (!container) return; // Guard against null container

            const img = container.querySelector('img');
            if (!img) return; // Guard against null image

            const rect = container.getBoundingClientRect();

            const imgWidth = img.naturalWidth;
            const imgHeight = img.naturalHeight;

            // Calculate boundaries based on zoom level
            const maxX = Math.max(0, (imgWidth * scale - rect.width) / 2);
            const maxY = Math.max(0, (imgHeight * scale - rect.height) / 2);

            // More restrictive boundaries based on zoom level
            const restrictionFactor = zoomLevel <= 0.9 ? 6 : // Very restricted at low zoom
                                    zoomLevel <= 1.8 ? 4 : // More restricted at medium zoom
                                    2; // Less restricted at high zoom
            
            // Add minimum movement threshold
            const minMovement = 2; // Minimum pixels to move
            if (Math.abs(deltaX) < minMovement && Math.abs(deltaY) < minMovement) {
                return;
            }

            // Clamp the movement within boundaries with increased restrictions
            const clampedX = Math.max(-maxX/restrictionFactor, Math.min(deltaX, maxX/restrictionFactor));
            const clampedY = Math.max(-maxY/restrictionFactor, Math.min(deltaY, maxY/restrictionFactor));

            // Add movement dampening
            const dampening = 0.8; // Reduce movement speed
            const smoothX = this.state.currentX + (clampedX - this.state.currentX) * dampening;
            const smoothY = this.state.currentY + (clampedY - this.state.currentY) * dampening;

            // Only update if movement is significant and within bounds
            if ((Math.abs(smoothX - this.state.currentX) > 0.5 || 
                 Math.abs(smoothY - this.state.currentY) > 0.5) &&
                Math.abs(smoothX) <= maxX/restrictionFactor &&
                Math.abs(smoothY) <= maxY/restrictionFactor) {
                
                this.setState({
                    currentX: smoothX,
                    currentY: smoothY
                });
            }
        }
    };

    handleTouchMoveEnd = (e) => {
        this.setState({
            showOverlays: true
        });
        window.removeEventListener('touchmove', this.handleTouchMoveDrag);
        window.removeEventListener('touchend', this.handleTouchMoveEnd);
    }
      
      
      
      
      
    
    handleMouseUpDrag = () => {
        this.setState({ isDragging: false, showOverlays: true });
        window.removeEventListener('mousemove', this.handleMouseMoveDrag);
        window.removeEventListener('mouseup', this.handleMouseUpDrag);
      };
    
 


   
    downloadVideo = async (enterprise_id_download, sku_id_download) => {
        const queryParams = new URLSearchParams(window.location.search);
        const sourceValue = queryParams.get('sku_name') ? 'WEBSITE' : 'IFRAME';
        let retryCount = 0;
        const maxRetries = 5; 
        const offsetDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
         const time = offsetDate.toISOString()?.slice(0, -1);
        const makeRequest = async () => {
          try {
            if (retryCount === 0) {
              this.setState({
                isDownloading: true,
                downloadProgress: 0,
              });
            }

            //Amplitude Events
            const { skuName, skuId } = this.getSkuIdAndImageCategory();
            amplitudeEvents({
                event: 'click_download',
                customAmplitudeEvents: {
                    iframe_session_id: this.state?.sessionId,
                    sku_id: skuId || this.state?.skuId_for_download,
                    enterprise_id: this.state?.enterpriseid_for_download, 
                    time: time,
                    skuName: skuName
                }
            })
            
            const res = await centralAPI.handleGetRequest(
              `${process.env.REACT_APP_BASEURL_API_SPYNE}/spin/download-video`,
              {
                enterprise_id: enterprise_id_download,
                sku_id: sku_id_download,
                source: sourceValue,
              }
            );
            const statusCode = parseInt(res.statusCode, 10);
            if (statusCode === 102) {
              toast.info('Download in Progress...', {
                autoClose: 3000,
                position: 'bottom-center'
              });
            } else if (statusCode === 500 || statusCode === 404) {
              toast.info('Video Download Failed...', {
                autoClose: 3000,
                position: 'bottom-center'
              });
            }
      
            const videoUrl = res.data.output_video_url;
            if (!videoUrl) {
              if (retryCount === 0) {
                this.progressInterval = setInterval(() => {
                  this.setState((prevState) => {
                    const newProgress = Math.min(prevState.downloadProgress + 5, 95);
                    if (newProgress >= 95) {
                      clearInterval(this.progressInterval);
                      this.progressInterval = null;
                    }
                    return { downloadProgress: newProgress };
                  });
                }, 1000);
              }
      
              if (retryCount < maxRetries) {
                retryCount++;
                setTimeout(() => {
                  makeRequest();
                }, 20000); 
              } else {
                toast.error('Video URL not available after multiple attempts.');
                if (this.progressInterval) {
                  clearInterval(this.progressInterval);
                  this.progressInterval = null;
                }
                this.setState({ isDownloading: false, downloadProgress: 0 });
              }
              return;
            }
      
            if (this.progressInterval) {
              clearInterval(this.progressInterval);
              this.progressInterval = null;
            }
            this.setState({ downloadProgress: 100 });
      
            const link = document.createElement('a');
            link.href = videoUrl;
            const fileExtension = videoUrl.split('.').pop().toLowerCase();
            link.download = `360_video_${sku_id_download}.${fileExtension}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      
            setTimeout(() => {
              this.setState({ isDownloading: false, downloadProgress: 0 });
            }, 1000);
          } catch (error) {
            console.error('Error downloading video:', error);
            if (this.progressInterval) {
              clearInterval(this.progressInterval);
              this.progressInterval = null;
            }
            this.setState({ downloadProgress: 0, isDownloading: false });
          }
        };
      
        await makeRequest();
      };
      
    handleOnLoad = () => {
        this.setState({ hotspot_val: true });
    };  
    render() {
        const isV3 = checkIsV3();
 
        return (

            (window?.innerWidth > 440 || this.state.showMinWidthWebView || this.state.spinMotion) ?
            <div className={isV3 ? this.state.showExtOrInt==="INT" || (this.state.showExtOrInt !== "INT" && this.state.showExtOrInt !== "EXT")? "w-full h-full": "md:w-full md:h-full md:flex md:justify-center md:items-center" : ''}>
                    
                        <div className={`${this.state.showExtOrInt === "EXT" ? Styles.visible : Styles.hidden} ${Styles['view-360-container']}`}>
                            
                            {
                                (this.state.loading  || this.state.showMinWidthWebView == null || this.state.spinMotion == null ) ? (
                                    (this.state.errStatus.show ? <></> : <Loader />)
                                ) : (
                                    <div className={Styles[this.state.beforAfterView ? "view-360-slider-container" : ""]}>
                                        {this.state.beforAfterView ? <BeforeAfterHeading showhotspotBeforeAfter={this.state.showhotspotBeforeAfter} /> : null}
                                        <div className={Styles[this.state.beforAfterView ? "images-middle-container" : null]}>
                                            <div className={Styles[this.state.beforAfterView ? "list-grid-view" : null]}>
                                            {this.state.beforAfterView ? <BeforeContainer showhotspotBeforeAfter={this.state.showhotspotBeforeAfter} video360Urls={this.state.video360Urls} IsVdp={this.state.IsVdp} skuId={this.state.skuId}   state={this.state} handleMouseDown={this.handleMouseDown} handleMouseUp={this.handleMouseUp} handleTouchStarted={this.handleTouchStarted} touchEnd={this.touchEnd} isImageLoaded={this.isImageLoaded} /> : null}
                                                <div className={Styles[this.state.beforAfterView ? this.state.showhotspotBeforeAfter ? "grid-view" : "max-list-view" : 'full-screen']}>
                                              
                                                    <div className={Styles[this.state.beforAfterView ? "after-image" : ""]} >
                                                        <div >
                                                            <div className={[Styles['front'], 'd-flex', 'justify-content-center', 'align-items-center'].join(' ')}>
                                                                {
                                                                    this.state.images.length && this.state.lazyLoadImages ? (
                                                                        <div className={[Styles[this.state.imageLoaded ? this.state.beforAfterView ? 'slickListContainerbeforeAfter' : isV3 ? 'slickListContaine-v3' : 'slickListContainer' : 'slickListContainerDisable']]} >
                                                                            <div className={[Styles['viewContainer']]} style={{ touchAction: 'none' }}>
                                                                                <div className={`${this.state.isZoomActive ? Styles['hidden'] : this.state.beforAfterView ? Styles['before-after-cont'] : ''} relative `} id="imageActualContainer" 
                                                                                    onMouseEnter={ ()=>{
                                                                                        if(this.state.automatedImageSpin) return;
                                                                                        this.handleMouseEnter(); this.updateNewImageDimensions()
                                                                                    }
                                                                                    }
                                                                                    
                                                                                    onMouseLeave={()=>{
                                                                                        if(this.state.automatedImageSpin) return;
                                                                                        else {
                                                                                            this.handleMouseLeave()};
                                                                                    }}
                                                                                    style={{overflow: "hidden"}}
                                                                                    >

                                                                                        
                                                                                    
                                                                                     {
                                                                                    !this.state.imageLoaded && !this.state.spinMotion && 
                                                                                    <div className="flex justify-center items-center h-full w-full">
                                                                                    <div className='rounded-lg absolute inset-0 h-full w-full object-cover aspect-video shimmer flex items-center justify-center border text-gray-500 text-xl'></div>
                                                                                </div>
                                                                                    } 

                                                                                    
{                               
                                                                                <img
                                                                                ref={this.imageRef}
                                                                                src={this.state.images[this.state.imageIndex]?.src}
                                                                                alt={this.state.images[this.state.imageIndex]?.imageName || ''}
                                                                                id={this.state.images[this.state.imageIndex]?.imageId}
                                                                                onMouseDown={this.handleCombinedMouseDown}
                                                                                onMouseUp={this.handleMouseUp}
                                                                                onTouchStart={this.handleTouchStarted}
                                                                                onTouchEnd={this.touchEnd}
                                                                                draggable="false"
                                                                                onLoad={this.isImageLoaded}
                                                                                fetchpriority="high"
                                                                                onKeyDown={this.handleKeyDown}
                                                                                onKeyUp={this.handleKeyUp}
                                                                                tabIndex="0" 
                                                                                style={{
                                                                                    objectFit: 'contain',
                                                                                    objectPosition: 'center',
                                                                                    maxWidth: '100%',
                                                                                    maxHeight: '100%',
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                    transition: 'transform 0.3s ease-out',
                                                                                    transform: this.state.zoomLevel > 0 
    ? `translate(${this.state.currentX}px, ${this.state.currentY}px) scale(${1 + this.state.zoomLevel})`
    : 'none',
                                                                                    transformOrigin: 'center center'
                                                                                }}
                                                                                 
                                                                                 /> }
                                                                                    <PreFetchImages imagesLoadHandler={this.handleImagesLoaded} images={this.state.images} showHotspot={this.showHotspot} imageLoaded={this.state.imageLoaded} captureEvent={this.captureEvent} skuId = {this.state?.skuId_for_download} skuName={this.state?.skuName} enterpriseId = {this.state?.enterpriseid_for_download} isloadedImages = {this.state.allImagesLoaded} />
                                                                                    {/* {console.log(this.state.images)} */}
                                                                                    {(this.state.loading || this.state.isLoaded || this.state.imageLoaded) ?
                                                                                        <div className=''>
                                                                                            {(!this.state.spinMotion && !this.state.showConfig) && (
                                                                                                <div className={`absolute flex items-center justify-center bottom-[8px] md:bottom-[15px] right-[8px]  gap-3 ${isV3 ? 'sm:right-[45px]' : ' md:right-[45px]'}`}>
                                                                                                    {(this.state?.interior360Url?.length > 0 && this.state.showHotspotWhenImagesLoaded) ?
                                                                                                        <div className={`${this.state.showOverlays ? "" : "hidden"}`}>
                                                                                                            <ToggleButton updateState={this.updateState} value={this.state} />
                                                                                                        </div>
                                                                                                        : null}
                                                                                                    {/* {(this.state.isAnyHotSpotPresent && this.state.imageNewDimensions.isNewDimensionsUpdated && this.state?.interior360Url?.length > 0) ?
                                                                                                    <div className={[Styles["line"]]}></div> : null} */}
                                                                                                    {/* <div className={[Styles['interior-exterior-container-panel'] , 'flex']}>  */}
                                                                                                    {((this.state.isAnyHotSpotPresent && !this.state.spinMotion && !this.state.showhotspotBeforeAfter)) ?
                                                                                                        <div className={`${this.state.showOverlays ? "" : "hidden"}`}>
                                                                                                            <HotspotToggleHandler handleHotSpotViewToggle={this.handleHotSpotViewToggle} toggleHotspotView={this.state.toggleHotspotView} ToggleInteriorHotspots={this.state.ToggleInteriorHotspots} toggleInteriorHotspots={this.toggleInteriorHotspots} zoomLevel={this.state.zoomLevel > 0.1} />
                                                                                                        </div>
                                                                                                        : null}

                                                                                                    {/* {this.state?.showDownloadBtn &&
                                                                                                    this.state?.isDownloadEnabled &&
                                                                                                    !this.state?.showHotspot && ( // New check added here
                                                                                                        <div className={` ${Styles['Download-container-panel']}`}>
                                                                                                            <div>
                                                                                                                <button
                                                                                                                    className="w-max h-10 p-2 flex flex-col justify-center items-center text-white rounded-md transition-colors overflow-hidden relative"
                                                                                                                    onClick={() => this.downloadVideo(this.state?.enterpriseid_for_download, this.state?.skuId_for_download)}
                                                                                                                    disabled={this.state.isDownloading}
                                                                                                                >
                                                                                                                    <div className="flex items-center gap-1 z-10 relative">
                                                                                                                        <img src="https://spyne-static.s3.amazonaws.com/spin/DownloadIcon.svg" alt="Download Icon" />
                                                                                                                        <span
                                                                                                                            className="text-[14px] font-medium tracking-tight"
                                                                                                                            style={{ color: "#FFFFFFCC", letterSpacing: "-0.01122em" }}
                                                                                                                        >
                                                                                                                            {this.state.isDownloading ? 'Downloading...' : 'Download Spin'}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    {this.state.isDownloading && (
                                                                                                                        <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-200">
                                                                                                                            <div
                                                                                                                                className="h-full bg-blue-600 transition-all duration-300 ease-in-out"
                                                                                                                                style={{ width: `${this.state.downloadProgress}%` }}
                                                                                                                            ></div>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </button>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    )} */}

                                                                                                </div>)}



                                                                                        </div>
                                                                                        : null}
                                                                                    {(this.state.IsVdp && this.state.beforAfterView )? this.state.images.map((value)=>{
                                                                                        return <img src={value.inputUrl} alt="inputurl" style={{display : 'none'}} />
                                                                                    }): null}
                                                                                    {this.state.imageLoaded && (!this.state.spinMotion && this.state.spinMotion !== null)? <View360HelperText showHelperText={this.state.showHelperText} /> : null}
                                                                                    {(this.state.activeHotspot && this.state.isAnyFocusImage && !this.state.hasZoomed && this.state.toggleHotspotView) ? (
  <View360Slider
    modalList={this.state.modalList}
    hideModal={() => {
      if (this.state.clickHotspot && this.state.hotspotStartTime) {
        const currentSessionTime = (Date.now() - this.state.hotspotStartTime) / 1000;
        const newTotalTime = this.state.totalTimeSpent + currentSessionTime;
        this.setState({ hotspotTimespent: newTotalTime });

        this.setState({ 
          ...this.state, 
          hotSpotModal: { show: false }, 
          activeHotspot: null, 
          selectedHotspotObjKey: null, 
          clickHotspot: false,
          hotspotStartTime: null,
          totalTimeSpent: newTotalTime 
        });
      } else {
        this.setState({ 
          ...this.state, 
          hotSpotModal: { show: false }, 
          activeHotspot: null, 
          selectedHotspotObjKey: null, 
          clickHotspot: false 
        });
      }
    }}
    goToSlide={this.state.goToSlide}
    increment={this.increment}
    decrement={this.decrement}
    name={this.state.images[this.state.imageIndex]?.imageName}
    viewedFullScreenModal={() => {
      let hotspot_name = this.props.hotSpotMapObj?.get(`${this.state.imgId}`)?.imageHotSpots?.get(`${this.state.selectedHotspotObjKey}`)?.reasons?.[0];
      this.updateStats({ viewedFullScreenModal: true });
    }}
  />
) : null}
                                                                         {!this.state?.showConfig && (
  <div className={`${this.state.showOverlays ? "flex": "hidden"} absolute  bottom-1 mx-4 items-center justify-between ${isV3 ? 'left-[2px] md:left-[1px] md:bottom-[6px]' : 'left-[2px] md:left-[6px]'} `} style={{zIndex: "90"}}>

  {/* Zoom Out Button */}
  <button 
         className="w-[32px] h-[32px] sm:w-[36px] sm:h-[36px] h-sm:w-[38px] h-sm:h-[38px] md:w-[42px] md:h-[42px] p-1.5 sm:p-2 md:p-2 lg:p-3 mx-auto flex items-center justify-center transform transition-all duration-300 hover:scale-110 active:scale-95"

    onClick={this.handleZoomOutLevel}
    onDoubleClick={this.stopPropagation}
    disabled={this.state?.zoomLevel <= 0}
  > 
    <SVG iconName="minus" className={"fill-[#ffffff] h-4 w-4"} />
    
  </button>

  {/* Zoom Level Indicator */}
  <div className='text-white-default mx-2 h-sm:text-[12px] font-medium text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px]'
       style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.50))' }}>
    {Math.round(100 + (this.state.zoomLevel * (100/2.7)))}%
  </div>

  {/* Zoom In Button */}
  <button 
     className="w-[32px] h-[32px] sm:w-[36px] sm:h-[36px] h-sm:w-[38px] h-sm:h-[38px] md:w-[42px] md:h-[42px] p-1.5 sm:p-2 md:p-2 lg:p-3 mx-auto flex items-center justify-center transform transition-all duration-300 hover:scale-110 active:scale-95"

    onClick={this.handleZoomInLevel}
    onDoubleClick={this.stopPropagation}
    disabled={this.state.zoomLevel >= 2.7}
  >
    <SVG iconName="plus" className={"fill-[#ffffff] h-4 w-4"} />
  </button>
 </div>
)}
                                                                                </div>
                                                                               <div className={`${!this.state.isZoomActive ? Styles['hidden'] : Styles['zoomedImageContainer']}`}></div>
                                                                                {

                                                                                    (
                                                                                        this.props.hotSpotMapObj.get(`${this.state.imgId}`) && this.state.showHotspotWhenImagesLoaded && this.state.toggleHotspotView && this.state.imageLoaded && this.state.lazyLoadImages && !this.state.spinMotion && !this.state.showhotspotBeforeAfter) ? (
                                                                                        [...this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.keys()].map((hotspotObjKey, index) => {

                                                                                            if (!this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x || !this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y) return;
                                                                                            return (
                                                                                                <div
                                                                                                    key={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotId}
                                                                                                    className="d-inline-flex align-items-center"

                                                                                                >
                                                                                                    {(this.state.showValue?.[index] && (this.state.selectedHotspotObjKey !== hotspotObjKey || !this.state.isAnyFocusImage)) ? <div className={[Styles["DentIcon__container"]]}
                                                                                                        style={{
                                                                                                            left: `calc(${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%)`,
                                                                                                            top: `calc(${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}% )`,
                                                                                                            transform: `translate(-17%, -21%)`
                                                                                                        }}
                                                                                                    >
                                                                                                        <svg
                                                                                                        className={[Styles["DentIcon__wrap"]]} viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path
                                                                                                                d="M42.3541 11.0119C37.7055 6.36331 31.4007 3.75177 24.8266 3.75177C18.2526 3.75177 11.9477 6.36331 7.29917 11.0119C2.6506 15.6604 0.0390625 21.9653 0.0390625 28.5393C0.0390625 35.1134 2.6506 41.4182 7.29917 46.0668C11.9477 50.7154 18.2526 53.3269 24.8266 53.3269C31.4007 53.3269 37.7055 50.7154 42.3541 46.0668C47.0027 41.4182 49.6142 35.1134 49.6142 28.5393C49.6142 21.9653 47.0027 15.6604 42.3541 11.0119L54.0391 -0.673096"
                                                                                                                fill="transparent" stroke="#fff" stroke-width="1.5"
                                                                                                                onAnimationEnd={() => this.handleAnimationEnd(index)}

                                                                                                            ></path>
                                                                                                        </svg>

                                                                                                        <label ref={this.elementRef} className={[Styles["DentIcon__label"], Styles["DentIcon__open"]].join(' ')}>{this.Capitalize(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`)?.reasons?.[0] ?? 'info')}</label>
                                                                                                    </div> : null}

                                                                                                    {(this.state.selectedHotspotObjKey === hotspotObjKey && this.state.isAnyFocusImage) ? <>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className={[Styles['active-line-hotspot']]} width="100%" height="100%">
                                                                                                            <line fill="none" stroke="#fff" x1={`calc(${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}% + 1.2%)`} x2={this?.state?.imageNewDimensions?.newImgWidth ? this.state.imageNewDimensions.newImgWidth - 100 : window?.innerWidth > 1200 ? window?.innerWidth - 200 : window?.innerWidth - 100} y1={`calc(${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}% - 0.8%)`} y2="83" style={{ zIndex: 10 }} filter="url(#filter0_d_21310_177698)"></line>
                                                                                                        </svg>
                                                                                                        <svg style={{
                                                                                                            left: `calc(${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%)`,
                                                                                                            top: `calc(${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}% )`,
                                                                                                            transform: `translate(-17.7%, -18.5%)`
                                                                                                        }} className={[Styles['active-hotspot']]}>
                                                                                                            <circle cx="50%" cy="50%" r="16.5" fill-opacity="0" stroke="#fff" style={{ zIndex: 10 }} className={[Styles["circle"]]} stroke-width="1"></circle>
                                                                                                        </svg>
                                                                                                    </> : null}
                                                                                                    <img
                                                                                                        src={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotspot_icon}
                                                                                                        className={[Styles['slickListContainerItemcentered']]}
                                                                                                        onClick={(e) => this.handleHotSpotClick(e, hotspotObjKey)}
                                                                                                        onMouseEnter={() => {
                                                                                                            this.handleMouseOver(index)
                                                                                                            // captureEvents({event: 'hover_on_hotspot', custom: {
                                                                                                            //     'hotspot_name': this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).reasons[0]
                                                                                                            // }})
                                                                                                        }}
                                                                                                        onMouseLeave={() => this.hideLabel()}
                                                                                                        id={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotId}
                                                                                                        alt={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotTitle}
                                                                                                        style={{
                                                                                                            left: `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`,
                                                                                                            top: `${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`,
                                                                                                        }}
                                                                                                        draggable="false"
                                                                                                    />
                                                                                                    <span
                                                                                                        className={[Styles[this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).type == "hotspot" ? 'slickListContainerItemcentered-span-hotspot' : 'slickListContainerItemcentered-span']]}
                                                                                                        style={{
                                                                                                            left: `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`,
                                                                                                            top: `${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`,
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        })

                                                                                    ) : (null)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    ) : (null)
                                                                }
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                   
                                                        
                                                </div>  
                                                
                                            </div>
                                        </div>

                                    </div> 
                                )
                            }
                            {this.state.errStatus.show ?<FallbackUrl errStatus= {this.state.errStatus.code} isRequestFor3DModel ={this.state.isRequestFor3DModel} /> : null}
                        </div>

                        
                        {/* //interior */}
                        { this.state.showExtOrInt==="INT" &&    
                        <div className={`${isV3 ? `w-full h-full ${[Styles["interior-container"], 'container-fluid'].join(' ')}}` : [Styles["interior-container"], 'container-fluid'].join(' ')}`}>
                            {!this.state.isLoaded ? <Loader/> : null }
                            <div className={isV3 ? 'w-full h-full' : [Styles["interior-a-frame"]]}>
                                <div className={Styles['interior-iframe']}>
                                    {this?.state?.interior360Url.length > 1 ? <InteriorFrameDropdown state={this.state} selectedName={this.selectedName} updateInteriorDropdown={this.updateDropdown} /> : null}
                                </div>
                                {/* {console.log('interiorData:', this.state.interiorData)} */}
                                {/* {console.log('Hotspots being passed:', this.state.interiorData)} */}
                                <InteriorIframe handleOnLoad={this.handleOnLoad} state={this.state} isMobileView={false} hotspots={this.state.interiorData} ToggleInteriorHotspots = {this.state.ToggleInteriorHotspots} hotspotDataInterior = {this.state.hotspotDataInterior} hotspotImageId = {this.state.hotspotImageId}  interiorTimeSpent = {this.handleInteriorTimeSpent}/> 
</div>
                            {(!this.state.loading || this.state.isLoaded || this.state.imageLoaded) ?
                                // <div className={[Styles[false ? 'interior-exterior-container-sml-screen' : 'interior-exterior-container']]}>
                                <div>
                                    <div className={`absolute flex items-center justify-center bottom-[8px] md:bottom-[15px] right-[8px] md:right-[45px] gap-3 ${isV3 ? 'bottom-[5px] md:right-[35px]  ' : ''}`}>
                                                            
                                            {this.state?.interior360Url?.length > 0 ? <ToggleButton updateState={this.updateState} value={this.state} /> : null}

                                            {((this.state.isAnyHotSpotPresent && !this.state.spinMotion && !this.state.showhotspotBeforeAfter)) ? <HotspotToggleHandler handleHotSpotViewToggle={this.handleHotSpotViewToggle} toggleHotspotView={this.state.toggleHotspotView} toggleInteriorHotspots={this.toggleInteriorHotspots} 
    ToggleInteriorHotspots={this.state.ToggleInteriorHotspots}  /> : null}
                                        
                                    </div>
                                    
                                </div> : null}

                        </div>
                    }

                    {/* underneathData */}
                     {(this.state.showExtOrInt !== "INT" && this.state.showExtOrInt!=="EXT") &&      
                     
                     <div className={`${isV3 ? `w-full h-full ${[Styles["interior-container"], 'container-fluid'].join(' ')}` : [Styles["interior-container"], 'container-fluid'].join(' ')}`}>
                        
                        <div className={isV3 ? 'w-full h-full' : [Styles["interior-a-frame"]]}>                            <div className={Styles['interior-iframe']}>
                                {this?.state?.underneathData?.length > 1 ? <UnderneathFrameDropdown state={this.state} selectedName={this.selectedUnderneathName} updateDropdown={this.updateDropdown} /> : null}
                            </div>
                            <UnderneathIframe state={this.state} handleOnLoad={this.handleOnLoad} isMobileView={false} timeSpent = {this.handleUnderneathTimeSpent} />
                        </div>
                        {(!this.state.loading || this.state.isLoaded || this.state.imageLoaded) ?
                        <div>
                                    <div className={`absolute flex items-center justify-center bottom-[8px] md:bottom-[15px] right-[8px] md:right-[45px] gap-3 ${isV3 ? 'bottom-[5px] md:right-[35px]  ' : ''}`}>
                                    {/* {(this.state.isAnyHotSpotPresent && this.state.imageNewDimensions.isNewDimensionsUpdated && this.state?.underneathData?.length > 0) ? <div className={[Styles["line"]]}></div> : null} */}
                                    {this.state?.underneathData?.length > 0 ? <ToggleButton updateState={this.updateState} value={this.state} /> : null}
                                    {((this.state.isAnyHotSpotPresent && !this.state.spinMotion && !this.state.showhotspotBeforeAfter)) ? <HotspotToggleHandler handleHotSpotViewToggle={this.handleHotSpotViewToggle} toggleHotspotView={this.state.toggleHotspotView} /> : null}

                                </div>
                            </div> : null}
                       </div>
                     }
                    <div>
                        {(this.state.beforAfterView && window?.innerWidth > 440) ? <ToggleButton updateState={this.updateState} value={this.state} beforeAfterView={true} /> : null}
                    </div>
                </div>
                :
                <div className={[Styles['view-360-mobile']]} style={{ height: `${window?.innerHeight ? `${window?.innerHeight}px` : '100%'}` }}>
                    {this.state.showExtOrInt === "EXT" ?
                        <div className={Styles['view-360-container-mobile']}>
                            {
                                this.state.loading ? (
                                    <Loader /> 
                                    // <></>
                                ) : (
                                    <>
                                        {(!this.state.loading || this.state.isLoaded) && (!this.state.activeHotspot && this.state.isAnyFocusImage) ?
                                            <div className={[Styles['interior-exterior-container-mobile-view']]}>
                                                {(this.state.isAnyHotSpotPresent) ?
                                                    <MobileToggleHandler handleHotSpotViewToggle={this.handleHotSpotViewToggle} toggleHotspotView={this.state.toggleHotspotView}  /> : null}
                                            </div>
                                            : null}
                                        <div style={((this.state.activeHotspot && this.state.isAnyFocusImage) || (!this.state.toggleHotspotView && this.state.beforAfterView) || (this.state.beforAfterView && !this.state.isAnyHotSpotPresent)) ? { display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-between' } : { display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }} >
                                            {(this.state.beforAfterView && (!this.state.toggleHotspotView || !this.state.isAnyHotSpotPresent)) ?
                                                <MobileViewHeadingContainer showhotspotBeforeAfter={this.state.showhotspotBeforeAfter} video360Urls={this.state.video360Urls} IsVdp={this.state.IsVdp} skuId={this.state.skuId}   state={this.state} handleMouseDown={this.handleMouseDown} handleMouseUp={this.handleMouseUp} handleTouchStarted={this.handleTouchStarted} touchEnd={this.touchEnd} isImageLoaded={this.isImageLoaded} />
                                                : null}
                                            {(this.state.activeHotspot && this.state.isAnyFocusImage) ?
                                                <MobileViewSlider modalList={this.state.modalList}
                                                    hideModal={() => this.setState({ ...this.state, activeHotspot: null, selectedHotspotObjKey: null })}
                                                    goToSlide={this.state.goToSlide}
                                                    updateFocusPoint={this.focusPoint}
                                                    increment={this.increment}
                                                    decrement={this.decrement}
                                                    name={this.state.images[this.state.imageIndex]?.imageName} /> 
                                            : null}
                                            <div className={[Styles['front'], 'd-flex', 'justify-content-center', 'align-items-center', ''].join(' ')}>
                                                {this.state.beforAfterView && (!this.state.toggleHotspotView || !this.state.isAnyHotSpotPresent) ?<MobileViewAfterContainer/> : null}
                                                {
                                                    this.state.images.length && this.state.lazyLoadImages ? (
                                                        <div className={[Styles[this.state.imageLoaded ? 'slickListContainerMobileView' : 'slickListContainerMobileDisable']]} >
                                                            <div className={[Styles['viewContainer']]}>
                                                                <div className={`${this.state.isZoomActive ? Styles['hidden'] : ''}`} id="imageActualContainer">
                                                                <img
                                                                ref={this.imageRef}
                                                                src={this.state.images[this.state.imageIndex]?.src}
                                                                alt={this.state.images[this.state.imageIndex]?.imageName || ''}
                                                                id={this.state.images[this.state.imageIndex]?.imageId}
                                                                onMouseDown={this.handleMouseDown}
                                                                onMouseUp={this.handleMouseUp}
                                                                onTouchStart={this.handleTouchStarted}
                                                                onTouchEnd={this.touchEnd}
                                                                draggable="false"
                                                                onLoad={this.isImageLoaded}
                                                                fetchpriority="high"
                                                                onKeyDown={this.handleKeyDown}
                                                                onKeyUp={this.handleKeyUp}
                                                                tabIndex="0" 
                                                                style={{ outline: 'none' }} 
                                                                />

                                                                    <PreFetchImages imagesLoadHandler={this.handleImagesLoaded} images={this.state.images} showHotspot={this.showHotspot} imageLoaded={this.state.imageLoaded} captureEvent={this.captureEvent} skuId = {this.state?.skuId_for_download} skuName={this.state?.skuName}  enterpriseId = {this.state?.enterpriseid_for_download}/>
                                                                   

                                                                </div>

                                                                {
                                                                    (this.props.hotSpotMapObj.get(`${this.state.imgId}`) && this.state.toggleHotspotView && this.state.imageLoaded) ? (
                                                                        [...this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.keys()].map((hotspotObjKey, index) => {
                                                                            if (!this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x || !this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y) return;
                                                                            return (
                                                                                <div
                                                                                    key={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotId}
                                                                                    className="d-inline-flex align-items-center"

                                                                                >

                                                                                    {(this.state.selectedHotspotObjKey === hotspotObjKey && this.state.isAnyFocusImage) ? <>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className={[Styles['active-line-hotspot']]} width="100%" height="100%" role='presentation' aria-hidden="true" style={{ position: 'absolute', height: '700px', top: `-${(window?.innerHeight || 900) / 2.35}px` }}>
                                                                                            <defs>
                                                                                                <filter id="drop-shadow" x="-20%" y="-20%" width="140%" height="140%">
                                                                                                    <feDropShadow dx="0" dy="0" stdDeviation="6" flood-color="rgba(0, 0, 0, 0.32)" />
                                                                                                </filter>
                                                                                            </defs>
                                                                                            <line fill="none" stroke="#fff" x1="187.5" x2={this.ConvertingPercentageToPixel(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x, "x", 10)} y1="0" y2={this.ConvertingPercentageToPixel(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y, "y", (window?.innerHeight || 900) / 2.36)} style={{ filter: 'url(#drop-shadow)' }} stroke-width="1.3"></line>
                                                                                        </svg>
                                                                                        <svg style={{
                                                                                            left: `calc(${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%)`,
                                                                                            top: `calc(${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}% )`,
                                                                                            transform: `translate(-17.7%, -18.5%)`

                                                                                        }} className={[Styles['active-hotspot']]}>
                                                                                            <circle cx="50%" cy="50%" r="16.5" fill-opacity="0" stroke="#fff" style={{ zIndex: 10 }} className={[Styles["circle"]]} stroke-width="1.3"></circle>
                                                                                        </svg>
                                                                                    </> : null}
                                                                                    <img
                                                                                        src={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`)?.hotspot_icon}
                                                                                        className={[Styles['slickListContainerItemcentered']]}
                                                                                        onClick={(e) => this.handleHotSpotClick(e, hotspotObjKey, `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`, `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`)}
                                                                                        onMouseEnter={() => this.handleMouseOver(index)}
                                                                                        onMouseLeave={() => this.hideLabel()}
                                                                                        id={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotId}
                                                                                        alt={this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotTitle}
                                                                                        style={{
                                                                                            left: `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`,
                                                                                            top: `${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`,
                                                                                        }}
                                                                                        draggable="false"
                                                                                    />
                                                                                    <span
                                                                                        className={[Styles[this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).hotSpotTitle == "hotspot" ? 'slickListContainerItemcentered-span-hotspot' : 'slickListContainerItemcentered-span']]}
                                                                                        style={{
                                                                                            left: `${this.calculateLeftAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.x)}%`,
                                                                                            top: `${this.calculateTopAsPerAspect(this.props.hotSpotMapObj.get(`${this.state.imgId}`).imageHotSpots.get(`${hotspotObjKey}`).coordinates.y)}%`,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        })

                                                                    ) : (null)
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : (null)
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            
                            {(!this.state.spinMotion && this.state.spinMotion !== null) ? <View360HelperText showHelperText={this.state.showHelperText} /> :null}
                            {this.state.errStatus.show ? <FallbackUrl errStatus={this.state.errStatus.code} isRequestFor3DModel={this.state.isRequestFor3DModel} /> : null}
                        </div>
                        : this.state.showExtOrInt === "INT"?
                        <div className={[Styles["interior-container"], 'container-fluid'].join(' ')}>
                            {!this.state.isLoaded &&  this.state.isIntTabActive?   <Loader />  : <></>}
                            <div className={[Styles["interior-a-frame"]]}>
                                <div className={Styles['interior-iframe']}>
                                    {this?.state?.interior360Url.length > 1 ? <InteriorFrameDropdown state={this.state} selectedName={this.selectedName} updateInteriorDropdown={this.updateDropdown} ToggleInteriorHotspots = {this.stateToggleInteriorHotspots} /> : null}
                                </div>
                                <InteriorIframe state={this.state} handleOnLoad={this.handleOnLoad} isMobileView={true} hotspotDataInterior = {this.state.hotspotDataInterior} hotspotImageId = {this.state.hotspotImageId} interiorTimeSpent = {this.handleInteriorTimeSpent}/>
                            </div>
                        </div>: 
                        <div className={[Styles["interior-container"], 'container-fluid'].join(' ')}>
                      
                        <div className={[Styles["interior-a-frame"]]}>
                            <div className={Styles['interior-iframe']}>
                                {this?.state?.underneathData.length > 1 ? <UnderneathFrameDropdown state={this.state} selectedName={this.selectedUnderneathName} updateDropdown={this.updateDropdown} /> : null}

                            </div>

                            <UnderneathIframe state={this.state} handleOnLoad={this.handleOnLoad} isMobileView={true} timeSpent = {this.handleUnderneathTimeSpent} />

                        </div>
                    </div>
                    }
                    {(this.state?.interior360Url?.length > 0 && !(this.state.activeHotspot && this.state.isAnyFocusImage) && this.state.showInteriorFrame)?<ToggleButton updateState={this.updateState} value={this.state} mobileView={true} />: null}
                </div>
        )
    }

    // Add this method to the View360 class if it doesn't exist
    stopPropagation = (e) => {
      e.stopPropagation();
    };
}


const mapStateToProps = (state, ownProps)=>{
    //console.log(state.view360Reducer)
    return{
        hotSpotMapObj : state.view360Reducer.hotSpotMapObj,
        preloadedData : ownProps.preloadedData
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        updateWholeHotSpotMapObj: (hotSpotMapObj) => dispatch(updateWholeHotSpotMapObj(hotSpotMapObj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(View360);