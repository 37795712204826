import React from 'react';
import PlusIcon from '../common/svg/Icons/PlusIcon';
import MinusIcon from '../common/svg/Icons/MinusIcon';
import SVG from '../common/svg/SVG';

const ZoomControls = ({ zoomLevel, onZoomIn, onZoomOut }) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div 
      className="absolute left-0 bottom-1 mx-4 flex items-center justify-center" 
      style={{zIndex: "9999"}}
      onDoubleClick={stopPropagation}
    >
      <button 
        className="w-[32px] h-[32px] sm:w-[36px] sm:h-[36px] h-sm:w-[38px] h-sm:h-[38px] md:w-[42px] md:h-[42px] p-1.5 sm:p-2 md:p-2 lg:p-3 mx-auto flex items-center justify-center transform transition-all duration-300 hover:scale-110 active:scale-95"
        onClick={onZoomOut}
        onDoubleClick={stopPropagation}
        disabled={zoomLevel <= 0}
      >
        <SVG iconName="minus" className={"fill-[#ffffff] h-4 w-4"} />

      </button>

      <div 
        className='text-white-default mx-2 h-sm:text-[12px] font-medium text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px]'
        onDoubleClick={stopPropagation}
        style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.50))' }}
      >
        {Math.round(100 + (zoomLevel * (100/2.7)))}%
      </div>

      <button 
        className="w-[32px] h-[32px] sm:w-[36px] sm:h-[36px] h-sm:w-[38px] h-sm:h-[38px] md:w-[42px] md:h-[42px] p-1.5 sm:p-2 md:p-2 lg:p-3 mx-auto flex items-center justify-center transform transition-all duration-300 hover:scale-110 active:scale-95"
        onClick={onZoomIn}
        onDoubleClick={stopPropagation}
        disabled={zoomLevel >= 2.7}
      >
        <SVG iconName="plus" className={"fill-[#ffffff] h-4 w-4"} />
    </button>
    </div>
  );
};

export default ZoomControls;