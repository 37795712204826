export const TAB_ENUM = {
  THREE_SIXTY_VIEW: "360",
  VIDEO_VIEW: "video",
  GALLERY_VIEW: "image",
};

export const ASSETS = {
  TAB: {
    view360:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/360.svg",
    vie360_active:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/360Active.svg",
    video:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/video.svg",
    video_active:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/videoActive.svg",
    gallery:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/gallery.svg",
    gallery_active:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/galleryActive.svg",
  },
  GALLERY: {
    zoomIn:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/plusRounded.svg",
    zoomOut:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/minusRounded.svg",
    forward_arrow:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/leftArrow.svg",
    bacward_arrow:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/rightArrow.svg",
  },
  video: {
    video_play:
      "https://spyne-static.s3.amazonaws.com/console/play_white_icon.svg",
    video_pause:
      "https://spyne-static.s3.amazonaws.com/console/pause_white_icon.svg",
    seek_forward:
      "https://spyne-static.s3.amazonaws.com/console/seeker_15s_forward.svg",
    seek_backward:
      "https://spyne-static.s3.amazonaws.com/console/seeker_15s_backward.svg",
    muted:
      "https://spyne-static.s3.amazonaws.com/console/sound_off.svg",
    unMuted:
      "https://spyne-static.s3.amazonaws.com/console/sound_on.svg",
  },
  other: {
    noSkuDone:
      "https://spyne-prod-video.s3.amazonaws.com/static/website/2024-08-20/sku_unfinished.png",
  },
};

export const TAB_OPTIONS = [
  {
    key: TAB_ENUM.THREE_SIXTY_VIEW,
    label: "360 View",
    icon: ASSETS.TAB.view360,
    activeIcon: ASSETS.TAB.vie360_active,
    showItemsLength: false,
  },
  {
    key: TAB_ENUM.VIDEO_VIEW,
    label: "Feature Video",
    icon: ASSETS.TAB.video,
    activeIcon: ASSETS.TAB.video_active,
    showItemsLength: false,
  },
  {
    key: TAB_ENUM.GALLERY_VIEW,
    label: "Gallery",
    icon: ASSETS.TAB.gallery,
    activeIcon: ASSETS.TAB.gallery_active,
    showItemsLength: false,
    totalItem: "",
  },
];

export const iframeTabs = [
  {
    label: "Exterior",
    isDisabled: false,
  },
  {
    label: "Interior",
    isDisabled: false,
  },
  {
    label: "Additional",
    isDisabled: false,
  },
];

export const IFRAME_MODULE_CONFIG = {
  paramsKey: "version",
  paramsValue: "v2",
  isSkuDone: "done",
};

export const VIEW_360_DATA = {
  GENRIC_HOTSPOT_DATA: [
    {
      hotspot_id: "hotspot1",
      type: "Infotainment Screen",
      reasons: ["Reason1"],
      hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
      focus_image_url:
        "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_dddec78c-c8fb-4a76-8b2d-f739b6412aee.png",
      coordinates: { x: 5, y: -5, z: 0 },
    },
    {
      hotspot_id: "hotspot2",
      type: "AC Vent",
      reasons: ["Reason2"],
      hotspot_icon:
        "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_85914232-b90c-46a4-bc54-78b99f556705.png",
      focus_image_url:
        "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_36b3362c-ee5f-4fea-a996-3c808b815f87.png",
      coordinates: { x: 40, y: -15, z: 0 },
    },
    {
      hotspot_id: "hotspot3",
      type: "Steering wheel",
      reasons: ["Reason4"],
      hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
      focus_image_url:
        "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_502fb716-eb27-478b-aeb6-4b410a6adbae.png",
      coordinates: { x: -40, y: -15, z: 0 },
    },
    {
      hotspot_id: "hotspot4",
      type: "Gear Lever",
      reasons: ["Reason4"],
      hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
      focus_image_url:
        "https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne.s3.amazonaws.com/AI/app/edited/car_hotspot_tracking_f33c2f96-9071-4c54-ba45-fc22c6c6a1de.png",
      coordinates: { x: -5, y: -35, z: 0 },
    },
  ],
  MOCK_INTERIOR_HOTSPOT: [
    {
      hotspot_id: "6d5da2028f844ecc996704fe32ab8659",
      type: "tag1",
      reasons: ["Reason1"],
      hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
      focus_image_url:
        "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
      coordinates: { x: 0, y: 0, z: 0 },
    },
    {
      hotspot_id: "8da5b950a2274e769aa7696c346ca436",
      type: "tag2",
      reasons: ["Reason2"],
      hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
      focus_image_url:
        "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
      coordinates: { x: 2, y: 2, z: 2 },
    },
    {
      hotspot_id: "8da5b950a2274e769aa7696c346ca436",
      type: "tag4",
      reasons: ["Reason4"],
      hotspot_icon: "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
      focus_image_url:
        "https://spyne-static.s3.amazonaws.com/icons/feature.svg",
      coordinates: { x: -4, y: 19, z: 3 },
    },
  ],
};

export const imageFallBackUrl = "https://spyne-static.s3.amazonaws.com/failedSkuFallback.svg"
export const videoThumbnail = "https://spyne-static.s3.us-east-1.amazonaws.com/console/video-bg.png"
export const playIcon = "https://spyne-static.s3.us-east-1.amazonaws.com/console/combined-vs/playbtn.svg"
export const spinIcon = 'https://spyne-static.s3.us-east-1.amazonaws.com/console/combined-vs/360banner.svg'

/** left arrow */
export const LeftArrow = ({ handleLeft, className }) => {
  return (
    <button onClick={() => {
      handleLeft()
    }}
      className="absolute left-2 top-1/2 transform -translate-y-1/2 z-[100] cursor-pointer">
      <img src="https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne-static.s3.us-east-1.amazonaws.com/multIcons/handleleft.svg" className={className} />
    </button>
  )
}

/** right arrow */
export const RightArrow = ({ handleRight, className }) => {
  return (
    <button onClick={()=>{
      handleRight()
    }}
      className="absolute right-2 top-1/2 transform -translate-y-1/2 z-[100] cursor-pointer">
      <img src="https://media.spyneai.com/unsafe/filters:format(webp)/https://spyne-static.s3.us-east-1.amazonaws.com/multIcons/handleright.svg" className={className} />
    </button>
  )
}


