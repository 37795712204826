import React from 'react'
import Styles from '../../Css/View360/View360.module.css'
import View360CommonStyle from '../../Css/View360/View360Common.module.css'
import { checkIsV2 } from '../../utils/getAppVersion'

const UnderneathFrameDropdown = ({state,updateDropdown,selectedName}) => {
    const isV2 = checkIsV2()
    return (
        <div className={ Styles['dropdown-container']}>
            <div className={Styles['header-container']} onClick={()=>updateDropdown()}>
                <div className={Styles['selected-name']}>{state?.selectedUnderneathUrl?.tags?.overlay_name || state?.selectedUnderneathUrl?.name}</div>
                <img alt="Caret icon" srcset="https://spyne-static.s3.amazonaws.com/console/filter/chevron_down_inactive.svg 1x, https://spyne-static.s3.amazonaws.com/console/filter/chevron_down_inactive.svg 2x" src="https://spyne-static.s3.amazonaws.com/console/filter/chevron_down_inactive.svg" 
                width="24" height="24" decoding="async" data-nimg="1" 
                className={Styles[state.showDropdown ? 'dropdown-icon-noactive':'dropdown-icon-active']}
                 loading="lazy"></img>
            </div>
           {state.showDropdown && <div className={Styles['dropdown-url-name']}>
                {
                    state?.underneathData?.map((item,index) => {
                        return (
                            <div key={index} className={Styles['dropdown-url-heading']} onClick={()=>selectedName(item)}>
                                {item?.tags?.overlay_name || item?.image_name}
                            </div>
                        )
                    })
                }
            </div>}
        </div>
    )
}

export default UnderneathFrameDropdown
