import React, { Component } from 'react'
import { singleData } from './config'
import Styles from '../../Css/View360/View360.module.css'
import BeforeAfterToggleSvg from './BeforeAfterToggleSvg'
import SVG from '../common/svg/SVG'

export default class ToggleButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDropdownOpen: false
    }
    this.dropdownRef = React.createRef();
  }
  componentDidMount() {
    document?.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document?.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (this.dropdownRef?.current && !this.dropdownRef?.current?.contains(event?.target)) {
      this.setState({ isDropdownOpen: false });
    }
  };

  toggleDropdown = () => {
    this.setState(prevState => ({ isDropdownOpen: !prevState.isDropdownOpen }));
  };
  render() {
    const { isDropdownOpen } = this.state;
    const { mobileView, value, updateState, beforeAfterView } = this?.props;
    const availableOptions = singleData?.iframeTabs?.filter(element =>
      !((element?.key === 'UNDERNEATH' && !value?.underneathData?.length > 0) ||
        (element?.key === 'INT' && !value?.interior360Url?.length > 0))
    );

    if (this.props.beforeAfterView) {
      return (
        <div className={[Styles['before-after-tab']]}>
          {this.props.value.showExtOrInt !== "INT" && (
            <ul className={[Styles['before-after-tab-view']]}>
              {singleData?.beforeAfterTabs?.map((elements, indx) => {
                return (
                  <li
                    key={`iframeTabs-${indx}`}
                    onClick={() => this.props.updateState(elements?.key, 'beforeAfter')}
                    className={[
                      Styles['tabs'],
                      elements?.key === this.props.value.activeToggleBeforeAfter
                        ? Styles["active"]
                        : Styles[""]
                    ].join(' ')}
                  >
                    <BeforeAfterToggleSvg
                      activeTitle={this.props.value.activeToggleBeforeAfter}
                      hotspot={elements?.title === 'Spin 360 only' ? false : true}
                    />
                    {this.props.value.isAnyHotSpotPresent
                      ? elements.title
                      : `View ${indx + 1}`}
                  </li>
                );
              })}
              <div className={[Styles['before-after-tabs-bg']]}></div>
            </ul>
          )}

        </div>
      )
    }
    const selectedOption = availableOptions?.find(opt => opt?.key === value?.showExtOrInt);


    return (
      <div ref={this.dropdownRef} className={`${mobileView ? 'absolute bottom-[10%] left-[50%] -translate-x-1/2' : ''}`}>
        <div className="relative" id='dropdown-container'>
          {/* Dropdown Button */}
          <button
            onClick={this.toggleDropdown}
            className={`flex items-center gap-2 px-2 py-1 sm:py-2 sm:h-[40px] rounded-lg bg-white-default border border-gray-200 text-black-1
                            text-xs md:text-sm font-semibold leading-5`}
          >
            <SVG iconName="eye" className="w-5 h-5" />

            <span>{selectedOption?.title}</span>
            <svg
              className={`w-4 h-4 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="3"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className={`absolute right-0 bottom-full ${mobileView ? 'left-0 bottom-auto' : ''} -translate-y-3 mt-1 md:mt-2 w-40 rounded-lg bg-white bg-white-default shadow-lg border border-gray-200 overflow-hidden`}>
              {availableOptions?.map((element, index) => (
                <button
                  key={`iframeTabs-${index}`}
                  onClick={() => {
                    updateState?.(element?.key, 'exteriorInterior');
                    this.setState({ isDropdownOpen: false });
                  }}
                  className={`w-full text-left px-4 py-2 hover:bg-gray-50 transition-colors
                                        ${element?.key === value?.showExtOrInt
                      ? 'bg-blue-activeTabBg text-blue-activeBlue'
                      : 'text-black-1'}
                                        ${mobileView ? 'text-xs' : 'text-xs md:text-sm'}`}
                >
                  {element?.title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}
