import { useState, useEffect } from 'react';

const useImageZoom = (imageWithShimmerRef) => {
  const [zoomLevel, setZoomLevel] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [lastTouchDistance, setLastTouchDistance] = useState(null);

  const handleZoomIn = () => {
    setZoomLevel(prevZoom => {
      const newZoom = Math.min(prevZoom + 0.9, 2.7);
      return newZoom;
    });
  };

  const handleZoomOut = () => {
    setZoomLevel(prevZoom => {
      const newZoom = Math.max(prevZoom - 0.9, 0);
      const transitionRatio = newZoom / prevZoom;
      
      setCurrentX(prev => prev * transitionRatio);
      setCurrentY(prev => prev * transitionRatio);
      
      return newZoom;
    });
  };

  const handleMouseDown = (e) => {
    if (zoomLevel > 0) {
      setIsDragging(true);
      setStartX(e.clientX - currentX);
      setStartY(e.clientY - currentY);
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && zoomLevel > 0) {
      const containerWidth = e.target.offsetWidth;
      const containerHeight = e.target.offsetHeight;
      const imageWidth = containerWidth * (1 + zoomLevel);
      const imageHeight = containerHeight * (1 + zoomLevel);

      let newX = e.clientX - startX;
      let newY = e.clientY - startY;

      // Boundary checks
      const maxX = (imageWidth - containerWidth) / 2;
      const maxY = (imageHeight - containerHeight) / 2;

      newX = Math.max(-maxX, Math.min(newX, maxX));
      newY = Math.max(-maxY, Math.min(newY, maxY));

      setCurrentX(newX);
      setCurrentY(newY);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const getTouchDistance = (touches) => {
    return Math.hypot(
      touches[0].clientX - touches[1].clientX,
      touches[0].clientY - touches[1].clientY
    );
  };

  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      setLastTouchDistance(getTouchDistance(e.touches));
      setIsDragging(false);
      return;
    } else if (e.touches.length === 1 && zoomLevel > 0) {
      setIsDragging(true);
      setStartX(e.touches[0].clientX - currentX);
      setStartY(e.touches[0].clientY - currentY);
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      e.preventDefault();
      const newDistance = getTouchDistance(e.touches);
      
      if (lastTouchDistance !== null) {
        const delta = newDistance - lastTouchDistance;
        const zoomDelta = delta * 0.04;
        
        setZoomLevel(prev => {
          const newZoom = prev + zoomDelta;
          return Math.max(0, Math.min(2.7, newZoom));
        });
      }
      
      setLastTouchDistance(newDistance);
      return;
    } else if (e.touches.length === 1 && isDragging && zoomLevel > 0) {
      const containerWidth = e.target.offsetWidth;
      const containerHeight = e.target.offsetHeight;
      const imageWidth = containerWidth * (1 + zoomLevel);
      const imageHeight = containerHeight * (1 + zoomLevel);
      
      let newX = e.touches[0].clientX - startX;
      let newY = e.touches[0].clientY - startY;
      
      // Boundary checks
      const maxX = Math.max(0, (imageWidth - containerWidth) / 2);
      const maxY = Math.max(0, (imageHeight - containerHeight) / 2);
      
      newX = Math.max(-maxX, Math.min(newX, maxX));
      newY = Math.max(-maxY, Math.min(newY, maxY));
      
      setCurrentX(newX);
      setCurrentY(newY);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setLastTouchDistance(null);
  };

  const resetZoom = () => {
    setZoomLevel(0);
    setCurrentX(0);
    setCurrentY(0);
  };

  useEffect(() => {
    if (isDragging && imageWithShimmerRef?.current) {
      imageWithShimmerRef.current.addEventListener('mousemove', handleMouseMove);
      imageWithShimmerRef.current.addEventListener('mouseup', handleMouseUp);
      imageWithShimmerRef.current.addEventListener('touchmove', handleTouchMove);
      imageWithShimmerRef.current.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if(imageWithShimmerRef?.current){
        imageWithShimmerRef.current.removeEventListener('mousemove', handleMouseMove);
        imageWithShimmerRef.current.removeEventListener('mouseup', handleMouseUp);
        imageWithShimmerRef.current.removeEventListener('touchmove', handleTouchMove);
        imageWithShimmerRef.current.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [isDragging, imageWithShimmerRef]);

  return {
    zoomLevel,
    currentX,
    currentY,
    isDragging,
    handleZoomIn,
    handleZoomOut,
    handleMouseDown,
    handleTouchStart,
    handleTouchMove,
    resetZoom,
    imageStyle: {
      transition: isDragging ? 'none' : 'transform 0.3s ease-out',
      transform: zoomLevel > 0 
        ? `translate(${currentX}px, ${currentY}px) scale(${1 + zoomLevel})`
        : 'none',
      transformOrigin: 'center center',
      cursor: zoomLevel > 0 ? 'grab' : 'default',
      userSelect: 'none'
    }
  };
};

export default useImageZoom;