import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

export const handleGetRequest = (URL, callParams, addXRequestId = false)=>{
    let handleGetPromise = new Promise((resolve, reject)=>{
        axios({
            method: 'GET',
            url: URL,
            params : callParams ? {...callParams} : {},
            headers: addXRequestId ? { 'X-Request-Id': uuidv4() } : {}
        }).then(res=>{
            //console.log('res',res);
            if(res.data?.status === 200)
                resolve(res.data);
            else if(res.status === 200)
                resolve(res.data)
            else 
                reject(res.data['message'] || res.data);
            
        }).catch(err=>{
            reject(err);
        })
    });

    return handleGetPromise;

}


