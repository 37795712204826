import React from 'react'

const Eyeicon = ({ className }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} strokeWidth="3">
            <path d="M9.99989 7.66797C10.6187 7.66797 11.2122 7.9138 11.6498 8.35139C12.0874 8.78897 12.3332 9.38246 12.3332 10.0013C12.3332 10.6201 12.0874 11.2136 11.6498 11.6512C11.2122 12.0888 10.6187 12.3346 9.99989 12.3346C9.38105 12.3346 8.78756 12.0888 8.34998 11.6512C7.91239 11.2136 7.66656 10.6201 7.66656 10.0013C7.66656 9.38246 7.91239 8.78897 8.34998 8.35139C8.78756 7.9138 9.38105 7.66797 9.99989 7.66797ZM9.99989 4.16797C13.8888 4.16797 17.2099 6.58686 18.5554 10.0013C17.2099 13.4157 13.8888 15.8346 9.99989 15.8346C6.111 15.8346 2.78989 13.4157 1.44434 10.0013C2.78989 6.58686 6.111 4.16797 9.99989 4.16797ZM3.13989 10.0013C3.76853 11.2849 4.74468 12.3663 5.95737 13.1227C7.17006 13.8791 8.57064 14.2801 9.99989 14.2801C11.4291 14.2801 12.8297 13.8791 14.0424 13.1227C15.2551 12.3663 16.2312 11.2849 16.8599 10.0013C16.2312 8.71773 15.2551 7.63628 14.0424 6.87988C12.8297 6.12349 11.4291 5.7225 9.99989 5.7225C8.57064 5.7225 7.17006 6.12349 5.95737 6.87988C4.74468 7.63628 3.76853 8.71773 3.13989 10.0013Z" fill="black" />
        </svg>

    )
}

export default Eyeicon