const PlusIcon = ({ className }) => {
    return (
        <svg
            width="16" 
            height="17" 
            viewBox="0 0 16 17" 
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${className ? className : "fill-white"}  stroke-none`}
            style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.50))' }}>
            <path 
                d="M14.4997 9.58268H9.08307V14.9993C9.08307 15.2867 8.96894 15.5622 8.76577 15.7654C8.56261 15.9685 8.28706 16.0827 7.99974 16.0827C7.71242 16.0827 7.43687 15.9685 7.23371 15.7654C7.03054 15.5622 6.9164 15.2867 6.9164 14.9993V9.58268H1.49974C1.21242 9.58268 0.93687 9.46855 0.733706 9.26538C0.530542 9.06222 0.416405 8.78667 0.416405 8.49935C0.416405 8.21203 0.530542 7.93648 0.733706 7.73332C0.93687 7.53015 1.21242 7.41602 1.49974 7.41602H6.9164V1.99935C6.9164 1.71203 7.03054 1.43648 7.23371 1.23332C7.43687 1.03015 7.71242 0.916016 7.99974 0.916016C8.28706 0.916016 8.56261 1.03015 8.76577 1.23332C8.96894 1.43648 9.08307 1.71203 9.08307 1.99935V7.41602H14.4997C14.7871 7.41602 15.0626 7.53015 15.2658 7.73332C15.4689 7.93648 15.5831 8.21203 15.5831 8.49935C15.5831 8.78667 15.4689 9.06222 15.2658 9.26538C15.0626 9.46855 14.7871 9.58268 14.4997 9.58268Z" />
        </svg>


    )
}

export default PlusIcon