import { Pannellum } from 'pannellum-react';
import React, { useEffect } from 'react'
import { checkIsV2, checkIsV3 } from '../../utils/getAppVersion';

const UnderneathIframe = ({handleOnLoad,state, isMobileView , timeSpent}) => {
   const isV2 = checkIsV2()
   const isV3 = checkIsV3()

    useEffect(()=>{
        // console.log(state?.url)
    },[state?.url])


    useEffect(() => {
      const startTime = Date.now();
      return () => {
        const endTime = Date.now();
        const timeSpentMs = endTime - startTime; 
        const timeSpentSeconds = timeSpentMs / 1000; 
        
        if (typeof timeSpent === 'function') {
          timeSpent(timeSpentSeconds);
        } else {
          console.error('timeSpent is not a function');
        }
      };
     }, [timeSpent]);



    return (
        state?.underneathData?.map((frames,index)=>{
            if(state?.selectedUnderneathUrl?.url!==frames.input_image_hres_url)return;
            return(
                <div key={index}
                style={{
                  width: isV2 ? "" : isV3 ? '100%' : '100vw',
                  height: isV3 ? '100%' : '100vh',
                  position: 'relative',
                }}
              >
                {isMobileView ? <Pannellum
                  width="100%"
                  height="100%"
                  hfov={70}
                  image={`https://media.spyneai.com/unsafe/2400x1200/filters:format(webp)/${state?.selectedUnderneathUrl?.url}`}
                  pitch={0}
                  yaw={0}
                  // haov={180}
                  // vaov={180}
                  autoLoad={true}
                  showZoomCtrl={false}
                  showControls={false}
    
                >
                  
                </Pannellum> :
                  <Pannellum
                    width="100%"
                    height="100%"
                    hfov={120}
                    image={`https://media.spyneai.com/unsafe/2400x1200/filters:format(webp)/${state?.selectedUnderneathUrl?.url}`}
                    pitch={-20}
                    yaw={-25}
                    // haov={180}
                    // vaov={180}
                    autoLoad={true}
                    showZoomCtrl={false}
                    showControls={false}
    
                  >
                  </Pannellum>
                }
              </div>
            //     <a-scene>
            //     <a-assets>
            //         <img id="city" src={frames?.input_image_hres_url} onLoad={handleOnLoad} crossOrigin="anonymous" alt="" />
            //     </a-assets>
            //     <a-sky
            //         id="image-360"
            //         radius="10"
            //         src="#city"
            //     ></a-sky>
    
            //     {/* <a-entity
            //         className="link"
            //         geometry="primitive: ring; radiusInner: 0.02; radiusOuter: 0.03"
            //     ></a-entity> */}

            //     {isMobileView ? (<a-camera 
            //         look-controls="
            //             magicWindowTrackingEnabled: false;" 
            //         orbit-controls="
            //             dampingFactor: 0.9;
            //             maxPolarAngle: 180;
            //             target: 0 2 0; 
            //             screenSpacePanning: false; 
            //             initialPosition: 0 3 5; 
            //             rotateSpeed: 0.5"
            //     >
            //         <a-cursor
            //             id="cursor"
            //             animation__click="property: scale; from: 0.1 0.1 0.1; to: 1 1 1; easing: easeInCubic; dur: 150; startEvents: click"
            //             animation__clickreset="property: scale; to: 0.1 0.1 0.1; dur: 1; startEvents: animationcomplete__click"
            //             animation__fusing="property: scale; from: 1 1 1; to: 0.1 0.1 0.1; easing: easeInCubic; dur: 150; startEvents: fusing"
            //         ></a-cursor>
            //     </a-camera>
            //     ) : (
            //     <a-camera>
            //         <a-cursor
            //             id="cursor"
            //             animation__click="property: scale; from: 0.1 0.1 0.1; to: 1 1 1; easing: easeInCubic; dur: 150; startEvents: click"
            //             animation__clickreset="property: scale; to: 0.1 0.1 0.1; dur: 1; startEvents: animationcomplete__click"
            //             animation__fusing="property: scale; from: 1 1 1; to: 0.1 0.1 0.1; easing: easeInCubic; dur: 150; startEvents: fusing"
            //         ></a-cursor>
            //     </a-camera>
            //     )}
            // </a-scene>
            )
        })
    )
}

export default UnderneathIframe
