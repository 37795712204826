import React, { useContext } from 'react';
import { MediaContext, NavigationContext } from './context';
import ImageWithShimmer from '../common/ImageWithShimmer';
import { IMAGE_COMPRESSOR } from '../../utils/imageCompressor';
import getSpinThumbnail from '../../utils/getSpinThumbnail';
import {playIcon, spinIcon, videoThumbnail} from '../common/config'

const GridItem = ({ item, index, template, rightGrid=false }) => {
    const { selectedIndex, handleSelectThumbnail } = useContext(NavigationContext);
    const { aspectRatio } = useContext(MediaContext);
    const spinThumbnail = () => {
        if (item.type === "360") {
            return getSpinThumbnail({ images: item?.data?.skuDetails, firstAngle: item?.data?.sequenceData?.first_frame_angle || null });
        }
        return null;
    }
// const [isHover, setIsHover] = useState(false);

    return (
        <div key={index} className={`${rightGrid ? "" : "h-full"} relative hover:scale-105 transition-all duration-300`}
            style={{
                scrollSnapAlign: 'center', // Make items snap to center
                aspectRatio: aspectRatio
            }}
            onClick={() => handleSelectThumbnail(index, item.type)}
            // onMouseEnter={() => setIsHover(true)}
            // onMouseLeave={() => setIsHover(false)}

        >
            {
                item.type === "image" && <div className={`w-full h-full relative border overflow-hidden rounded-lg ${selectedIndex === index ? "border-2 border-blue-activeBlue" : ""} `}
                >
                    <ImageWithShimmer src={IMAGE_COMPRESSOR({ url: item?.data?.output_image_hres_url, resolution: (typeof aspectRatio !== 'undefined' && aspectRatio === '16/9') ? '375x211' : '375x281' })} alt={item?.data?.alt || 'image'} className={`w-full h-full object-cover cursor-pointer`} />
                </div>
            }
            {
                item.type === "video" && <div className={`w-full h-full relative rounded-lg border overflow-hidden  ${selectedIndex === index ? "border-2 border-blue-activeBlue" : ""}`}>
                    <ImageWithShimmer src={IMAGE_COMPRESSOR({ url: videoThumbnail, resolution: aspectRatio === '16/9' ? '375x211' : '375x281' })} alt={item?.data?.alt || 'video'} className={`w-full h-full object-cover object-center cursor-pointer`} />
                    <div className='absolute z-[11] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center'>
                        <img
                            src={playIcon}
                            alt="Overlay"
                            className={`w-[30px] h-[30px] z-[11] ${template === "t2" ? "lg:w-[40px] lg:h-[40px]" : ""}`}
                        />
                        <p className={` text-white-70 mt-1 text-[10px] leading-[10px] lg:text-xs text-center font-medium whitespace-nowrap ${template === "t2" ? "lg:text-sm" : ""}`}>Featured Video</p>
                    </div>
                </div>
            }
            {
                item.type === "360" && <div className={`w-full h-full relative rounded-lg border  overflow-hidden  ${selectedIndex === index ? "border-2 border-blue-activeBlue" : ""}`}>
                    <ImageWithShimmer src={IMAGE_COMPRESSOR({ url: spinThumbnail()?.output_image_hres_url, resolution: aspectRatio === '16/9' ? '375x211' : '375x281' })} alt={item?.data?.alt || '360'} className={`w-full h-full object-cover cursor-pointer`} />
                    <img src={spinIcon} alt="spin" className={`w-8 h-8 absolute top-1 right-1 ${template === "t2" ? "w-[47px] h-[47px]" : ""}`} />
                </div>
            }
              {/* {isHover && <button className={`absolute right-[2px] bottom-[2px] z-[] w-10 h-10`}>
                  <img src="https://spyne-static.s3.us-east-1.amazonaws.com/console/white-square.svg" alt="Full Screen" className="w-full h-full" />
                </button>} */}

        </div>
    )
}

export default GridItem;