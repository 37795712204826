import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'
import { MediaContext, NavigationContext, UIContext } from './context'
import useTouchNavigation from './hooks/useTouchNavigation';
import ImageWithShimmer from '../common/ImageWithShimmer';
import { IMAGE_COMPRESSOR } from '../../utils/imageCompressor';
import { LeftArrow, RightArrow } from '../common/config';
import VideoItem from './VideoItem';
import View360 from '../View360/View360';
import ImageCategoryDropdown from './ImageCategoryDropdown';
import ZoomControls from './ZoomControls';
import useImageZoom from './hooks/useImageZoom';

//viewer component selected 360, video OR image contet
const MainViewer = ({ template, handleFullScreen, isFullScreen = false, dynamicTabImage }) => {
  const [selectedImageTab, setSelectedImageTab] = useState(dynamicTabImage?.[0]?.label ?? 'Exterior');

  // Split context consumption
  const { selectedTab, aspectRatio, } = useContext(MediaContext);
  const { carouselItems, handleLeft, handleRight, selectedIndex, handleSelectThumbnail, skipAnimation } = useContext(NavigationContext);
  const { showOverlays, setShowOverlays, isSingleProduct } = useContext(UIContext);
  const [shouldLoadImage, setShouldLoadImage] = useState(false);
  const hasFirstItem360 = carouselItems?.[0]?.type === "360";

  // map for imagerefs
  const imageRefs = useRef({});
  const mainViewerRef = useRef(null);
  const containerRef = useRef(null);

  // Create zoom state for the currently selected image
  const {
    zoomLevel,
    handleZoomIn,
    handleZoomOut,
    handleMouseDown: handleMouseDownImage,
    handleTouchStart: handleTouchStartImage,
    handleTouchMove: handleTouchMoveImage,
    resetZoom,
    imageStyle
  } = useImageZoom(imageRefs.current[selectedIndex]);

  const { handleTouchStart, handleTouchEnd, handleTouchMove } = useTouchNavigation(handleLeft, handleRight, carouselItems, selectedIndex);

  const containerheight = aspectRatio === "16/9" ? containerRef.current?.clientWidth * (9 / 16) : containerRef.current?.clientWidth * (3 / 4);
  const heightFull = containerheight + 60 > window.innerHeight ? true : false;
  let firstImageIndex = carouselItems?.findIndex((item) => item?.type === "image");
  let lastImageIndex = carouselItems?.findLastIndex((item) => item?.type === "image");
  let firstExteriorIndex = carouselItems?.findIndex((item) => item?.type === "image" && item?.category === "exterior");
  let firstInteriorIndex = carouselItems?.findIndex((item) => item?.type === "image" && item?.category === "interior");
  let firstAdditionalIndex = carouselItems?.findIndex((item) => item?.type === "image" && item?.category === "additional");

  useEffect(() => {
    resetZoom();
  }, [selectedIndex]);

  // Initialize refs for all images //map
  useEffect(() => {
    carouselItems?.forEach((item, index) => {
      if (item.type === "image" && !imageRefs.current[index]) {
        imageRefs.current[index] = React.createRef();
      }
    });
  }, [carouselItems]);

  useEffect(() => {
    if (selectedTab === "image") {
      let category = carouselItems?.[selectedIndex]?.category
      let tab = dynamicTabImage?.find((tab) => tab?.label?.toLowerCase() === category?.toLowerCase())
      setSelectedImageTab(tab?.label)
    }
  }, [selectedTab, selectedIndex, carouselItems, dynamicTabImage]);

  const handleMouseDown = useCallback((e) => {
    if (e.target.closest('button')) return;
    setShowOverlays(false);
  }, [setShowOverlays]);

  const handleMouseUp = useCallback((e) => {
    if (e.target.closest('button')) return;
    setShowOverlays(true);
  }, [setShowOverlays]);

  useEffect(() => {
    if (mainViewerRef?.current) {
      mainViewerRef.current.addEventListener('mousedown', handleMouseDown)
      mainViewerRef.current.addEventListener('mouseup', handleMouseUp)
      mainViewerRef.current.addEventListener('touchstart', handleMouseDown)
      mainViewerRef.current.addEventListener('touchend', handleMouseUp)
    }

    return () => {
      if (mainViewerRef?.current) {
        mainViewerRef.current.removeEventListener('mousedown', handleMouseDown)
        mainViewerRef.current.removeEventListener('mouseup', handleMouseUp)
        mainViewerRef.current.removeEventListener('touchstart', handleMouseDown)
        mainViewerRef.current.removeEventListener('touchend', handleMouseUp)
      }
    }
  }, [mainViewerRef, handleMouseDown, handleMouseUp]);
  
  const [is360Loading, setIs360Loading] = useState(hasFirstItem360 || false);

  const handle360LoadComplete = () => {
    if(is360Loading){
      setIs360Loading(false);
    }
  };
  // Determine if image should load
  const getShouldLoadImage = () => {
    // Load if either:
    // 1. 360 view has finished loading
    // 2. This is the currently selected image
    return !is360Loading || selectedTab === "image"
  };
  useEffect(() => {
    let shouldLoad = getShouldLoadImage();
    setShouldLoadImage(shouldLoad);
  }, [is360Loading, selectedTab]);

  return (
    <div className={`h-full relative rounded-lg overflow-hidden ${template === "mobile" ? "!rounded-none" : ""}`} ref={containerRef}>
      {!isSingleProduct && (
        <div className={`${((!showOverlays && selectedTab === "360") || (template === "mobile" && (selectedTab === "video" || selectedTab === "360"))) ? "hidden" : ""} absolute inset-0 w-full flex justify-between items-center px-4 pointer-events-none`}
          style={{
            zIndex: 12,
            height: (template === "t2") ? containerheight : "100%",
          }}
        >
          <div className={`pointer-events-auto ${(template === "mobile" && (selectedTab === "image") && (selectedIndex === firstImageIndex)) ? "hidden" : ""}`}>
            <LeftArrow
              handleLeft={() => handleLeft(carouselItems?.length, selectedIndex)}
              className="w-7 h-7 cursor-pointer"
            />
          </div>
          <div className={`pointer-events-auto ${(template === "mobile" && (selectedTab === "image") && (selectedIndex === lastImageIndex)) ? "hidden" : ""}`}>
            <RightArrow
              handleRight={() => handleRight(carouselItems?.length, selectedIndex)}
              className="w-7 h-7 cursor-pointer"
            />
          </div>
        </div>
      )}

      <div className={`absolute left-0 w-full z-10 
           ${template === "t1" ? "!h-full !max-h-full mx-auto" : template === "mobile" ? heightFull ? "h-full" : "top-1/2 -translate-y-1/2 w-full my-auto" : "h-full mx-auto sm:mx-0 sm:h-auto sm:w-full my-auto "}
                 flex items-center justify-center overflow-hidden
                 `}
        style={{
          height: containerheight,
        }}>

        {selectedTab === "image" && template === "mobile" && (dynamicTabImage?.length > 1) && (
          <ImageCategoryDropdown
            options={dynamicTabImage}
            selectedTab={selectedImageTab}
            onSelect={(option) => {
              setSelectedImageTab(option.label);
              handleSelectThumbnail(
                option.label === "Exterior"
                  ? firstExteriorIndex
                  : option.label === "Interior"
                    ? firstInteriorIndex
                    : firstAdditionalIndex,
                'image'
              );
            }}
          />
        )}
        <div className='' style={{
          height: "100%",
          aspectRatio: aspectRatio,
          position: 'relative'
        }}>
          {selectedTab === 'image' && <ZoomControls
            zoomLevel={zoomLevel}
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
          />}
        </div>
        {/* carous)}el container */}
        <div
          className={`flex absolute w-full h-full ${skipAnimation ? "transition-none" : "transition-transform duration-500 ease-in-out"}`}
          style={{
            transform: `translateX(${-selectedIndex * 100}%)`,
            width: `100%`,
            left: '0',
          }}
          ref={mainViewerRef}
          onTouchStart={(selectedTab === "image" && zoomLevel === 0) ? handleTouchStart : null}
          onTouchMove={(selectedTab === "image" && zoomLevel === 0) ? handleTouchMove : null}
          onTouchEnd={(selectedTab === "image" && zoomLevel === 0) ? handleTouchEnd : null}
        >

          {
            carouselItems.map((item, index) => {
              return (
                <div key={index} className={`w-full h-full transition-all duration-500 ease-in-out flex-shrink-0 rounded-xl
            ${template === "mobile" && "flex justify-center !rounded-none"}
            `}>
                  <div
                    className={`overflow-hidden object-cover rounded-xl cursor-pointer relative ${template === "t1" ? "!h-full !max-h-full mx-auto" : template === "mobile" ? heightFull ? "h-full" : "w-full my-auto" : "h-full mx-auto md:mx-0 md:h-auto md:w-full my-auto "}
                flex items-center justify-center overflow-hidden ${template === "mobile" && "!rounded-none"}
                `}
                    style={{
                      aspectRatio: aspectRatio
                    }}
                  // onDoubleClick={handleFullScreen}
                  >
                    {
                      item.type === "360" && (
                        <View360 preloadedData={item?.data} hotspot={template === "mobile" ? false : true} selectedIndex={selectedIndex} onLoadComplete={is360Loading ? handle360LoadComplete : null} />
                      )
                    }
                    {
                      item.type === "video" && (
                        <VideoItem item={item} template={template} />
                      )
                    }
                    {
                      item.type === "image" && (
                        <ImageWithShimmer
                          src={IMAGE_COMPRESSOR({ url: item?.data?.output_image_hres_url })}
                          alt={item?.data?.alt || 'image'}
                          className={`w-full h-full object-cover object-center rounded-xl ${template === "mobile" && "!rounded-none"}`}
                          imageWithShimmerRef={imageRefs.current[index]}
                          handleMouseDown={handleMouseDownImage}
                          handleTouchStart={handleTouchStartImage}
                          handleTouchMove={handleTouchMoveImage}
                          imageStyle={imageStyle}
                          enableZoom={selectedIndex === index}
                          shouldStartLoading={shouldLoadImage ? 'eager' : 'lazy'}
                        />
                      )
                    }
                    {/* Full screen button */}

                    {!isFullScreen && template!=='mobile' && <button onClick={handleFullScreen} className={`${(!showOverlays && item.type === "360") ? "hidden": ""} absolute -right-[3px]  bottom-[2px] md:bottom-[7px] z-10 w-12 h-12`}>
                  <img src="https://spyne-static.s3.us-east-1.amazonaws.com/console/white-square.svg" alt="Full Screen" className="w-full h-full" />
                </button>} 
                    {/* {
                  <button onClick={handleShareModal} className={`${(!showOverlays && item.type === "360") ? "hidden": ""} absolute right-[1%] top-[1%] z-10 w-10 h-10`}>
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))'
                      }}
                    >
                      <path d="M13.125 18C12.3958 18 11.776 17.7375 11.2656 17.2125C10.7552 16.6875 10.5 16.05 10.5 15.3C10.5 15.21 10.5219 15 10.5656 14.67L4.41875 10.98C4.18542 11.205 3.91563 11.3814 3.60938 11.5092C3.30312 11.637 2.975 11.7006 2.625 11.7C1.89583 11.7 1.27604 11.4375 0.765625 10.9125C0.255208 10.3875 0 9.75 0 9C0 8.25 0.255208 7.6125 0.765625 7.0875C1.27604 6.5625 1.89583 6.3 2.625 6.3C2.975 6.3 3.30312 6.3639 3.60938 6.4917C3.91563 6.6195 4.18542 6.7956 4.41875 7.02L10.5656 3.33C10.5365 3.225 10.5184 3.1239 10.5114 3.0267C10.5044 2.9295 10.5006 2.8206 10.5 2.7C10.5 1.95 10.7552 1.3125 11.2656 0.7875C11.776 0.2625 12.3958 0 13.125 0C13.8542 0 14.474 0.2625 14.9844 0.7875C15.4948 1.3125 15.75 1.95 15.75 2.7C15.75 3.45 15.4948 4.0875 14.9844 4.6125C14.474 5.1375 13.8542 5.4 13.125 5.4C12.775 5.4 12.4469 5.3361 12.1406 5.2083C11.8344 5.0805 11.5646 4.9044 11.3312 4.68L5.18437 8.37C5.21354 8.475 5.23192 8.5764 5.2395 8.6742C5.24708 8.772 5.25058 8.8806 5.25 9C5.24942 9.1194 5.24592 9.2283 5.2395 9.3267C5.23308 9.4251 5.21471 9.5262 5.18437 9.63L11.3312 13.32C11.5646 13.095 11.8344 12.9189 12.1406 12.7917C12.4469 12.6645 12.775 12.6006 13.125 12.6C13.8542 12.6 14.474 12.8625 14.9844 13.3875C15.4948 13.9125 15.75 14.55 15.75 15.3C15.75 16.05 15.4948 16.6875 14.9844 17.2125C14.474 17.7375 13.8542 18 13.125 18Z" fill="white" />
                    </svg>
                  </button>
                } */}


                  </div>

                </div>
              )
            })
          }


        </div>
      </div>


    </div >
  )
}

export default MainViewer