import React, { useState, useRef, useEffect } from "react";

const ImageWithShimmer = ({
  src,
  alt,
  className,
  enableZoom = false,
  imageWithShimmerRef,
  handleMouseDown,
  handleTouchStart,
  handleTouchMove,
  imageStyle = '',
  shouldStartLoading = '',
}) => {
  const [showLoading, setShowLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const localRef = useRef(null);
  // Use provided ref or fallback to local ref
  const ref = imageWithShimmerRef || localRef;

  useEffect(() => {
    if (shouldStartLoading !== '' && shouldStartLoading === 'eager') {
      setImageSrc(src);
    }else if(shouldStartLoading === ''){
      setImageSrc(src);
    }
  }, [shouldStartLoading, src]);
 
  const handleDataError = () => {
    setShowLoading(false);
    setHasError(true);
  };

  return (
    <div className="relative w-full h-full bg-gray-100" ref={ref}>
      {(imageSrc) && (
        <img
          onLoad={() => setShowLoading(false)}
          onError={handleDataError}
          src={imageSrc}
          alt={alt}
          className={`${className} transition-opacity duration-300`}
          style={{
            ...imageStyle,
            touchAction: enableZoom ? 'none' : 'auto',
          }}
          onMouseDown={enableZoom ? handleMouseDown : () => { }}
          onTouchStart={enableZoom ? handleTouchStart : () => { }}
          onTouchMove={enableZoom ? handleTouchMove : () => { }}
          draggable="false"
        />
      )}

      {(showLoading ) && (
        <div className='rounded-lg absolute inset-0 h-full object-cover aspect-video shimmer w-full flex items-center justify-center border text-gray-500 text-xl'></div>
      )}

      {hasError && (
        <div className='rounded-lg absolute inset-0 h-full object-cover aspect-video shimmer w-full flex items-center justify-center border text-gray-500 text-xl'> Coming soon</div>
      )}
    </div>
  );
};

export default ImageWithShimmer;